.benefits {
  background: #2c41bb;

  position: relative;

  padding: 124px 0 8px 0;
  margin: -2px 0 0 0;

  @include s {
    padding-top: 40px;
    padding-bottom: 3px;
  }

  @include smallD {
    padding-top: 90px;
  }

  @include md {
    padding-top: 90px;
  }

  &__screw {
    width: 100%;
    height: 30px;

    position: absolute;
    left: 0;

    &_top {
      top: -30px;

      @include s {
        top: -8px;
      }
    }

    &_bottom {
      bottom: -30px;

      @include s {
        bottom: 8px;
      }
    }

    @include s {
      height: 3px;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include s {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    @include tablet {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__title {
    font-weight: 600;
    letter-spacing: -0.025em;
    text-align: left;

    @include s {
      letter-spacing: -1px;
    }
  }

  &__subtitle {
    font-size: 23px;
    line-height: 40px;
    color: #d8feff;
    text-align: left;

    max-width: 50%;

    padding-top: 14px;

    @include s {
      max-width: 90%;

      font-size: 11px;
      line-height: 15px;
      text-align: center;

      padding-top: 20px;
    }

    @include tablet {
      padding-top: 0;

      max-width: 80%;

      text-align: center;

      margin-top: 25px;
    }

    @include smallD {
      font-size: 20px;
      line-height: 30px;
    }

    @include md {
      font-size: 20px;
      line-height: 30px;
    }

    @include l {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__list {
    margin-top: 128px;

    @include s {
      margin-top: 41px;
    }

    @include tablet {
      margin-top: 40px;
    }

    @include smallD {
      margin-top: 60px;
    }

    @include md {
      margin-top: 80px;
    }
  }

  &__item-wrapper {
    width: 24.7%;

    display: inline-block;

    margin-bottom: 122px;

    @include s {
      margin-bottom: 27px;

      width: 49%;
    }

    @include tablet {
      width: 33%;

      margin-bottom: 40px;
    }

    @include smallD {
      margin-bottom: 60px;
    }

    @include mdl {
      margin-bottom: 80px;
    }
  }
}