/**
 * Fonts
*/

//@font-face {
//  font-family: 'DINP-R';
//  src: url("../fonts/DINPro-Regular.eot?") format("eot"),
//  url("../fonts/DINPro-Regular.woff") format("woff"),
//  url("../fonts/DINPro-Regular.ttf") format("truetype");
//  font-style: normal;
//}

//@import "../../fonts/GothamRounded";

@font-face {
  font-family: 'Gotham Rounded';
  src: local('Gotham Rounded'), url('../../assets/fonts/GothamRounded/GothamRounded-Light.eot');
  src: local('Gotham Rounded'), url('../../assets/fonts/GothamRounded/GothamRounded-Light.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GothamRounded/GothamRounded-Light.woff') format('woff'),
    //url("../../assets/fonts/GothamRounded/GothamRounded-Light.otf") format("opentype"),
  url('../../assets/fonts/GothamRounded/GothamRounded-Light.ttf')  format('truetype');
  font-weight: 100; // light
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: local('Gotham Rounded'), url('../../assets/fonts/GothamRounded/GothamRounded-Medium.eot');
  src: local('Gotham Rounded'), url('../../assets/fonts/GothamRounded/GothamRounded-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GothamRounded/GothamRounded-Medium.woff') format('woff'),
  //url("../../assets/fonts/GothamRounded/GothamRounded-Medium.otf") format("opentype"),
  url('../../assets/fonts/GothamRounded/GothamRounded-Medium.ttf')  format('truetype');
  font-weight: 500; // medium
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: local('Gotham Rounded'), url('../../assets/fonts/GothamRounded/GothamRounded-Bold.eot');
  src: local('Gotham Rounded'), url('../../assets/fonts/GothamRounded/GothamRounded-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GothamRounded/GothamRounded-Bold.woff') format('woff'),
  //url("../../assets/fonts/GothamRounded/GothamRounded-Bold.otf") format("opentype"),
  url('../../assets/fonts/GothamRounded/GothamRounded-Bold.ttf')  format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../../assets/fonts/Poppins/Poppins-Medium.eot');
  src: local('Poppins'), url('../../assets/fonts/Poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Poppins/Poppins-Medium.woff') format('woff'),
    //url("../../assets/fonts/Poppins/Poppins-SemiBold.otf") format("opentype"),
  url('../../assets/fonts/Poppins/Poppins-Medium.ttf')  format('truetype');
  font-weight: 500; // medium
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../../assets/fonts/Poppins/Poppins-SemiBold.eot');
  src: local('Poppins'), url('../../assets/fonts/Poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Poppins/Poppins-SemiBold.woff') format('woff'),
  //url("../../assets/fonts/Poppins/Poppins-SemiBold.otf") format("opentype"),
  url('../../assets/fonts/Poppins/Poppins-SemiBold.ttf')  format('truetype');
  font-weight: 600; //semibold
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../../assets/fonts/Poppins/Poppins-Bold.eot');
  src: local('Poppins'), url('../../assets/fonts/Poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Poppins/Poppins-Bold.woff') format('woff'),
  //url("../../assets/fonts/Poppins/Poppins-Bold.otf") format("opentype"),
  url('../../assets/fonts/Poppins/Poppins-Bold.ttf')  format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}