.head {
  position: relative;
  z-index: 2;

  padding-top: 130px;

  @include s {
    padding-top: 30px;
  }

  &:after {
    content: '';

    position: absolute;
    top: 133px;
    right: 25px;

    width: 110px;
    height: 130px;

    z-index: 3;

    background: url('../../assets/images/MayorWelcome.png') center no-repeat;
    background-size: contain;

    display: none;

    @include s {
      display: block;
    }

    @include sm {
      display: none;
    }
  }

  &__wrapper {
    //width: 1760px;
    width: 91.5%;
    height: 46.5vw;

    max-width: 1756px;
    max-height: 892px;

    margin: -96px auto 0 auto;

    position: relative;

    .head__main-svg {
      position: relative;
      left: -20px;
      width: calc(100% + 42px);
      top: -19px;
      height: calc(100% + 36px);

      @include s {
        width: 480px;
        left: -240px;
      }

      @include tablet {
        left: -10px;
        width: calc(100% + 20px);
        top: -12px;
        height: calc(100% + 20px);
      }
    }

    &:before {
      content: '';

      position: absolute;
      top: 3px;
      left: 18px;

      width: calc(100% - 36px);
      height: calc(100% - 10px);

      z-index: 2;

      background: url('../../assets/images/welcomeMayor.webp') center no-repeat;
      background-size: contain;

      @include s {
        top: -7px;
        left: -300px;

        width: 600px;
        height: calc(100% + 10px);

        background: url('../../assets/images/welcomeMayorMobile.webp') center no-repeat;
        background-size: contain;
        background-position: center;
      }
    }

    &:after {
      content: '';

      position: absolute;
      top: 98px;
      left: 70px;

      width: 110px;
      height: 130px;

      z-index: 3;

      background: url('../../assets/images/MayorWelcome.png') center no-repeat;
      background-size: contain;

      display: none;

      @include sm {
        display: block;
      }
    }

    @include s {
      margin: 5px auto 0 auto;

      width: 1px;
      height: 225px;
    }
  }

  &__form-wrapper {
    position: absolute;
    top: 37.7%;
    left: 4.7%;

    width: 781px;
    height: 695px;
    //height: 520px;

    z-index: 3;

    @include s {
      position: relative;
      top: 0;
      left: 0;

      margin-top: 55px;

      padding: 0 10px;

      width: 100%;
      height: auto;
    }

    @include tablet {
      width: 350px;
      height: 340px;
    }

    @include smallD {
      width: 460px;
      height: 440px;
    }

    @include md {
      width: 580px;
      height: 500px;
    }

    @include l {
      width: 600px;
      height: 540px;
    }

    svg {
      filter: drop-shadow(0 40px 62px rgba(59, 21, 76, 0.91));
    }

    &-content {
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      padding: 70px 60px 75px 76px;

      @include tablet {
        padding: 30px;
      }

      @include smallD {
        padding: 30px;
      }

      @include mdl {
        padding: 40px 40px 40px 40px;
      }
    }
  }

  &__title {
    font-family: Poppins;
    font-weight: bold;
    font-size: 60px;
    color: #2b40ba;
    letter-spacing: -0.028em;
    line-height: 72px;

    @include s {
      font-size: 27px;
      font-weight: 600;
      line-height: 33px;
      color: white;
      text-align: center;
    }

    @include tablet {
      font-size: 24px;
      line-height: 32px;
    }

    @include smallD {
      font-size: 32px;
      line-height: 40px;
    }

    @include md {
      font-size: 40px;
      line-height: 50px;
    }

    @include l {
      font-size: 40px;
      line-height: 50px;
    }
  }

  &__subtitle {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 26px;
    color: #4557ba;
    letter-spacing: -0.007em;
    line-height: 40px;

    margin-top: 45px;

    @include s {
      font-size: 13px;
      line-height: 20px;
      color: #d8feff;
      text-align: center;

      margin: 20px auto 0 auto;
      max-width: 77%;
    }

    @include tablet {
      font-size: 16px;
      line-height: 24px;

      margin-top: 16px;
    }

    @include smallD {
      font-size: 18px;
      line-height: 26px;

      margin-top: 30px;
    }

    @include mdl {
      font-size: 20px;
      line-height: 34px;

      margin-top: 30px;
    }
  }

  &__manage-block {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 63px;

    @include s {
      margin-top: 14px;

      padding: 0 0 0 8px;
    }

    @include tablet {
      margin-top: 15px;
    }

    @include smallD {
      margin-top: 30px;
    }

    @include md {
      margin-top: 30px;
    }
  }

  &__button-wrapper {
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 34px;

      @include s {
        margin-right: 8px;
      }

      @include tablet {
        margin-right: 16px;
      }
    }

    .button {
      display: flex;
      align-items: center;

      height: 80px;
      border-radius: 40px;

      font-size: 24px;
      letter-spacing: 0;

      @include s {
        height: 40px;

        font-size: 12px;
        font-weight: bold;

        padding: 0 10px 0 17px;

        color: white;
      }
    }

    .head__try-btn {
      height: 71px;

      @include s {
        font-size: 12px;
        color: white;

        height: 37px;
      }

      @include tablet {
        font-size: 14px;

        padding: 0 10px 0 20px;

        height: 32px;
      }

      @include smallD {
        font-size: 16px;

        height: 43px;
      }

      @include md {
        height: 59px;

        font-size: 20px;
      }

      @include l {
        height: 61px;

        font-size: 22px;
      }
    }

    &_play {
      width: 320px;
      height: 106px;

      background: url('../../assets/images/playVideoBtnBg.png') center no-repeat;
      background-size: contain;

      position: relative;

      @include s {
        width: 166px;
        height: 53px;

        background: url('../../assets/images/playVideoBtnBgMobile.png') center no-repeat;
        background-size: contain;
      }

      @include tablet {
        width: 170px;
        height: 57px;
      }

      @include smallD {
        width: 194px;
        height: 66px;
      }
    }
  }

  &__try-btn {
    background-image: linear-gradient(to top, rgb(235,95,62) 0%, rgb(253,198,81) 100%);
    box-shadow: 0 14px 24px 0 rgba(203, 51, 7, 0.91),inset 0 7px 7px 0 rgba(255, 238, 217, 0.16),inset 0px -4px 4px 0px rgba(255, 237, 151, 0.47),inset 0px -7px 5px 0px rgba(180, 64, 64, 0.26);
    padding: 0 19px 0 35px;

    position: relative;
    top: -2px;

    @include s {
      padding: 0 19px 0 18px;

      box-shadow: 0px 7px 12px 0px rgba(203, 51, 7, 0.91),inset 0px 4px 4px 0px rgba(255, 238, 217, 0.16),inset 0px -2px 2px 0px rgba(255, 237, 151, 0.47),inset 0px -4px 3px 0px rgba(180, 64, 64, 0.26);

      top: 0;
    }

    @include smallD {
      top: -1px;
    }

    @include mdl {
      top: -1px;
    }
  }

  &__play-ring {
    width: 42px;
    height: 42px;

    border-radius: 50%;

    background: transparent;
    border: 3px solid #fda134;

    margin-left: 17px;

    display: flex;
    justify-content: center;
    align-items: center;

    @include s {
      width: 21px;
      height: 21px;

      margin-left: 8px;

      border-width: 2px;
    }

    @include tablet {
      width: 24px;
      height: 24px;

      margin-left: 12px;
    }

    @include smallD {
      width: 32px;
      height: 32px;
    }
  }

  &__play-button-text {
    font-family: "Gotham Rounded";
    font-weight: bold;
    font-size: 24px;
    color: #324dc6;
    text-transform: uppercase;

    position: absolute;

    top: 35%;
    left: 12.5%;

    @include s {
      font-size: 12px;
      color: white;
    }

    @include tablet {
      font-size: 13px;

      top: 36%;
      left: 7.5%;
    }

    @include smallD {
      font-size: 16px;

      top: 35%;
      left: 10%;
    }

    @include md {
      font-size: 20px;

      top: 38%;
      left: 10%;
    }

    @include l {
      font-size: 22px;

      top: 37%;
      left: 9.5%;
    }
  }

  &__play-button-icon {
    width: 90px;
    height: 90px;

    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 12px;

    position: absolute;
    top: 5px;
    right: 13px;

    background-image: linear-gradient( 90deg, rgb(235,95,62) 0%, rgb(253,198,81) 100%);
    box-shadow: 0px 14px 24px 0px rgba(203, 51, 7, 0.91),inset 0px 7px 7px 0px rgba(255, 238, 217, 0.16),inset 0px -4px 4px 0px rgba(255, 237, 151, 0.47),inset 0px -7px 5px 0px rgba(180, 64, 64, 0.26);

    &:before {
      content: '';

      width: 100%;
      height: 100%;

      border-radius: 50%;

      background-image: linear-gradient( 90deg, rgb(165,210,252) 0%, rgb(250,251,252) 100%);
      box-shadow: 0px 6px 6px 0px rgba(108, 37, 5, 0.5),inset 0px 7px 7px 0px rgba(217, 248, 255, 0.16),inset 0px -4px 4px 0px rgba(151, 237, 255, 0.47),inset 0px -7px 5px 0px rgba(64, 102, 180, 0.26);
    }

    &:after {
      content: '';

      display: block;

      position: absolute;

      background: url('../../assets/images/playVideoIcon.png') center no-repeat;
      background-size: contain;

      width: 20px;
      height: 21px;

      left: calc(50% - 8px);
      top: calc(50% - 11px);

      filter: drop-shadow(0px 3px 3px rgba(255, 255, 255, 0.91));

      @include s {
        width: 10px;
        height: 20px;

        left: calc(50% - 4px);
      }

      @include tablet {
        width: 14px;
        left: calc(50% - 6px);
      }
    }

    @include s {
      width: 45px;
      height: 45px;

      top: 4px;
      right: 9px;

      padding: 6px;

      box-shadow: 0px 7px 12px 0px rgba(203, 51, 7, 0.91),inset 0px 4px 4px 0px rgba(255, 238, 217, 0.16),inset 0px -2px 2px 0px rgba(255, 237, 151, 0.47),inset 0px -4px 3px 0px rgba(180, 64, 64, 0.26);
    }

    @include tablet {
      width: 41px;
      height: 41px;

      padding: 7px;

      top: 7px;
      right: 3px;
    }

    @include smallD {
      width: 52px;
      height: 52px;

      padding: 7px;

      top: 6px;
      right: 5px;
    }

    @include md {
      width: 75px;
      height: 75px;

      top: 14px;
      right: 5px;
    }

    @include l {
      width: 78px;
      height: 78px;

      top: 12px;
      right: 5px;
    }
  }

  &__small-play-icon-wrapper {
    width: 9px;
    height: 10px;

    margin-left: 3px;

    @include s {
      width: 5px;
      height: 5px;
    }
  }

  &__small-play-icon {
    background: url("../../assets/images/smallPlayIcon.svg") center no-repeat;
    background-size: contain;

    width: 100%;
    height: 100%;
  }
}