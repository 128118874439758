.road {
  position: relative;

  z-index: 7;

  overflow: hidden;

  &__top-road-wrapper {
    position: relative;

    display: flex;
    justify-content: center;

    z-index: 5;

    width: 1683px;
    height: 2374px;

    margin: 0 auto;

    overflow: hidden;

    @include s {
      width: 360px;
      height: 2415px;
    }

    @include xs {
      width: 300px;
      height: 2020px;
    }

    @include sm {
      width: 400px;
      height: 2415px;
    }

    @include tablet {
      width: 680px;
      height: 4600px;
    }

    @include smallD {
      width: 980px;
      height: 1490px;
    }

    @include md {
      width: 1120px;
    }

    @include l {
      width: 1360px;
    }

    @include xl {
      width: 1480px;
    }
  }

  &__top-curved-line {
    position: absolute;

    width: 100%;
    height: 1975px;

    top: 0;
    left: 0;

    margin: 0 auto;

    @include s {
      width: 360px;
      height: 2421px;

      position: relative;
      top: -7px;
    }

    @include tablet {
      height: 4300px;
    }

    @include smallD {
      height: 1250px;
    }
  }

  &__section {
    position: absolute;

    background: #f5f7fa;

    padding-top: 76px;

    height: 1169px;

    z-index: 2;

    top: 0;
    left: 0;

    width: 100%;

    &-container {
      position: relative;
      left: 0;

      @include sm {
        width: 400px;
      }

      @include tablet {
        width: 580px;
      }

      @include smallD {
        width: 90%;
      }
    }

    @include s {
      padding-top: 1px;
      height: 1273px;
    }

    @include xs {
      height: 1100px;
    }

    @include tablet {
      height: 2400px;
    }

    @include smallD {
      padding-top: 0;
      height: 800px;
    }

    &_middle {
      z-index: 3;

      height: auto;
      overflow: visible;

      padding: 0;

      top: 49.6%;

      @include s {
        top: 1200px;
      }

      @include xs {
        top: 990px;
      }

      .road__section-container {
        display: flex;
        justify-content: center;
      }
    }

    &_second {
      background: white;

      z-index: 1;

      padding-top: 165px;

      //height: 1207px;

      top: 49.2%;

      height: auto;

      @include s {
        padding-top: 128px;
        padding-left: 9%;

        height: 440px;

        top: 1260px;
      }

      @include xs {
        top: 1180px;
        padding-top: 0;
      }

      @include tablet {
        top: 52%;
      }

      @include smallD {
        top: 45%;
      }

      .road__section-container {
        .road__block-row {
          @include smallD {
            width: 50%;
          }

          @include md {
            width: 50%;
          }
        }
      }
    }
  }

  &__screw {
    position: absolute;

    left: 0;
    bottom: -29px;

    width: 100%;
    height: 30px;

    z-index: 1;

    @include s {
      height: 4px;

      bottom: 8px;
    }
  }

  &__block {
    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include s {
        flex-direction: column;

        padding-left: 11.5%;
      }

      @include tablet {
        flex-direction: column;
      }

      @include md {
        padding: 0 5%;
      }
    }

    &-column {
      display: block;

      @include s {
        order: 2;

        padding-top: 22px;
      }

      @include xs {
        padding-top: 0;
      }

      @include l {
        max-width: 50%;
      }
    }

    &-phase {
      font-family: "Gotham Rounded";
      font-weight: bold;
      font-size: 24px;
      color: #9da9dd;
      letter-spacing: -0.5px;

      @include s {
        font-size: 12px;
        letter-spacing: -0.2px;
      }

      @include smallD {
        font-size: 20px;
      }

      @include md {
        font-size: 22px;
      }
    }

    &-title {
      font-family: Poppins;
      font-weight: 600;
      font-size: 54px;
      line-height: 60px;
      color: #3552ca;
      letter-spacing: -1.7px;

      margin-top: 27px;

      @include s {
        font-size: 27px;
        line-height: 30px;
        letter-spacing: -0.5px;

        margin-top: 15px;
      }

      @include smallD {
        font-size: 36px;
        line-height: 42px;

        margin-top: 10px;
      }

      @include md {
        font-size: 46px;
        line-height: 50px;

        margin-top: 20px;
      }
    }

    &-description {
      font-family: "Gotham Rounded";
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #808dc4;
      letter-spacing: -0.1px;

      padding-top: 43px;

      @include s {
        font-size: 9px;
        line-height: 13px;

        padding-top: 19px;
      }

      @include smallD {
        font-size: 14px;
        line-height: 20px;

        padding-top: 10px;
      }

      @include md {
        font-size: 16px;
        line-height: 24px;

        padding-top: 30px;
      }
    }

    &-icon-wrapper {
      width: 645px;
      height: 385px;

      position: relative;
      right: -49px;
      top: -77px;

      @include s {
        order: 1;

        width: 100%;
        height: 190px;

        right: auto;
        top: auto;
        left: -7px;
      }

      @include sm {
        width: 380px;
      }

      @include tablet {
        width: 540px;
        height: 330px;

        top: auto;
      }

      @include smallD {
        right: -30px;
        top: -30px;

        height: 330px;
      }

      @include md {
        width: 520px;
        height: 340px;

        right: -20px;
        top: -20px;
      }

      &:before {
        content: '';

        width: 100%;
        height: 100%;

        position: absolute;
        left: 0;
        bottom: -37px;

        background: url("../../assets/images/homeShape.png") center no-repeat;
        background-size: contain;

        @include s {
          left: -2px;
          bottom: -17px;
          background-size: 94%;
        }
      }

      &_second-phase {
        position: absolute;
        z-index: 4;

        width: 760px;
        height: 430px;

        right: -163px;
        top: -111px;

        @include s {
          width: 100%;
          height: 190px;

          right: auto;
          top: auto;
          left: -8px;
        }

        @include sm {
          width: 380px;
          left: auto;
          right: auto;
        }

        @include tablet {
          width: 540px;
          height: 330px;

          right: auto;
        }

        @include smallD {
          width: 440px;
          height: 270px;

          right: -30px;
          top: -40px;
        }

        @include md {
          width: 520px;
          height: 340px;

          right: 30px;
          top: 10px;
        }

        @include l {
          width: 700px;
          right: -60px;
        }

        &:before {
          bottom: -60px;

          @include s {
            left: -1px;
            bottom: -22px;
            background-size: 85%;
          }
        }
      }
    }

    &-icon {
      display: block;

      width: 100%;
      height: 100%;

      background-size: 113%;
      background-position: center;
      background-repeat: no-repeat;

      @include s {
        background-size: 105%;
      }

      &.icon-home-phase-3 {
        @include s {
          background-size: 96%;
        }
      }
    }
  }

  &__item {
    //width: 19.53%;
    width: 210px;

    @include s {
      width: 120px;
    }

    position: absolute;

    $rowOneOffset: 20.45%;
    $rowTwoOffset: 34.87%;
    $rowThreeOffset: 66.1%;
    $rowFourOffset: 80.5%;

    $columnOneOffset: 7.2%;
    $columnTwoOffset: 25.05%;
    $columnThreeOffset: 42.8%;
    $columnFourOffset: 60.7%;
    $columnFiveOffset: 78.5%;

    $columnOneOffsetMobile: 21.5%;
    $columnTwoOffsetMobile: 63.05%;

    @include xs {
      $columnOneOffsetMobile: 14%;
      $columnTwoOffsetMobile: 57%;
    }

    @include smallD {
      width: 170px;
      $rowThreeOffset: 66.4%;
      $rowFourOffset: 80.9%;
    }

    @include md {
      $columnOneOffset: 4.2%;
      $columnTwoOffset: 23.05%;
      $columnThreeOffset: 42.8%;
      $columnFourOffset: 60.7%;
      $columnFiveOffset: 80.5%;
    }

    &:nth-child(1) {
      top: $rowOneOffset;
      left: $columnOneOffset;

      @include s {
        top: 440px;
        left: $columnOneOffsetMobile;
      }

      @include xs {
        top: 363px;
      }

      @include tablet {
        top: 855px;
        left: $columnOneOffsetMobile;
      }
    }

    &:nth-child(2) {
      top: $rowOneOffset;
      left: $columnTwoOffset;

      @include s {
        top: 440px;
        left: $columnTwoOffsetMobile;
      }

      @include xs {
        top: 363px;
      }

      @include tablet {
        top: 855px;
        left: $columnTwoOffsetMobile;
      }
    }

    &:nth-child(3) {
      top: $rowOneOffset;
      left: $columnThreeOffset;

      @include s {
        top: 610px;
        left: $columnTwoOffsetMobile;
      }

      @include xs {
        top: 505px;
      }

      @include tablet {
        top: 1176px;
        left: $columnTwoOffsetMobile;
      }
    }

    &:nth-child(4) {
      top: $rowOneOffset;
      left: $columnFourOffset;

      @include s {
        top: 610px;
        left: $columnOneOffsetMobile;
      }

      @include xs {
        top: 505px;
      }

      @include tablet {
        top: 1176px;
        left: $columnOneOffsetMobile;
      }
    }


    &:nth-child(5) {
      top: $rowOneOffset;
      left: $columnFiveOffset;

      @include s {
        top: 736px;
        left: $columnOneOffsetMobile;
      }

      @include xs {
        top: 610px;
      }

      @include tablet {
        top: 1416px;
        left: $columnOneOffsetMobile;
      }

      .road__item-circle {
        &:after {
          @include md {
            width: 130px;
          }
        }
      }
    }

    &:nth-child(6) {
      top: $rowTwoOffset;
      left: $columnOneOffset;

      @include s {
        top: 736px;
        left: $columnTwoOffsetMobile;
      }

      @include tablet {
        top: 1416px;
        left: $columnTwoOffsetMobile;
      }
    }

    &:nth-child(7) {
      top: $rowTwoOffset;
      left: $columnTwoOffset;

      @include s {
        top: 1082px;
        left: $columnOneOffsetMobile;
      }

      @include xs {
        top: 899px;
      }

      @include tablet {
        top: 2069px;
        left: $columnOneOffsetMobile;
      }
    }

    &:nth-child(8) {
      top: $rowTwoOffset;
      left: $columnThreeOffset;

      @include s {
        top: 909px;
        left: $columnOneOffsetMobile;
      }

      @include xs {
        top: 754px;
      }

      @include tablet {
        top: 1736px;
        left: $columnOneOffsetMobile;
      }
    }

    &:nth-child(9) {
      top: $rowTwoOffset;
      left: $columnFourOffset;

      @include s {
        top: 909px;
        left: $columnTwoOffsetMobile;
      }

      @include xs {
        top: 754px;
      }

      @include tablet {
        top: 1736px;
        left: $columnTwoOffsetMobile;
      }
    }

    &:nth-child(10) {
      top: $rowTwoOffset;
      left: $columnFiveOffset;

      @include s {
        top: 736px;
        left: $columnTwoOffsetMobile;
      }

      @include xs {
        top: 610px;
      }

      @include tablet {
        top: 1416px;
        left: $columnTwoOffsetMobile;
      }
    }

    &:nth-child(11) {
      top: $rowThreeOffset;
      left: $columnOneOffset;

      @include s {
        top: 1082px;
        left: $columnTwoOffsetMobile;
      }

      @include xs {
        top: 899px;
      }

      @include tablet {
        top: 2069px;
        left: $columnTwoOffsetMobile;
      }
    }

    &:nth-child(12) {
      top: $rowThreeOffset;
      left: $columnTwoOffset;

      @include s {
        top: 1556px;
        left: $columnTwoOffsetMobile;
      }

      @include xs {
        top: 1294px;
      }

      @include tablet {
        top: 2962px;
        left: $columnTwoOffsetMobile;
      }
    }

    &:nth-child(13) {
      top: $rowThreeOffset;
      left: $columnThreeOffset;

      @include s {
        top: 1556px;
        left: $columnOneOffsetMobile;
      }

      @include xs {
        top: 1294px;
      }

      @include tablet {
        top: 2962px;
        left: $columnOneOffsetMobile;
      }
    }

    &:nth-child(14) {
      top: $rowThreeOffset;
      left: $columnFourOffset;

      @include s {
        top: 1729px;
        left: $columnOneOffsetMobile;
      }

      @include xs {
        top: 1438px;
      }

      @include tablet {
        top: 3289px;
        left: $columnOneOffsetMobile;
      }
    }

    &:nth-child(15) {
      top: $rowThreeOffset;
      left: $columnFiveOffset;

      @include s {
        top: 1729px;
        left: $columnTwoOffsetMobile;
      }

      @include xs {
        top: 1438px;
      }

      @include tablet {
        top: 3289px;
        left: $columnTwoOffsetMobile;
      }
    }



    &:nth-child(16) {
      top: $rowFourOffset;
      left: $columnOneOffset;

      @include s {
        top: 2245px;
        left: $columnOneOffsetMobile;
      }

      @include xs {
        top: 1868px;
      }

      @include tablet {
        top: 4265px;
        left: $columnOneOffsetMobile;
      }
    }

    &:nth-child(17) {
      top: $rowFourOffset;
      left: 21.45%;

      @include s {
        top: 2245px;
        left: $columnTwoOffsetMobile;
      }

      @include xs {
        top: 1868px;
      }

      @include tablet {
        top: 4265px;
        left: $columnTwoOffsetMobile;
      }

      @include md {
        left: 18%;
      }
    }

    &:nth-child(18) {
      top: $rowFourOffset;
      left: 35.8%;

      @include s {
        top: 2076px;
        left: $columnTwoOffsetMobile;
      }

      @include xs {
        top: 1727px;
      }

      @include tablet {
        top: 3944px;
        left: $columnTwoOffsetMobile;
      }

      @include md {
        left: 33%;
      }
    }

    &:nth-child(19) {
      top: $rowFourOffset;
      left: 50%;

      @include s {
        top: 2076px;
        left: $columnOneOffsetMobile;
      }

      @include xs {
        top: 1727px;
      }

      @include tablet {
        top: 3944px;
        left: $columnOneOffsetMobile;
      }

      @include md {
        left: 50%;
      }
    }

    &:nth-child(20) {
      top: $rowFourOffset;
      left: 64.25%;

      @include s {
        top: 1899px;
        left: $columnOneOffsetMobile;
      }

      @include xs {
        top: 1579px;
      }

      @include tablet {
        top: 3611px;
        left: $columnOneOffsetMobile;
      }

      @include md {
        left: 68%;
      }
    }

    &:nth-child(21) {
      top: $rowFourOffset;
      left: $columnFiveOffset;

      @include s {
        top: 1899px;
        left: $columnTwoOffsetMobile;
      }

      @include xs {
        top: 1579px;
      }

      @include tablet {
        top: 3611px;
        left: $columnTwoOffsetMobile;
      }

      @include md {
        left: 84%;
      }
    }

    &:nth-child(11), &:nth-child(12), &:nth-child(13), &:nth-child(14), &:nth-child(15), &:nth-child(16), &:nth-child(17),
    &:nth-child(18), &:nth-child(19), &:nth-child(20), &:nth-child(21) {
      .road__item-title, .road__item-benefit-list {
        @include smallD {
          max-width: 135px;
        }

        @include md {
          max-width: 170px;
        }
      }
    }


    &-circle {
      width: 60px;
      height: 60px;

      border-radius: 50%;

      position: relative;

      padding: 15px;

      display: flex;
      justify-content: center;
      align-items: center;

      background: #e7ebf7;

      @include s {
        width: 30px;
        height: 30px;

        padding: 8px;
      }

      @include tablet {
        width: 40px;
        height: 40px;

        padding: 10px;
      }

      @include smallD {
        width: 44px;
        height: 44px;

        padding: 12px;
      }

      &:before {
        content: '';

        width: 100%;
        height: 100%;

        border-radius: 50%;

        background-color: rgba(84, 97, 150, 0.141);
        box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.91),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);
      }

      &-checkbox {
        width: 30px;
        height: 30px;

        background: url("../../assets/images/timelineCheck.png") center no-repeat;
        background-size: contain;

        position: absolute;
        top: 15px;
        left: 15px;

        filter: drop-shadow(0 6px 6px rgba(12, 103, 5, 0.5));

        opacity: 0;

        @include s {
          width: 16px;
          height: 16px;

          left: 7px;
          top: 7px;
        }

        @include tablet {
          width: 20px;
          height: 20px;

          top: 10px;
          left: 10px;
        }

        @include smallD {
          width: 20px;
          height: 20px;

          top: 12px;
          left: 12px;
        }

        &_current {
          background: url("../../assets/images/timelineCurrent.png") center no-repeat;
          background-size: contain;

          filter: drop-shadow(0 6px 6px rgba(103, 16, 5, 0.5));
        }
      }
    }

    &-title {
      font-family: "Gotham Rounded";
      font-weight: bold;
      font-size: 21px;
      color: #3552ca;
      letter-spacing: -0.5px;

      margin: 27px 0 0 -1px;

      max-width: 240px;

      @include s {
        font-size: 10.5px;

        @include s {
          margin-top: 10px;
        }
      }

      @include tablet {
        font-size: 18px;
      }

      @include smallD {
        font-size: 14px;
        margin-top: 12px;
      }

      @include md {
        font-size: 18px;

        max-width: 190px;
      }
    }

    &-benefit-list {
      margin-top: 21px;

      max-width: 240px;

      @include s {
        margin-top: 10px;
      }

      @include xs {
        margin-top: 8px;
      }

      @include tablet {
        margin-top: 10px;
      }

      @include smallD {
        margin-top: 10px;
      }

      @include md {
        margin-top: 15px;
        max-width: 190px;
      }
    }

    &-benefit {
      display: flex;

      font-family: "Gotham Rounded";
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #808dc4;

      @include s {
        font-size: 9px;
        line-height: 15px;
      }

      @include xs {
        font-size: 8px;
        line-height: 11px;
      }

      @include tablet {
        font-size: 14px;
        line-height: 22px;
      }

      @include smallD {
        font-size: 14px;
        line-height: 18px;
      }

      &:before {
        content: '';

        width: 5px;
        min-width: 5px;
        height: 5px;
        border-radius: 50%;

        margin: 10px 12px 0 0;

        background: #808dc4;

        @include s {
          width: 2px;
          height: 2px;
          min-width: 2px;

          margin: 5px 6px;
        }
      }

      &:not(:last-child) {
        margin-bottom: -2px;
      }
    }

    &_active {
      .road__item-circle {
        background: #7dcc36;

        &-checkbox {
          opacity: 1;
        }

        &:after {
          content: '';

          width: 405%;
          height: 4px;

          background: #7dcc36;

          position: absolute;
          top: calc(50% - 4px);
          left: 59px;

          @include s {
            display: none;

            height: 2px;
            top: calc(50% - 0px);
            left: 29px;
          }

          @include md {
            width: 165px;
          }
        }
      }

      &-no-line {
        .road__item-circle {
          &:after {
           display: none;
          }
        }
      }
    }

    &_active-current {
      .road__item-circle {
        &:after {
          background-image: linear-gradient(to right, rgb(128,205,54) 0%, rgb(229,232,67) 50%, rgba(255,153,69, 0.9) 100%);
        }
      }
    }

    &_active-current-mobile {
      .road__item-circle {
        &:after {
          content: '';
          background-image: linear-gradient(to left, rgb(128,205,54) 0%, rgb(229,232,67) 50%, rgba(255,153,69, 0.9) 100%);

          @include s {
            display: block !important;
          }
        }
      }
    }

    &_current {
      .road__item-circle {
        background-image: linear-gradient(to right, rgba(255,153,69, 0.9), rgb(255,153,69));

        &-checkbox {
          opacity: 1;
        }
      }
    }
  }
}