.popup {
  width: 1100px;
  height: 834px;

  position: relative;

  //position: absolute;
  //top: 183px;

  @include s {
    width: 90%;
    height: 410px;
  }

  @include sm {
    width: 90%;
    height: 410px;
  }

  @include tablet {
    width: 90%;
    height: 600px;
  }

  &__close-icon-wrapper {
    position: absolute;

    top: -10px;
    right: -10px;

    width: 56px;
    height: 56px;

    border-radius: 50%;

    background: linear-gradient(180deg, #FF9A48 0%, #F25625 100%);
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25), inset 0px -3px 3px #F18749, inset 0px -12px 18px rgba(255, 46, 0, 0.5), inset 0px 4px 4px rgba(255, 213, 104, 0.6), inset 0px 0px 6px rgba(184, 55, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    padding: 14px;

    z-index: 5;

    @include s {
      width: 40px;
      height: 40px;

      padding: 10px;
    }

    &:before {
      content: '';

      background: url("../../assets/images/close-icon.png") center no-repeat;
      background-size: contain;

      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__main-svg {
    filter: drop-shadow(0px 100px 100px rgba(0, 0, 0, 0.6));
  }

  &__wrapper {
    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;

    z-index: 500;

    opacity: 0;
    pointer-events: none;

    transition: opacity 0.5s ease-in-out;

    &_show {
      opacity: 1;
      pointer-events: all;
    }

    &.before {
      .popup {
        &__title {
          &_before {
            display: block;
          }

          &_after {
            display: none;
          }
        }

        &__description {
          &_before {
            display: block;
          }

          &_after {
            display: none;
          }
        }

        .icon-centralized-exchange {
          display: block;
        }

        .icon-mexc {
          display: none;
        }
      }
    }

    &.after {
      .popup {
        &__title {
          max-width: 100%;

          &_before {
            display: none;
          }

          &_after {
            display: block;
          }
        }

        &__description {
          &_before {
            display: none;
          }

          &_after {
            display: block;
          }
        }

        .icon-centralized-exchange {
          display: none;
        }

        .icon-mexc {
          display: block;
        }

        &__list-wrapper {
          margin-top: 87px;
        }
      }
    }
  }

  &__form {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    padding: 80px 120px;

    @include s {
      padding: 35px 20px;
    }

    @include sm {
      padding: 35px 20px;
    }

    @include tablet {
      padding: 50px 60px;
    }

    iframe {
      @include s {
        width: 100%;
        height: 200px;
      }
    }
  }

  &__title {
    font-family: Poppins;
    font-weight: bold;
    font-size: 60px;
    line-height: 70px;
    color: #314AC3;
    text-align: center;

    max-width: 90%;
    margin: 0 auto;

    &.hide {
      display: none;
    }

    @include s {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__description {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 26px;
    color: #314AC3;
    text-align: center;

    max-width: 90%;
    margin: 40px auto 0 auto;

    .link {
      color: inherit;
      font-size: inherit;
      text-decoration: underline;
    }

    &.hide {
      display: none;
    }

    @include s {
      font-size: 16px;
      line-height: 20px;

      margin-top: 20px;
    }
  }

  &__list-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 56px;
    padding: 0 16px;

    @include s {
      margin-top: 10px;
      padding: 0;
    }
  }

  &__list-item {
    margin: 0 10px;

    background: url("../../assets/images/popupBraggingForm.png") no-repeat center;
    background-size: contain;

    width: 253px;
    height: 138px;

    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @include s {
      width: 90px;
      height: 50px;

      margin: 0 4px;
    }
  }

  &__list-icon {
    width: 100%;
    height: 100%;

    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    position: relative;

    &.icon-competition-uniswap {
      background-size: 74%;
      left: -2px;
      top: -5px;

      @include s {
        top: -1px;
      }
    }

    &.icon-pancake-dark {
      background-size: 68%;
      top: -7px;

      @include s {
        top: -2px;
      }
    }

    &.icon-centralized-exchange {
      background-size: 65%;
      left: 1px;
      top: -6px;

      @include s {
        top: -1px;
      }
    }

    &.icon-mexc {
      display: block;
      background-size: 57%;
      position: relative;
      left: 3px;
      top: -7px;

      @include s {
        top: -1px;
        left: 0;
      }
    }
  }

  &__input-form-wrapper {
    margin-top: 81px;
    padding: 0 16px;

    @include s {
      margin-top: 20px;
      padding: 0;
    }
  }

  &__input-wrapper {
    display: flex;
    align-items: center;

    position: relative;

    width: 100%;
    height: 64px;

    border-radius: 32px;

    background: linear-gradient(180deg, #D3D7E6 0%, #EAEDF8 100%);
    box-shadow: 0px 2px 2px rgba(255, 255, 255, 0.6), inset 0px 3px 3px rgba(8, 24, 113, 0.2);

    &.error {
      box-shadow: 0px 2px 2px rgba(255, 255, 255, 0.6), inset 0 0 0 3px #FF3434;

      .popup__input {
        color: #FF3434;
      }
    }

    @include s {
      height: 40px;
    }
  }

  &__input {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #314AC3;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;

    padding: 5px 200px 5px 48px;

    background: transparent;

    border: none;

    &::placeholder {
      color: #314AC3;
    }

    @include s {
      font-size: 16px;
      line-height: 20px;

      padding: 6px 110px 5px 20px;
    }
  }

  &__button {
    width: 160px;
    height: 100%;

    background: linear-gradient(180deg, #FF9A48 0%, #F25625 100%);
    box-shadow: 0px 12px 24px rgba(242, 86, 37, 0.35), inset 0px -3px 3px #F18749, inset 0px -7px 4px rgba(255, 46, 0, 0.6), inset 0px 4px 4px rgba(255, 213, 104, 0.6), inset 0px 0px 6px 3px rgba(184, 55, 0, 0.3);
    border-radius: 32px;

    border: none;

    font-family: "Gotham Rounded";
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    color: white;

    cursor: pointer;

    position: absolute;
    top: 0;
    right: 0;

    &.submit {
      background: linear-gradient(180deg, #10ECAC 0%, #01956A 100%);
      box-shadow: 0px 12px 24px rgba(5, 103, 74, 0.25), inset 0px -3px 3px #015C42, inset 0px -7px 4px #01956A, inset 0px 0px 6px 3px rgba(184, 55, 0, 0.3);
    }

    &.error {
      background: linear-gradient(180deg, #FF513E 0%, #E51E08 100%);
      box-shadow: 0px 12px 24px rgba(242, 86, 37, 0.35), inset 0px -3px 3px #F18749, inset 0px -7px 4px rgba(255, 46, 0, 0.6), inset 0px 4px 4px rgba(255, 213, 104, 0.6), inset 0px 0px 6px 3px rgba(184, 55, 0, 0.3);
    }

    @include s {
      width: 100px;
      font-size: 14px;
    }
  }

  &__vouched-form-data {
    width: 100%;
    height: 100%;

    #vouched-element {
      width: 100%;
      height: 100%;

      position: relative;

      z-index: 10;

      overflow-y: scroll;
    }
  }
}

.icon-pancake-dark {
  background-image: url("../../assets/images/pancake-dark.png");
}

.icon-centralized-exchange {
  background-image: url("../../assets/images/centralized-exchange.png");
}

.icon-mexc {
  background-image: url("../../assets/images/mexc.png");
}