.button {
  font-family: "Gotham Rounded";
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.667;
  letter-spacing: 0.02em;
  color: white;

  border: none;

  width: auto;
  height: 60px;

  border-radius: 30px;

  cursor: pointer;

  display: flex;
  align-items: center;

  @include s {
    font-size: 9px;
    height: 30px;
  }

  @include sm {
    font-size: 12px;
    height: 36px;
  }

  @include tablet {
    font-size: 13px;
    height: 46px;

    padding: 0 12px;

    white-space: nowrap;
  }

  @include smallD {
    font-size: 15px;
    height: 50px;

    padding: 0 10px;

    white-space: nowrap;
  }

  &_orange {
    background: linear-gradient(to top, rgb(235,95,62) 0%, rgb(253,198,81) 100%);
    box-shadow: 0 8px 11px 0 rgba(27, 47, 165, 0.91),inset 0 7px 7px 0 rgba(255, 238, 217, 0.16),inset 0px -4px 4px 0px rgba(255, 237, 151, 0.47),inset 0px -7px 5px 0px rgba(180, 64, 64, 0.26);
  }

  &_active-orange {
    background: linear-gradient(180deg, #FF9A48 0%, #F25625 100%);
    box-shadow: 0px 12px 24px rgba(242, 86, 37, 0.35), inset 0px -3px 3px #F18749, inset 0px -7px 4px rgba(255, 46, 0, 0.6), inset 0px 4px 4px rgba(255, 213, 104, 0.6), inset 0px 0px 6px rgba(184, 55, 0, 0.3);
  }

  &_orange-note {
    border: 3px solid #ff832a;
    background-color: transparent;
    color: white;
  }

  &_disabled {
    background: linear-gradient(180deg, #DBE3EB 0%, #C4C4C4 100%);
    box-shadow: 0px 12px 24px rgba(119, 119, 119, 0.25), inset 0px -3px 3px #A3A3A3, inset 0px -7px 4px rgba(193, 193, 193, 0.6), inset 0px 4px 4px rgba(238, 238, 238, 0.6), inset 0px 0px 6px rgba(14, 4, 0, 0.3);
    border-radius: 32px;
    color: #A9A9A9;
    cursor: not-allowed;
  }
}