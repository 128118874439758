.reward {
  width: 100%;

  background: white;

  position: relative;

  padding-top: 120px;
  padding-bottom: 223px;

  @include s {
    padding-top: 37px;
    padding-bottom: 40px;
  }

  @include tablet {
    padding-top: 60px;
    padding-bottom: 100px;
  }

  @include smallD {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  @include mdl {
    padding-top: 90px;
    padding-bottom: 120px;
  }

  &__screw {
    position: absolute;
    left: 0;
    width: 100%;
    height: 30px;

    z-index: 3;
    top: -29px;

    @include s {
      height: 3px;
      top: -14px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include s {
      flex-direction: column;
      justify-content: flex-start;
    }

    @include tablet {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__info-column {
    display: flex;
    flex-direction: column;

    width: 40%;

    padding-top: 100px;

    @include s {
      width: 100%;
      padding-top: 0;
    }

    @include tablet {
      width: 100%;
      padding-top: 0;
    }

    @include smallD {
      width: 38%;
    }

    @include mdl {
      padding-top: 60px;
    }
  }

  &__icon-wrapper {
    width: 60%;
    height: 600px;

    min-width: 955px;

    position: relative;
    right: -65px;

    @include s {
      right: 0;
      left: 3px;

      margin-top: 8px;

      min-width: auto;
      width: 102%;
      height: 213px;
    }

    @include tablet {
      right: 0;
      left: 3px;

      margin-top: 20px;

      min-width: auto;
      width: 530px;
      height: 330px;
    }

    @include smallD {
      width: 580px;
      height: 410px;
      min-width: auto;

      right: 0;
    }

    @include mdl {
      height: 530px;
      min-width: auto;
    }

    @include md {
      right: 0;
    }
  }

  &__icon {
    background: url('../../assets/images/reward.png') center no-repeat;
    background-size: contain;

    display: block;
    width: 100%;
    height: 100%;

    filter: drop-shadow(0px 20px 35px rgba(0, 0, 0, 0.25));
  }

  &__title {
    font-family: Poppins;
    font-weight: 500;
    line-height: 78px;
    text-align: left;
    letter-spacing: -2.4px;

    @include s {
      text-align: center;
      letter-spacing: -1px;
      line-height: 34px;
    }

    @include tablet {
      text-align: center;
      letter-spacing: -1px;
      line-height: 34px;
    }

    @include smallD {
      font-size: 48px;
      line-height: 56px;
    }

    @include mdl {
      font-size: 54px;
      line-height: 60px;
    }
  }

  &__subtitle {
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.9px;
    text-align: left;

    margin-top: 36px;

    @include s {
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.5px;

      margin-top: 9px;
    }

    @include tablet {
      text-align: center;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.5px;

      margin-top: 9px;
    }

    @include smallD {
      font-size: 26px;
      line-height: 34px;
    }

    @include mdl {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__description {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 23px;
    color: #3552ca;
    line-height: 40px;

    margin-top: 36px;

    @include s {
      font-size: 11px;
      line-height: 15px;
      text-align: center;

      margin: 11px auto 0 auto;
      max-width: 75%;
    }

    @include tablet {
      font-size: 13px;
      line-height: 20px;
      text-align: center;

      margin: 15px auto 0 auto;
      max-width: 100%;
    }

    @include smallD {
      font-size: 20px;
      line-height: 28px;
    }

    @include mdl {
      font-size: 18px;
      line-height: 24px;
    }
  }
}