.best-blockchain {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 138px 0 105px 0;

  position: relative;

  @include s {
    padding-top: 41px;
    padding-bottom: 29px;
  }

  @include tablet {
    padding-top: 90px;
    padding-bottom: 60px;
  }

  @include smallD {
    padding-top: 90px;
    padding-bottom: 60px;
  }

  @include md {
    padding-top: 110px;
    padding-bottom: 90px;
  }

  &__screw {
    position: absolute;
    left: 0;
    width: 100%;
    height: 30px;

    z-index: 3;

    bottom: -29px;

    @include s {
      height: 4px;
      bottom: 7px;
    }
  }

  &__title {
    letter-spacing: 0;
  }

  &__subtitle {
    margin-top: 41px;

    @include s {
      margin: 21px auto 0 auto;
      max-width: 75%;
    }

    @include md {
      margin-top: 30px;
    }
  }
}