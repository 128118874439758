@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* Core variables */
@import "base/vars";

/* CSS Reset */
@import 'base/reset';

/* CSS print */
@import 'base/print';

/* Fonts */
@import 'base/fonts';

/* Mixins, helpers and functions */
@import 'base/mixins';

@import 'base/sizes';


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  box-shadow: none;

  //-webkit-backface-visibility: hidden;
  //-webkit-transform-style: preserve-3d;
}

html {
  overflow-x: hidden;
}

html, body {
  width: 100%;
  min-height: 100vh;

  @include s {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

body {
  &.body_ido {
    position: relative;

    &:before {
      background: url("../assets/images/HODLValleyCItyMap.webp") center no-repeat;
      background-size: cover;

      content: '';
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      filter: blur(6px);
    }

    &:after {
      content: '';

      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background: rgba(200, 200, 200, 0.1);
    }
  }

  .body-content {
    position: relative;
    z-index: 5;
  }
}

.container {
  width: 1440px;
  margin: 0 auto;

  @include xs {
    width: 97%;
  }

  @include s {
    width: 96%;
  }

  @include sm {
    width: 95%;
  }

  @include tablet {
   width: 95%;
  }

  @include smallD {
    width: 95%;
  }

  @include md {
    width: 1140px;
  }

  @include l {
    width: 1210px;
  }
}

.icon {
  display: block;
}

.flex {
  display: flex;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.justify {
  &-center {
    justify-content: center;
  }

  &-between {
    justify-content: space-between;
  }
}

.items-center {
  align-items: center;
}

.top-wrapper {
  background: $light-blue;

  padding-bottom: 133px;

  position: relative;
  z-index: 10;

  &_home {
    box-shadow: 0 37px 57px 0 rgba(55, 4, 77, 0.46);
  }

  @include s {
    padding-bottom: 40px;
  }

  @include tablet {
    padding-bottom: 40px;
  }
}

.freez {
  max-height: 100vh;
  overflow: hidden;
}

.temp-hide {
  visibility: hidden;
}

.title {
  font-family: Poppins;
  font-weight: 600;
  font-size: 66px;
  line-height: 72px;
  color: #3552ca;
  text-align: center;
  letter-spacing: -2px;

  position: relative;

  &_light {
    color: white;
  }

  @include s {
    font-size: 27px;
    line-height: 33px;
  }

  @include sm {
    font-size: 29px;
    line-height: 35px;
  }

  @include tablet {
    font-size: 34px;
    line-height: 40px;
  }

  @include smallD {
    font-size: 44px;
    line-height: 52px;
  }

  @include md {
    font-size: 54px;
    line-height: 60px;
  }
}

.subtitle {
  font-family: "Gotham Rounded";
  font-weight: 500;
  font-size: 24px;
  line-height: 41px;
  color: #4557ba;
  text-align: center;
  letter-spacing: -0.25px;

  &_light {
    color: #d8feff;
  }

  @include s {
    font-size: 11px;
    line-height: 15px;
  }

  @include sm {
    font-size: 14px;
    line-height: 20px;
  }

  @include tablet {
    font-size: 16px;
    line-height: 22px;
  }

  @include smallD {
    font-size: 18px;
    line-height: 24px;
  }

  @include md {
    font-size: 20px;
    line-height: 30px;
  }
}

.nowrap {
  white-space: nowrap;
}

/* Components */
@import "components/header";
@import "components/navMenu";
@import "components/button";
@import "components/head";
@import "components/benefits";
@import "components/benefit";
@import "components/bestBlockchain";
@import "components/properties";
@import "components/statistic";
@import "components/swap";
@import "components/nft";
@import "components/farm";
@import "components/reward";
@import "components/best";
@import "components/competition";
@import "components/bragging";
@import "components/footer";
@import "components/socialFooter";
@import "components/popup";
@import "components/smallScreenPopup";
@import "components/youtubePopup";
@import "components/offeringHead";
@import "components/tokenomics";
@import "components/growth";
@import "components/decentralize";
@import "components/roadmap";
@import "components/road";
@import "components/calculator";
@import "components/partnerships";

@import "components/economyHead";
@import "components/demand";
@import "components/operator";
@import "components/metaverse";
@import "components/citizen";

@import "components/ido";
@import "components/checkbox";

@import "components/404";

@import "components/503";

@import "components/cookie-form";

@import "components/bridge";
@import "components/bridge-form";
@import "components/hodl";

.invisible {
  display: none;

  &_mobile {
    @include s {
      display: none;
    }
  }

  &_tablet {
    @include tablet {
      display: none;
    }
  }

  &_mobile_tablet {
    @include s {
      display: none;
    }

    @include tablet {
      display: none;
    }
  }

  &_not-mobile {
    @include ns {
      display: none;
    }
  }

  &_not-mobile_tablet {
    @include nst {
      display: none;
    }
  }
}