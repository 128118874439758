.best {
  width: 100%;

  background: #f5f7fa;

  position: relative;

  padding-top: 123px;
  padding-bottom: 165px;

  @include s {
    padding-top: 39px;
    padding-bottom: 42px;
  }

  @include tablet {
    padding-top: 80px;
    padding-bottom: 100px;
  }

  @include smallD {
    padding-top: 60px;
    padding-bottom: 90px;
  }

  @include mdl {
    padding-top: 100px;
    padding-bottom: 120px;
  }

  &__screw {
    width: 100%;
    height: 30px;

    position: absolute;
    left: 0;

    z-index: 2;

    &_top {
      top: -30px;

      @include s {
        height: 3px;
        top: -14px;
      }
    }

    &_bottom {
      bottom: -30px;

      @include s {
        height: 3px;
        bottom: 8px;
      }
    }
  }

  &__title {
    letter-spacing: -1.5px;
  }

  &__subtitle {
    line-height: 39px;

    margin: 34px auto 0 auto;

    @include s {
      margin-top: 21px;
      line-height: 15px;
    }

    @include tablet {
      margin-top: 20px;
    }

    @include mdl {
      line-height: 30px;
    }
  }

  &__token-list {
    margin-top: 122px;

    text-align: center;

    @include s {
      margin-top: 40px;
      text-align: center;

      padding: 0 23px;
    }

    @include tablet {
      margin-top: 40px;
    }

    @include smallD {
      margin-top: 30px;
    }

    @include mdl {
      margin-top: 40px;
    }

    &_second {
      margin-top: 108px;

      @include s {
        margin-top: 0;
      }

      @include tablet {
        margin-top: 30px;
      }

      @include smallD {
        margin-top: 50px;
      }

      @include mdl {
        margin-top: 50px;
      }
    }
  }

  &__token-wrapper {
    margin: 0 67px;

    display: inline-flex;

    @include s {
      margin: 0 0 46px 0;

      width: 48%;

      align-items: center;
      justify-content: center;
    }

    @include tablet {
      margin: 0 30px;
    }

    @include smallD {
      margin: 0 34px;
    }

    @include mdl {
      margin: 0 44px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;

      @include s {
        margin-bottom: 0;
      }
    }
  }

  &__token {
    width: 150px;
    height: 150px;

    border-radius: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #e7ebf7;

    box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.91),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);

    transition: background-color 0.25s ease-in-out, transform 0.25s ease-in-out;

    position: relative;

    @include s {
      width: 75px;
      height: 75px;

      border-radius: 20px;
    }

    @include tablet {
      width: 100px;
      height: 100px;

      border-radius: 20px;
    }

    @include smallD {
      width: 130px;
      height: 130px;
    }

    &:after {
      content: '';

      position: absolute;

      width: calc(100% + 34px);
      height: calc(100% + 34px);

      left: -27px;
      top: -27px;

      box-sizing: content-box;
      border: 10px solid #ebeff9;
      border-radius: 66px;

      opacity: 0;

      transition: opacity 0.25s ease-in-out;

      @include s {
        width: calc(100% + 18px);
        height: calc(100% + 18px);

        left: -14px;
        top: -12px;

        border-width: 4px;
        border-radius: 30px;
      }

      @include tablet {
        width: calc(100% + 24px);
        height: calc(100% + 24px);
        left: -20px;
        top: -20px;

        border-width: 8px;

        border-radius: 40px;
      }

      @include smallD {
        width: calc(100% + 24px);
        height: calc(100% + 24px);
        left: -20px;
        top: -20px;

        border-width: 8px;

        border-radius: 60px;
      }

      @include mdl {
        width: calc(100% + 24px);
        height: calc(100% + 24px);
        left: -20px;
        top: -20px;

        border-width: 8px;

        border-radius: 60px;
      }
    }

    &:hover {
      background: #3552ca;

      transform: scale(1.2);

      @include tablet {
        transform: scale(1.1);
      }

      @include smallD {
        transform: scale(1.1);
      }

      @include mdl {
        transform: scale(1.1);
      }

      &:after, .best__token-bg {
        opacity: 1;

        @include s {
          opacity: 0;
        }
      }

      .best__token-icon-wrapper {
        transform: scale(1.2);

        @include tablet {
          transform: scale(1.1);
        }

        @include mdl {
          transform: scale(1.1);
        }
      }

      .best__token-icon {
        filter: drop-shadow(0px 8px 11px rgba(27, 47, 165, 0.91));
      }
    }
  }

  &__token-bg {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    overflow: hidden;

    border-radius: 40px;

    opacity: 0;

    transition: opacity 0.25s ease-in-out;

    &:before {
      content: '';

      position: absolute;

      top: 0;
      left: 50%;

      width: 0;
      height: 0;

      background-color: transparent;
      box-shadow: 0 0 60px 60px rgba(77, 203, 255, 0.35);
    }
  }

  &__token-icon-wrapper {
    width: 65%;
    height: 65%;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: transform 0.25s ease-in-out;

    position: relative;
    z-index: 2;

    &_question {
      width: 33%;
    }
  }

  &__token-icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    width: 100%;
    height: 100%;

    display: block;

    &.icon-avalanche {
      background-size: 85%;
    }
  }
}

.icon-eth {
  background-image: url("../../assets/images/eth.png");
}

.icon-btc {
  background-image: url("../../assets/images/btc.png");
}

.icon-some-token {
  background-image: url("../../assets/images/someCoin.png");
}

.icon-binance-coin {
  background-image: url("../../assets/images/binanceCoin.png");
}

.icon-question {
  background-image: url("../../assets/images/questionMark.png");
}

.icon-avalanche {
  background-image: url("../../assets/images/avalanche.png");
}

.icon-fantom {
  background-image: url("../../assets/images/fantom.png");
}