.social-footer {
  background: #f5f7fa;

  padding: 62px 0;

  position: relative;

  @include s {
    padding-top: 29px;
    padding-bottom: 28px;
  }

  @include md {
    padding: 54px 0;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include s {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__social-list {
    display: flex;
  }

  &__social-item-wrapper {
    margin: 0 5px;

    @include s {
      margin: 0 2.5px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__social-item {
    width: 60px;
    height: 60px;

    border-radius: 50%;

    padding: 7px;

    overflow: hidden;

    background-color: rgba(53, 82, 202, 0.071);
    box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.91),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;

    @include s {
      width: 30px;
      height: 30px;

      padding: 3px 3px;
    }

    @include sm {
      width: 36px;
      height: 36px;
    }

    @include tablet {
      width: 46px;
      height: 46px;
    }

    @include md {
      width: 50px;
      height: 50px;
    }
  }

  &__social-item-icon-wrapper {
    width: 100%;
    height: 100%;

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px;

    @include s {
      padding: 5px;
    }

    &_facebook {
      background-image: linear-gradient( 0deg, rgb(89,100,255) 0%, rgb(66,145,255) 100%);
      box-shadow: 0px 8px 11px 0px rgba(6, 112, 202, 0.91),inset 0px 7px 7px 0px rgba(255, 255, 255, 0.13),inset 0px -4px 4px 0px rgba(151, 246, 255, 0.47),inset 0px -7px 5px 0px rgba(64, 102, 180, 0.26);
    }

    &_twitter {
      background-image: linear-gradient( 0deg, rgb(54,139,255) 0%, rgb(38,215,255) 100%);
      box-shadow: 0px 8px 11px 0px rgba(6, 112, 202, 0.91),inset 0px 7px 7px 0px rgba(255, 255, 255, 0.13),inset 0px -4px 4px 0px rgba(151, 246, 255, 0.47),inset 0px -7px 5px 0px rgba(64, 102, 180, 0.26);
    }

    &_reddit {
      background-image: linear-gradient( 0deg, rgb(195,39,41) 0%, rgb(249,98,57) 73%, rgb(250,150,57) 100%);
      box-shadow: 0px 8px 11px 0px rgba(202, 43, 6, 0.91),inset 0px 7px 7px 0px rgba(255, 255, 255, 0.13),inset 0px -4px 4px 0px rgba(255, 249, 151, 0.69),inset 0px -7px 5px 0px rgba(125, 27, 25, 0.26);
    }

    &_medium {
      background-image: linear-gradient( 0deg, rgb(45,45,47) 0%, rgb(94,94,94) 100%);
      box-shadow: 0px 8px 11px 0px rgba(54, 54, 54, 0.91),inset 0px 7px 7px 0px rgba(255, 255, 255, 0.24),inset 0px -4px 4px 0px rgba(255, 255, 255, 0.39),inset 0px -7px 5px 0px rgba(191, 191, 191, 0.26);
    }

    &_youtube {
      background-image: linear-gradient( 0deg, rgb(252,45,80) 0%, rgb(252,84,75) 100%);
      box-shadow: 0px 8px 11px 0px rgba(222, 24, 33, 0.91),inset 0px 7px 7px 0px rgba(255, 255, 255, 0.13),inset 0px -4px 4px 0px rgba(255, 227, 194, 0.47),inset 0px -7px 5px 0px rgba(180, 64, 88, 0.26);
    }

    &_discord {
      background-image: linear-gradient( 0deg, rgb(88,101,242) 0%, rgb(100,113,253) 100%);
      box-shadow: 0px 8px 11px 0px rgba(47, 62, 216, 0.91),inset 0px 7px 7px 0px rgba(255, 255, 255, 0.13),inset 0px -4px 4px 0px rgba(139, 194, 255, 0.47),inset 0px -7px 5px 0px rgba(11, 26, 185, 0.26);
    }

    &_instagram {
      box-shadow: 0px 8px 11px 0px rgba(222, 24, 33, 0.91);

      background: url("../../assets/images/instagramBg.png") no-repeat center;
      background-size: cover;
    }
  }

  &__social-item-icon {
    display: block;

    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &__copyright-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    padding-right: 36px;

    @include s {
      padding: 0;

      margin-top: 13px;
    }
  }

  &__copyright-text {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 15px;
    color: #adb3bc;
    line-height: 25px;
    letter-spacing: -0.2px;

    @include s {
      font-size: 7.5px;
      line-height: 12px;
      white-space: nowrap;
    }

    @include sm {
      font-size: 12px;
      line-height: 16px;
    }

    @include md {
      font-size: 14px;
    }
  }
}

.icon-facebook {
  background-image: url("../../assets/images/facebook.png");
}

.icon-twitter {
  background-image: url("../../assets/images/twitter.png");
}

.icon-telegram {
  background-image: url("../../assets/images/telegram.png");
}

.icon-reddit {
  background-image: url("../../assets/images/reddit.png");
}

.icon-medium {
  background-image: url("../../assets/images/medium.png");
}

.icon-youtube {
  background-image: url("../../assets/images/youtube.png");
}

.icon-discord {
  background-image: url("../../assets/images/discord.png");
}

.icon-github {
  background-image: url("../../assets/images/github.png");
}

.icon-instagram {
  background-image: url("../../assets/images/instagram.png");
}