.nft {
  background: #f5f7fa;

  padding-top: 147px;
  padding-bottom: 151px;

  @include s {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include tablet {
    padding-top: 90px;
    padding-bottom: 40px;
  }

  @include smallD {
    padding-top: 80px;
    padding-bottom: 90px;
  }

  @include mdl {
    padding-top: 110px;
    padding-bottom: 120px;
  }

  &__title {
    @include s {
      margin: 0 auto;
      max-width: 55%;
    }
  }

  &__subtitle {
    margin-top: 23px;

    @include s {
      margin: 20px auto 0 auto;
      max-width: 75%;
    }
  }

  &__slide-list {
    margin-top: 9px;
  }

  &__slide {
    width: 480px;
    height: 622px;
    margin: 0 auto;

    position: relative;

    transition: width 0.5s linear, height 0.5s linear;

    @include s {
      width: 240px;
      height: 311px;
    }

    @include tablet {
      width: 310px;
      height: 420px;
    }

    @include smallD {
      width: 310px;
      height: 420px;
    }

    @include md {
      width: 350px;
      height: 530px;
    }

    @include l {
      width: 380px;
      height: 560px;
    }

    * {
      -webkit-backface-visibility: hidden;
      -webkit-transform-style: preserve-3d;
    }
  }

  &__slide-content-wrapper {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__slide-icon-wrapper {
    width: 92%;
    height: 330px;

    margin-top: 18%;
    margin-bottom: 46px;

    transition: width 0.25s linear, height 0.25s linear, margin 0.25s linear;

    @include s {
      margin-top: 8%;
      margin-bottom: 24px;

      height: 165px;
    }

    @include tablet {
      margin-top: 18%;
      margin-bottom: 46px;

      height: 190px;
    }

    @include smallD {
      margin-top: 18%;
      margin-bottom: 46px;

      height: 190px;
    }

    @include md {
      height: 270px;

      margin-top: 16%;
    }

    @include l {
      height: 270px;

      margin-top: 16%;
    }
  }

  &__slide-icon {
    display: block;
    width: 100%;
    height: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: 110%;

    filter: drop-shadow(0px 4px 4px rgba(4, 16, 86, 0.75));

    left: 10px;
  }

  &__slide-svg {
    path, rect {
      fill: #e7ebf7;
    }
  }

  &__slide-title {
    font-family: "Poppins";
    font-size: 30px;
    text-align: center;
    color: #3552ca;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -1.1px;

    transition: font-size 0.25s linear;

    @include s {
      font-size: 24px;
    }

    @include tablet {
      font-size: 24px;
    }

    @include smallD {
      font-size: 24px;
    }
  }

  &__slide-subtitle {
    font-family: "Gotham Rounded";
    font-size: 18px;
    font-weight: 500;
    color: #4557ba;
    text-align: center;
    line-height: 26px;
    letter-spacing: -0.1px;

    margin-top: 20px;

    max-width: 80%;

    transition: font-size 0.25s linear;

    @include s {
      font-size: 10px;
      line-height: 14px;

      margin-top: 10px;
    }

    @include tablet {
      font-size: 13px;
      line-height: 18px;

      margin-top: 10px;
    }

    @include smallD {
      font-size: 13px;
      line-height: 18px;

      margin-top: 10px;
    }
  }

  .slick {
    &-track {
      padding: 70px 0 125px 0;

      @include s {
        padding-top: 0;
        padding-bottom: 19px;
      }

      @include md {
        padding-top: 50px;
        padding-bottom: 100px;
      }

      @include l {
        padding-top: 50px;
        padding-bottom: 100px;
      }
    }

    &-list {
     padding: 0 !important;
    }

    &-slide {
      height: 800px;

      display: flex;
      justify-content: center;
      align-items: center;

      @include s {
        height: 400px;
      }

      @include tablet {
        height: 500px;
      }

      @include smallD {
        height: 500px;
      }

      @include mdl {
        height: 660px;
      }

      &:hover {
        .nft {
          &__slide {
            width: 570px;
            height: 806px;

            position: relative;

            @include s {
              width: 240px;
              height: 336px;
            }

            @include tablet {
              width: 340px;
              height: 520px;
            }

            @include smallD {
              width: 340px;
              height: 520px;
            }

            @include md {
              width: 390px;
              height: 630px;
            }

            @include l {
              width: 420px;
              height: 660px;
            }

            &:before {
              content: '';

              position: absolute;

              top: 20%;
              left: 50%;

              width: 0;
              height: 0;

              background-color: transparent;
              box-shadow: 0 80px 120px 180px rgba(77, 203, 255, 0.8);

              @include s {
                box-shadow: none;
              }
            }
          }

          &__slide-icon-wrapper {
            width: 135%;
            height: 480px;

            margin-top: 16%;
            margin-bottom: 19px;

            transition: width 0.25s linear 0.5s, height 0.25s linear 0.5s, margin 0.25s linear 0.5s;

            @include s {
              margin-top: 12%;
              margin-bottom: 14px;

              height: 200px;
              min-height: 200px;
            }

            @include tablet {
              margin-top: 14%;

              width: 117%;
              height: 233px;
            }

            @include smallD {
              margin-top: 14%;

              width: 117%;
              height: 233px;
            }

            @include md {
              margin-top: 14%;

              width: 117%;
              height: 400px;
            }

            @include l {
              margin-top: 14%;

              width: 117%;
              height: 400px;
            }
          }

          &__slide-icon {
            background-size: cover;

            filter: drop-shadow(0px 10px 20px rgba(4, 16, 86, 0.75));

            @include md {
              background-size: 115%;
            }

            @include l {
              background-size: 115%;
            }
          }

          &__slide-svg {
            path, rect {
              fill: #536ee8;
            }

            filter: drop-shadow(0px 30px 40px rgba(10, 28, 131, 0.59));

            @include s {
              filter: drop-shadow(0px 15px 20px rgba(10, 28, 131, 0.59));
            }
          }

          &__slide-title {
            font-size: 48px;
            color: white;

            transition: font-size 0.25s linear 0.5s;

            @include s {
              font-size: 24px;
            }

            @include tablet {
              font-size: 28px;
            }

            @include smallD {
              font-size: 28px;
            }

            @include md {
              font-size: 36px;
            }

            @include l {
              font-size: 36px;
            }
          }

          &__slide-subtitle {
            font-size: 20px;
            line-height: 30px;
            color: #d2f1ff;

            margin-top: 14px;

            transition: font-size 0.25s linear 0.5s;

            @include s {
              margin-top: 7px;

              font-size: 10px;
              line-height: 14px;
            }

            @include tablet {
              font-size: 20px;
              line-height: 24px;
            }

            @include smallD {
              font-size: 20px;
              line-height: 24px;
            }

            @include md {
              font-size: 18px;
              line-height: 24px;
            }

            @include l {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  &__pagination-block {
    margin-top: -66px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;

    @include s {
      margin-top: 0;
      padding: 0 8px;

      top: -6px;
    }
  }

  &__page-btn {
    width: 120px;
    height: 120px;

    border-radius: 50%;

    background: #e9edf4;

    cursor: pointer;

    position: relative;

    z-index: 2;

    box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.91),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 15px;

    @include s {
      width: 45px;
      height: 45px;

      padding: 5px;
    }

    @include tablet {
      width: 80px;
      height: 80px;
    }

    @include smallD {
      width: 100px;
      height: 100px;
    }

    @include md {
      width: 100px;
      height: 100px;
    }

    @include l {
      width: 100px;
      height: 100px;
    }

    &:before {
      content: '';

      width: 100%;
      height: 100%;

      border-radius: 50%;
    }

    &:after {
      content: '';

      position: absolute;
      top: 30%;
      left: 30%;

      width: 38%;
      height: 38%;

      display: block;
    }

    &_prev {
      &:before {
        background-image: linear-gradient( 90deg, rgb(165,210,252) 0%, rgb(250,251,252) 100%);
        box-shadow: 0px 9px 9px 0px rgba(46, 107, 169, 0.5),inset 0px 7px 7px 0px rgba(217, 248, 255, 0.01),inset 0px -4px 4px 0px rgba(151, 237, 255, 0.47),inset 0px -7px 5px 0px rgba(64, 102, 180, 0.26);
      }

      &:after {
        background: url("../../assets/images/arrowPrev.png") center no-repeat;
        background-size: contain;

        filter: drop-shadow(0px 3px 3px rgba(255, 255, 255, 0.91));
      }
    }

    &_next {
      &:before {
        background-image: linear-gradient( 90deg, rgb(235,95,62) 0%, rgb(253,198,81) 100%);
        box-shadow: 0px 24px 36px 0px rgba(203, 51, 7, 0.91),inset 0px 7px 7px 0px rgba(255, 238, 217, 0.16),inset 0px -4px 4px 0px rgba(255, 237, 151, 0.47),inset 0px -7px 5px 0px rgba(180, 64, 64, 0.26);
      }

      &:after {
        background: url("../../assets/images/arrowNext.png") center no-repeat;
        background-size: contain;

        filter: drop-shadow(0px 6px 6px rgba(108, 37, 5, 0.5));
      }
    }
  }

  &__page-line {
    width: calc(100% - 232px);
    height: 17px;

    background: #e9edf4;

    position: absolute;

    top: calc(50% - 9px);
    left: 116px;

    //box-shadow: 0px 3px 3px 0px rgba(8, 24, 113, 0.2);
    box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.91),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);

    @include s {
      height: 7px;
      top: calc(50% - 4px);
      width: calc(100% - 100px);
      left: 50px;
    }

    @include md {
      width: calc(100% - 195px);
      left: 97px;
    }

    @include l {
      width: calc(100% - 195px);
      left: 97px;
    }
  }

  &__pages-wrapper {
    position: absolute;
    width: 100%;
    height: 61px;
    //background: rgba(255, 0, 255, 0.5);

    left: 0;
    top: -22px;

    display: flex;
    justify-content: center;
    align-items: center;

    @include s {
      height: 25px;
      top: -9px;
    }
  }

  &__page-circle {
    width: 60px;
    height: 60px;

    border-radius: 50%;

    overflow: hidden;

    background: #e9edf4;

    cursor: pointer;

    margin: 0 5px;

    box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.91),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    @include s {
      width: 22px;
      height: 22px;

      margin: 0 2px;
    }

    &:before {
      content: '';

      width: 30px;
      height: 30px;

      border-radius: 50%;

      background: #d7ddf0;

      box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.91),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);

      @include s {
        width: 12px;
        height: 12px;
      }
    }

    &:after {
      content: '';

      width: 30px;
      height: 30px;

      border-radius: 50%;

      opacity: 0;

      transition: opacity 0.25s ease-in-out;

      position: absolute;
      top: calc(50% - 15px);
      left: calc(50% - 15px);

      background-image: linear-gradient( 90deg, rgb(235,95,62) 0%, rgb(253,198,81) 100%);
      box-shadow: 0px 7px 9px 0px rgba(203, 51, 7, 0.91),inset 0px 5px 5px 0px rgba(255, 238, 217, 0.24),inset 0px -3px 3px 0px rgba(255, 237, 151, 0.47),inset 0px -5px 5px 0px rgba(180, 64, 64, 0.26);

      @include s {
        width: 12px;
        height: 12px;
        top: calc(50% - 6px);
        left: calc(50% - 6px);
      }
    }

    &_active {
      &:after {
        opacity: 1;
      }
    }
  }
}

.icon-bank {
  background-image: url('../../assets/images/nfts/Bank.webp');
}

.icon-post-office {
  background-image: url('../../assets/images/nfts/postOffice.webp');

}

.icon-insurance-tower {
  background-image: url('../../assets/images/nfts/insuranceTower.webp');

}

.icon-airplane {
  background-image: url('../../assets/images/nfts/Airplane.webp');

}

.icon-airport {
  background-image: url('../../assets/images/nfts/Airport.webp');

}

.icon-arena {
  background-image: url('../../assets/images/nfts/Arena.webp');

}

.icon-farm-phase-1 {
  background-image: url('../../assets/images/nfts/farmOne.webp');

}

.icon-farm-phase-2 {
  background-image: url('../../assets/images/nfts/farmTwo.webp');

}

.icon-farm-phase-3 {
  background-image: url('../../assets/images/nfts/farmThree.webp');

}

.icon-community-mine {
  background-image: url('../../assets/images/nfts/Mine.webp');

}

.icon-pool-phase-1 {
  background-image: url('../../assets/images/nfts/poolOne.webp');

}

.icon-pool-phase-2 {
  background-image: url('../../assets/images/nfts/poolTwo.webp');

}

.icon-pool-phase-3 {
  background-image: url('../../assets/images/nfts/poolThree.webp');

}

.icon-datacenter {
  background-image: url('../../assets/images/nfts/Datacenter.webp');

}

.icon-factory {
  background-image: url('../../assets/images/nfts/Factory.webp');

}

.icon-gallery {
  background-image: url('../../assets/images/nfts/Gallery.webp');

}

.icon-square {
  background-image: url('../../assets/images/nfts/HODLSquare.webp');

}

.icon-news-station {
  background-image: url('../../assets/images/nfts/newsStation.webp');

}

.icon-physic-park {
  background-image: url('../../assets/images/nfts/psychicPark.webp');

}

.icon-public-storage {
  background-image: url('../../assets/images/nfts/publicStorage.webp');

}

.icon-supermarket {
  background-image: url('../../assets/images/nfts/Supermarket.webp');

}

.icon-swap-meet {
  background-image: url('../../assets/images/nfts/swapMeet.webp');

}

.icon-townhall {
  background-image: url('../../assets/images/nfts/Townhall.webp');

}

.icon-home-phase-1 {
  background-image: url('../../assets/images/nfts/userHomeOne.webp');

}

.icon-home-phase-2 {
  background-image: url('../../assets/images/nfts/userHomeTwo.webp');

}

.icon-home-phase-3 {
  background-image: url('../../assets/images/nfts/userHomeThree.webp');

}