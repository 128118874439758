.footer {
  background: white;

  padding: 65px 0 70px 0;

  position: relative;

  @include s {
    padding-top: 31px;
    padding-bottom: 25px;
  }

  @include md {
    padding: 60px 0;
  }

  &__screw {
    position: absolute;
    left: 0;
    width: 100%;
    height: 30px;

    z-index: 3;

    bottom: -29px;

    @include s {
      height: 4px;
      bottom: 6px;
    }
  }

  &__logo {
    background: url('../../assets/images/HodlVerse.png') center no-repeat;
    background-size: contain;

    width: 140px;
    height: 155px;

    filter: drop-shadow(0px 43px 48px rgba(255, 255, 255, 0.52));

    position: relative;
    left: -9px;

    @include s {
      left: 0;

      width: 77px;
      height: 77px;

      margin: 0 auto;
    }

    @include md {
      width: 124px;
      height: 140px;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;

    @include s {
      flex-direction: column;
      justify-content: flex-start;
    }

    @include tablet {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__description {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #4557ba;
    letter-spacing: -0.2px;

    margin-top: -8px;

    @include s {
      font-size: 9px;
      line-height: 14px;
      text-align: center;

      margin: 23px auto 0 auto;
      max-width: 79%;
    }

    @include tablet {
      font-size: 16px;
      line-height: 24px;

      max-width: calc(100% - 200px);
    }

    @include md {
      font-size: 16px;
    }

    &_main {
      @include sm {
        display: block !important;

        font-size: 14px;
        line-height: 16px;

        margin: 5px auto 15px auto;

        max-width: 100%;
      }
    }
  }

  &__info-block {
    width: 25%;

    @include s {
      width: 100%;
    }

    @include tablet {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__columns-container {
    width: 75%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    padding-top: 42px;

    @include s {
      width: 100%;

      padding: 8px 10px 0 10px;
    }

    @include tablet {
      width: 100%;

      padding: 20px 30px 20px 100px;
    }

    @include smallD {
      width: 65%;
    }

    @include md {
      width: 65%;
    }

    @include l {
      width: 65%;
    }
  }

  &__column {
    &:first-child {
      width: 201px;

      @include s {
        width: 30%;
      }
    }

    &:nth-child(2) {
      width: 260px;

      @include s {
        width: 40%;
      }
    }

    &:last-child {
      width: 180px;

      @include s {
        width: 30%;
      }
    }

    &:not(:last-child) {
      margin-right: 100px;

      @include s {
        margin-right: auto;
      }

      @include tablet {
        margin-right: auto;
      }
    }

    @include s {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__link {
    font-family: "Gotham Rounded";
    font-weight: bold;
    color: #2c42bc;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.3px;

    cursor: pointer;

    position: relative;

    transition: color 0.25s ease-in-out;

    display: block;

    @include s {
      font-size: 9px;
    }

    @include sm {
      font-size: 12px;
    }

    @include tablet {
      font-size: 14px;
    }

    @include md {
      font-size: 16px;
    }

    &:before {
      content: '';

      width: 9px;
      height: 9px;

      border-radius: 50%;

      overflow: hidden;

      position: absolute;

      top: 5px;
      left: -20px;

      opacity: 0;

      background-image: linear-gradient(90deg, rgb(235,95,62) 0%, rgb(253,198,81) 100%);

      box-shadow: inset 0px 4px 4px 0px rgba(255, 238, 217, 0.32),inset 0px -3px 3px 0px rgba(255, 237, 151, 0.69),inset 0px -6px 6px 0px rgba(180, 64, 64, 0.26);

      @include s {
        width: 4px;
        height: 4px;

        top: 3px;
        left: -8px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 31px;

      @include s {
        margin-bottom: 16px;
      }

      @include tablet {
        margin-bottom: 22px;
      }
    }

    &:hover {
      color: #ff5e29;
    }

    &_active {
      color: #ff5e29;

      &:before {
        opacity: 1;
      }
    }
  }
}