.page-503 {
  padding-top: 86px;

  background: #3c53d6;

  min-height: 555px;

  position: relative;

  @include tablet {
    min-height: 500px;
  }

  &__top-wrapper {
    padding-bottom: 0;
  }

  &__screw {
    position: absolute;

    left: 0;
    top: calc(100% - 1px);

    width: 100%;
    height: 85px;

    z-index: 3;

    &_under {
      height: 85px;
      top: calc(100% + 8px);

      z-index: 2;
    }
  }

  &__container {
    position: relative;
    z-index: 5;

    max-width: 660px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #FFFFFF;

    margin-top: 65px;

    @include s {
      font-size: 20px;
      line-height: 30px;
    }

    @include sm {
      font-size: 20px;
      line-height: 30px;
    }

    @include tablet {
      font-size: 22px;
      line-height: 30px;
      margin-top: 50px;
    }
  }

  &__manage-block {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: -98px;
    left: 0;

    width: 100%;

    z-index: 20;
  }

  &__manage-btn {
    height: 70px;

    margin: 0 30px;
    padding: 0 56px;

    border-radius: 36px;
  }

  &__icon {
    width: 398px;
    height: 284px;

    display: block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @include s {
      width: 250px;
      height: 180px;
    }

    @include sm {
      width: 250px;
      height: 180px;
    }

    @include tablet {
      width: 250px;
      height: 180px;
    }

    @include smallD {
      width: 320px;
      height: 230px;
    }
  }

  &__footer-empty {
    width: 100%;
    height: 150px;
  }
}

.icon-network {
  background-image: url("../../assets/images/network.svg");
}