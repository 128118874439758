.farm {
  background: #3c53d6;

  position: relative;

  padding-top: 114px;
  padding-bottom: 130px;

  @include s {
    padding-top: 40px;
    padding-bottom: 46px;
  }

  @include tablet {
    padding-top: 90px;
    padding-bottom: 100px;
  }

  @include md {
    padding-top: 90px;
    padding-bottom: 100px;
  }

  &__screw {
    position: absolute;
    left: 0;
    width: 100%;
    height: 30px;

    z-index: 3;
    top: -29px;

    @include s {
      height: 4px;
      top: -13px;
    }
  }

  &__title {
    line-height: 1.182;
    text-align: center;

    @include s {
      margin: 0 auto;
      max-width: 90%;
    }
  }

  &__subtitle {
    text-align: center;

    margin-top: 36px;

    @include s {
      margin: 19px auto 0 auto;
      max-width: 90%;
    }
  }

  &__info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 36px;

    @include s {
      flex-direction: column;
      justify-content: flex-start;

      margin-top: 16px;
    }

    @include tablet {
      flex-direction: column;
      justify-content: flex-start;

      margin-top: 16px;
    }
  }

  &__icon-block-wrapper {
    width: 60%;
    height: 600px;

    position: relative;

    left: -30px;

    @include s {
      width: 310px;
      height: 225px;

      left: -6px;
    }

    @include tablet {
      width: 500px;
      height: 380px;

      left: 0;
    }

    @include smallD {
      left: 20px;

      height: 440px;
    }

    @include md {
      left: 20px;
    }

    @include l {
      left: -10px;

      height: 510px;
    }

    &:before {
      content: '';

      background: url("../../assets/images/farmShape.png") center no-repeat;
      background-size: contain;

      width: 101%;
      height: 100%;

      position: absolute;
      top: 13.9%;
      left: -0.2%;

      @include s {
        width: 104%;

        top: 13.9%;
        left: -0.2%;
      }

      @include tablet {
        width: 104%;

        top: 11.9%;
        left: -3.2%;
      }

      @include smallD {
        width: 96%;

        top: 10.9%;
        left: -4.2%;
      }

      @include md {
        width: 96%;

        top: 8.9%;
        left: -3.2%;
      }

      @include l {
        width: 96%;

        top: 11.9%;
        left: -3.2%;
      }
    }

    &:after {
      content: '';

      width: 50%;
      height: 0;

      position: absolute;
      top: 25%;
      left: 25%;

      background-color: transparent;
      box-shadow: 0 40px 220px 180px rgba(77, 203, 255, 0.55);
    }
  }

  &__icon-wrapper {
    position: absolute;
    top: -15px;
    left: -52px;

    width: calc(100% + 130px);
    height: calc(100% + 15px);

    opacity: 0;

    transition: opacity 0.25s ease-in-out;

    z-index: 2;

    @include s {
      width: calc(100% + 160px);
      height: calc(100% + 4px);

      top: -4px;
      left: -70px;
    }

    @include tablet {
      width: calc(100% + 100px);
      height: calc(100% + 4px);

      top: 0;
      left: 0;
    }

    @include smallD {
      width: calc(100% + 40px);
      height: 100%;
    }

    @include md {
      width: calc(100% + 40px);
      height: 100%;
    }

    @include l {
      width: calc(100% + 40px);
      height: 100%;
    }

    &_show {
      opacity: 1;
    }

    &_1 {
      left: -53px;
      top: 7px;

      @include s {
        left: -70px;
        top: 4px;
      }

      @include tablet {
        left: -50px;
        top: 0;
      }
    }

    &_2 {
      left: -46px;
      top: 9px;

      @include s {
        left: -68px;
        top: 4px;
      }

      @include tablet {
        left: -46px;
        top: 1px;
      }
    }

    &_3 {
      @include tablet {
        left: -50px;
        top: -14px;
      }

      @include smallD {
        top: -6px;
      }

      @include mdl {
        top: -9px;
      }
    }
  }

  &__icon {
    display: block;

    width: 100%;
    height: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__switcher-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 40%;

    padding-left: 36px;
    margin-top: -3px;

    @include s {
      width: 100%;

      padding-left: 0;
      margin-top: 14px;
    }

    @include tablet {
      width: 100%;

      padding-left: 0;
      margin-top: 14px;
    }

    @include smallD {
      padding-left: 10px;
    }

    @include md {
      margin-top: 40px;

      padding-left: 5px;
    }

    @include l {
      margin-top: 40px;
    }
  }

  &__farm-title {
    font-family: "Poppins";
    font-size: 48px;
    line-height: 0.75;
    font-weight: 600;
    color: #fefeff;
    text-align: center;
    letter-spacing: -1.5px;

    @include s {
      font-size: 21px;
      letter-spacing: -1px;
    }

    @include tablet {
      font-size: 34px;
      line-height: 42px;
    }

    @include smallD {
      font-size: 34px;
      line-height: 42px;
    }
  }

  &__farm-description {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
    color: #d2f1ff;
    text-align: center;

    margin-top: 39px;
    max-width: 80%;

    @include s {
      font-size: 10px;

      margin: 16px auto 0 auto;

      max-width: 60%;
    }

    @include tablet {
      font-size: 16px;
      line-height: 24px;

      margin-top: 15px;
    }

    @include smallD {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__switcher-wrapper {
    background-color: rgb(44, 65, 187);
    box-shadow: 0px 2px 2px 0px rgba(100, 161, 245, 0.42),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);

    display: flex;
    align-items: center;

    width: fit-content;
    height: 90px;

    border-radius: 45px;

    padding: 5px;

    margin-top: 52px;

    position: relative;
    left: -1px;

    @include s {
      margin-top: 16px;
      height: 45px;

      padding: 3px;
    }

    @include tablet {
      height: 60px;

      margin-top: 30px;
    }

    @include smallD {
      height: 60px;

      margin-top: 30px;
    }

    @include md {
      height: 70px;
    }

    @include l {
      height: 80px;
    }
  }

  &__switcher-item {
    font-family: "Gotham Rounded";
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: #cfe4ff;
    text-transform: uppercase;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    height: 100%;

    padding: 0 44px;

    margin: 0 13px;

    @include s {
      padding: 0 22px;
      margin: 0 8px;

      font-size: 12px;
    }

    @include tablet {
      font-size: 18px;

      padding: 0 20px;
    }

    @include smallD {
      font-size: 18px;

      padding: 0 20px;
    }

    @include md {
      font-size: 20px;

      padding: 0 28px;
    }

    @include l {
      font-size: 22px;

      padding: 0 36px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &_active {
      background-image: linear-gradient(0, rgb(235,95,62) 0%, rgb(253,198,81) 100%);
      box-shadow: 0px 14px 21px 0px rgba(3, 20, 125, 0.79),inset 0px 7px 7px 0px rgba(255, 238, 217, 0.16),inset 0px -4px 4px 0px rgba(255, 237, 151, 0.47),inset 0px -7px 5px 0px rgba(180, 64, 64, 0.26);

      border-radius: 45px;

      color: white;
    }
  }
}