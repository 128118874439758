.header {
  padding-top: 26px;

  position: relative;
  z-index: 50;

  @include s {
    padding-top: 8px;
  }

  @include smallD {
    padding-top: 10px;
  }

  @include tablet {
    padding-top: 10px;
  }

  * {
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__logo-wrapper {
    width: 311px;
    height: 180px;

    margin-left: -36px;

    @include s {
      width: 104px;
      min-width: 104px;
      height: 66px;
      margin-left: -4px;
    }

    @include xs {
      width: 70px;
      min-width: 70px;
      height: 55px;
    }

    @include sm {
      width: 104px;
      min-width: 104px;
      height: 66px;
      margin-left: -4px;
    }

    @include tablet {
      width: 110px;
      height: 70px;

      margin: 0;
    }

    @include smallD {
      width: 160px;
      height: 100px;

      margin: 0;
    }

    @include md {
      width: 170px;
      height: 110px;

      margin-left: 20px;
      margin-top: 25px;
    }

    @include l {
      width: 205px;
      min-width: 205px;
      height: 130px;

      margin: 0;

      position: relative;
      top: 12px;
      left: -17px;
    }
  }

  &__logo {
    background: url('../../assets/images/HodlVerse.png') center no-repeat;
    background-size: contain;

    width: 100%;
    height: 100%;

    filter: drop-shadow(0px 43px 48px rgba(255, 255, 255, 0.52));
  }

  &__menu-wrapper {
    @include s {
      display: none;
    }

    @include sm {
      display: none;
    }

    @include tablet {
      margin-left: 10px;
    }

    @include smallD {
      margin-left: 10px;
    }
  }

  &__content-wrapper {
    padding-top: 48px;

    display: flex;
    align-items: center;

    @include s {
      padding-top: 7px;
      padding-right: 4px;
    }

    @include sm {
      padding-top: 12px;
    }

    @include tablet {
      padding-top: 12px;
    }

    @include smallD {
      padding-top: 16px;
    }

    @include md {
      padding-top: 46px;
    }

    @include l {
      padding-top: 46px;
    }
  }

  &__manage-wrapper {
    margin-left: 67px;

    display: flex;
    align-items: center;

    @include s {
      margin-left: 0;
    }

    @include sm {
      margin-left: 0;
    }

    @include tablet {
      margin-left: 10px;
    }

    @include smallD {
      margin-left: 30px;
      margin-right: 30px;
    }

    @include md {
      margin-left: 30px;
    }

    @include l {
      margin-left: 60px;
    }
  }

  &__manage-btn {
    display: none;

    @include s {
      width: 90px;
    }

    @include sm {
      white-space: nowrap;
    }

    .button {
      padding: 0 27px;

      @include s {
        width: 100%;

        display: flex;
        justify-content: center;
      }

      @include sm {
        width: 100%;

        display: flex;
        justify-content: center;
      }

      @include tablet {
        padding: 0 12px;
      }

      @include md {
        height: 48px;
        font-size: 14px;
        padding: 0 25px;
        white-space: nowrap;
      }

      @include l {
        height: 48px;
        font-size: 14px;
        padding: 0 25px;
        white-space: nowrap;
      }
    }

    &_start {
      margin-right: 30px;

      @include s {
        margin-right: 8px;
      }

      @include sm {
        margin-right: 8px;
      }

      @include tablet {
        margin-right: 10px;
      }

      @include smallD {
        margin-right: 10px;
      }

      @include md {
        margin-right: 20px;
      }

      @include l {
        margin-right: 20px;
      }

      .button {
        padding: 0 27px;

        @include s {
          padding: 0 10px;
        }

        @include sm {
          padding: 0 10px;
        }

        @include tablet {
          padding: 0 12px;
        }

        @include md {
          padding: 0 22px;
        }

        @include l {
          padding: 0 22px;
        }
      }
    }

    &_money {
      @include s {
        margin-right: 6px;
      }

      @include sm {
        margin-right: 8px;
      }

      .button {
        @include s {
          padding: 0 2px;
        }
      }
    }
  }

  &__hamburger-wrapper {
    display: none;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    border-radius: 50%;

    cursor: pointer;

    background-image: linear-gradient(0deg, rgb(23,41,149) 0%, rgb(23,41,149) 100%);

    &:before {
      content: '';

      width: calc(100% - 6px);
      height: calc(100% - 6px);

      border-radius: 50%;

      position: absolute;
      top: 3px;
      left: 3px;

      background-image: linear-gradient(0deg, rgb(165,210,252) 0%, rgb(250,251,252) 100%);
      box-shadow: 0px 3px 3px 0px rgba(28, 46, 147, 0.5),inset 0px 4px 4px 0px rgba(217, 248, 255, 0.16),inset 0px -2px 2px 0px rgba(151, 237, 255, 0.47),inset 0px -4px 3px 0px rgba(64, 102, 180, 0.26);
    }

    @include s {
      display: flex;
    }

    @include sm {
      display: flex;
    }
  }

  &__hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 12px;

    &-line {
      width: 12px;
      height: 3px;

      border-radius: 2px;

      border: none;

      background-image: linear-gradient(0deg, rgb(253,198,81) 0%, rgb(235,95,62) 100%);
      box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.91),inset 0px 2px 2px 0px rgba(107, 25, 0, 0.35);

      &:not(:last-child) {
        margin-bottom: 1px;
      }
    }

    * {
      -webkit-backface-visibility: hidden;
      -webkit-transform-style: preserve-3d;
    }
  }

  &__dropdown {
    position: relative;
    cursor: pointer;

    &-menu {
      position: absolute;
      top: calc(100% + 5px);
      right: 4px;

      width: 150px;
      height: auto;

      background-image: linear-gradient(0deg, rgb(165,210,252) 0%, rgb(250,251,252) 100%);
      box-shadow: 0px 40px 50px 0px rgba(1, 21, 135, 0.67),inset 0px 7px 7px 0px rgba(217, 248, 255, 0.01),inset 0px -4px 4px 0px rgba(151, 237, 255, 0.47),inset 0px -7px 5px 0px rgba(64, 102, 180, 0.2);

      opacity: 0;
      max-height: 0;
      overflow: hidden;

      border-radius: 12px;

      transition: opacity 0.25s ease-in-out, max-height 0.25s ease-in-out;
    }

    &-menu-item {
      font-family: "Gotham Rounded";
      font-weight: bold;
      font-size: 15px;
      color: #3552ca;

      padding: 15px 14px 12px 14px;

      cursor: pointer;

      text-transform: uppercase;

      display: block;

      &:not(:last-child) {
        border-bottom: 1px solid #e0e6f7;
      }

      &_active {
        background-image: linear-gradient(0deg, rgb(235,95,62) 0%, rgb(253,198,81) 100%);
        color: white;
      }
    }

    &_active {
      &:before {
        background-image: linear-gradient(0deg, rgb(235,95,62) 0%, rgb(253,198,81) 100%);
        box-shadow: 0px 8px 11px 0px rgba(27, 47, 165, 0.91),inset 0px 7px 7px 0px rgba(255, 238, 217, 0.16),inset 0px -4px 4px 0px rgba(255, 237, 151, 0.47),inset 0px -7px 5px 0px rgba(180, 64, 64, 0.26);
      }

      .header__hamburger-line {
        //background-image: linear-gradient(0deg, rgb(253,198,81) 0%, rgb(235,95,62) 100%);
        background: white;
        box-shadow: none;
      }

      .header__dropdown {
        &-menu {
          opacity: 1;
          max-height: 400px;
          overflow-y: scroll;
        }
      }
    }
  }
}