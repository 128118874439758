.nav-menu {
  //border: 3px solid #2c41bb;
  border-radius: 40px;
  background: linear-gradient(90deg, rgba(23, 41, 149, 0.52) 0%, rgba(23, 41, 149, 0.52) 100%);
  box-shadow: inset 0 0 0 3px #2c41bb;
  //box-shadow: inset 0 0 3px 3px white;

  height: 80px;

  @include xs {

  }

  @include s {

  }

  @include tablet {
    height: 50px;
  }

  @include smallD {
    height: 54px;
  }

  @include md {
    height: 54px;
  }

  @include l {
    height: 54px;
  }

  overflow: hidden;

  &:hover {
    .nav-menu__item_active {
      background: transparent;
      color: white;
    }
  }

  &__container {
    display: flex;
    align-items: center;

    height: 100%;

    background: #2b40b9;
  }

  &__item {
    padding: 25px 40px;

    cursor: pointer;

    background-color: transparent;

    transition: background-color, color 0.25s ease-in-out;

    font-family: "Gotham Rounded";
    font-size: 18px;
    font-weight: bold;
    color: white;
    line-height: 1.667;
    text-transform: uppercase;
    text-align: center;

    border-radius: 40px;

    @include tablet {
      font-size: 14px;
      padding: 6px 16px;
    }

    @include smallD {
      font-size: 16px;
      padding: 20px;
    }

    @include md {
      padding: 12px 27px;
      font-size: 13px;
    }

    @include l {
      padding: 12px 27px;
      font-size: 13px;
    }

    &_active {
      background-color: white;
      color: #2c42bc;

      &:first-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &:last-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }

    &:hover {
      background-color: white !important;
      color: #2c42bc !important;

      &:first-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &:last-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }
}