.benefit {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: 7px;

  @include s {
    padding-left: 0;
  }

  @include tablet {
    width: 95%;
    margin: 0 auto;
  }

  @include l {
    width: 95%;
    margin: 0 auto;
  }

  &__icon-wrapper {
    width: 150px;
    height: 150px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgb(83, 106, 231);
    box-shadow: 0 30px 40px 0 rgba(10, 28, 131, 0.59),inset 0 3px 3px 0 rgba(167, 210, 255, 0.25),inset 0px -5px 7px 0px rgba(0, 27, 186, 0.28);

    border-radius: 40px;

    padding-top: 14px;

    position: relative;

    &:before {
      content: '';

      position: absolute;

      top: 20%;
      left: 50%;

      width: 0;
      height: 0;

      background-color: transparent;
      box-shadow: 0 0 60px 60px rgba(77, 203, 255, 0.35);
    }

    @include s {
      width: 75px;
      height: 75px;

      border-radius: 20px;
    }

    @include tablet {
      width: 100px;
      height: 100px;

      border-radius: 18px;
    }

    @include smallD {
      width: 115px;
      height: 115px;
    }

    @include md {
      width: 130px;
      height: 130px;
    }

    @include md {
      width: 140px;
      height: 140px;
    }
  }

  &__icon {
    display: block;

    width: 78%;
    height: 100%;

    position: relative;
    z-index: 5;

    &.icon__noncustodial {
      width: 96%;

      position: relative;
      left: 10px;
    }

    &.icon__gamification {
      background-size: 90%;
    }

    &.icon__swap {
      background-size: 72%;
    }

    &.icon__anonymous {
      position: relative;
      top: -7px;
    }

    &.icon__sharing {
      background-size: 110%;
    }

    &.icon__buyback {
      background-size: 92%;
    }

    &.icon__governance {
      background-size: 96%;
      position: relative;
      left: -3px;
      top: -3px;
    }

    &.icon__achievements {
      background-size: 104%;
      width: 90%;
    }

    &.icon__airdrops {
      background-size: 88%;
    }

    &.icon__blockchain-bridges {
      background-size: 100%;
    }
  }

  &__title {
    font-family: Poppins;
    font-weight: 600;
    font-size: 30px;
    color: white;
    text-align: center;

    margin-top: 39px;

    @include s {
      font-size: 15px;

      margin-top: 10px;
    }

    @include tablet {
      font-size: 22px;

      margin-top: 22px;
    }

    @include smallD {
      font-size: 22px;

      margin-top: 20px;
    }

    @include md {
      font-size: 26px;

      margin-top: 26px;
    }

    @include l {
      font-size: 28px;

      margin-top: 28px;
    }
  }

  &__subtitle {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 18px;
    color: #cfe4ff;
    line-height: 26px;
    text-align: center;

    max-width: 320px;

    margin-top: 14px;

    @include s {
      font-size: 9px;
      line-height: 13px;

      margin: 3px auto 0 auto;

      max-width: 150px;
    }

    @include tablet {
      font-size: 16px;

      line-height: 24px;

      margin-top: 10px;
    }

    @include smallD {
      font-size: 16px;

      line-height: 24px;

      margin-top: 10px;
    }

    @include md {
      font-size: 16px;

      line-height: 24px;

      margin-top: 10px;
    }

    @include md {
      font-size: 17px;

      line-height: 25px;

      margin-top: 10px;
    }
  }
}

.icon__crosschain {
  background: url("../../assets/images/crossChain.png") center no-repeat;
  background-size: contain;
}

.icon__noncustodial {
  background: url("../../assets/images/nonCustodial.png") center no-repeat;
  background-size: contain;
}

.icon__gamification {
  background: url("../../assets/images/gamification.png") center no-repeat;
  background-size: contain;
}

.icon__swap {
  background: url("../../assets/images/swap.png") center no-repeat;
  background-size: contain;
}

.icon__nft {
  background: url("../../assets/images/nft.png") center no-repeat;
  background-size: contain;
}

.icon__anonymous {
  background: url("../../assets/images/anonymous.png") center no-repeat;
  background-size: contain;
}

.icon__farming {
  background: url("../../assets/images/farming.png") center no-repeat;
  background-size: contain;
}

.icon__sharing {
  background: url("../../assets/images/sharing.png") center no-repeat;
  background-size: contain;
}

.icon__buyback {
  background: url("../../assets/images/buyback.png") center no-repeat;
  background-size: contain;
}

.icon__governance {
  background: url("../../assets/images/governance.png") center no-repeat;
  background-size: contain;
}

.icon__achievements {
  background: url("../../assets/images/achievements.png") center no-repeat;
  background-size: contain;
}

.icon__airdrops {
  background: url("../../assets/images/airdrops.png") center no-repeat;
  background-size: contain;
}

.icon__blockchain-bridges {
  background: url("../../assets/images/blockchainBridges.png") center no-repeat;
  background-size: contain;
}