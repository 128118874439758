.economy-head {
  &__slide-list {
    padding-bottom: 183px;

    width: 1580px;
    margin: 71px auto 0 auto;

    position: relative;

    @include s {
      width: 100%;
      margin-top: 3px;

      padding: 0 0 29px 0;
    }

    @include tablet {
      width: 100%;

      padding-bottom: 90px;
    }

    @include smallD {
      width: 100%;

      padding-bottom: 90px;
    }

    @include md {
      width: 100%;

      padding-bottom: 90px;
    }

    @include l {
      width: 1100px;

      padding-bottom: 90px;
    }

    .slick-list {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';

      width: 40%;
      height: 0;

      position: absolute;
      top: 25%;
      right: 10%;

      background-color: transparent;
      box-shadow: 0 120px 220px 180px rgba(77, 203, 255, 0.55);
    }
  }

  &__slide {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 0 0 80px;

    @include s {
      flex-direction: column;
      padding: 0 16px;
    }

    @include tablet {
      flex-direction: column;
      padding: 0 16px;
    }

    @include l {
      padding: 0;
    }
  }

  &__info-box {
    display: block;

    padding-top: 13px;

    max-width: 710px;

    @include s {
      width: 100%;
    }

    @include tablet {
      width: 100%;
    }

    @include smallD {
      max-width: 50%;
    }

    @include md {
      max-width: 46%;
    }

    @include l {
      max-width: 55%;
    }
  }

  &__preff-title {
    font-family: "Gotham Rounded";
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #d8feff;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    @include s {
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      letter-spacing: -0.1px;
    }

    @include tablet {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      letter-spacing: -0.1px;
    }

    @include smallD {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__title {
    font-family: Poppins;
    font-weight: 500;
    font-size: 108px;
    line-height: 124px;
    color: white;
    letter-spacing: -7.5px;

    margin-top: 26px;

    @include s {
      font-size: 27px;
      font-weight: 600;
      letter-spacing: -1px;
      text-align: center;
      margin-top: 11px;
      line-height: 30px;
    }

    @include tablet {
      font-size: 40px;
      line-height: 50px;
      text-align: center;
      letter-spacing: -1px;

      margin-top: 15px;
    }

    @include smallD {
      font-size: 80px;
      line-height: 92px;
    }

    @include md {
      font-size: 90px;
      line-height: 100px;
    }

    @include l {
      font-size: 90px;
      line-height: 106px;
    }
  }

  &__description {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #d8feff;

    margin-top: 100px;

    @include s {
      font-size: 13px;
      text-align: center;
      line-height: 20px;

      margin-top: 9px;
    }

    @include tablet {
      font-size: 16px;
      text-align: center;
      line-height: 22px;

      margin-top: 20px;
    }

    @include smallD {
      margin-top: 40px;

      font-size: 16px;
      line-height: 24px;
    }

    @include md {
      margin-top: 50px;

      font-size: 18px;
      line-height: 26px;
    }

    @include l {
      font-size: 18px;
      line-height: 26px;

      margin-top: 50px;
    }
  }

  &__icon-wrapper {
    width: 770px;
    height: 515px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    right: 52px;
    bottom: 29px;

    &:before {
      content: '';

      width: 100%;
      height: 100%;

      position: absolute;
      left: 0;
      bottom: -52px;

      background: url("../../assets/images/farmShape.png") center no-repeat;
      background-size: contain;

      @include s {
        bottom: -38px;
        left: -2px;
        background-size: 99%;
      }

      @include sm {
        bottom: -47px;
      }

      @include tablet {
        bottom: -70px;
        left: -2px;
        background-size: 99%;
      }

      @include smallD {
        bottom: -17px;
        left: -2px;
      }

      @include md {
        bottom: -30px;
      }

      @include l {
        left: -2px;
        bottom: -22px;
      }
    }

    @include s {
      width: 100%;
      height: 335px;
      left: -2px;
      right: auto;
      bottom: auto;
    }

    @include sm {
      max-width: 400px;
      left: auto;
      right: auto;

      margin: 0 auto;
    }

    @include tablet {
      width: 100%;
      max-width: 600px;
      height: 400px;
      left: auto;
      right: auto;
      bottom: auto;

      margin: 0 auto;
    }

    @include smallD {
      width: 410px;
      height: 340px;

      bottom: 0;
      right: 40px;
    }

    @include md {
      width: 540px;
      height: 410px;
    }

    @include l {
      width: 470px;
      height: 390px;

      right: 10px;
      bottom: 10px;
    }
  }

  &__icon {
    display: block;

    width: 100%;
    height: 100%;

    background-size: 111%;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
    z-index: 2;

    top: -30px;

    @include s {
      top: auto;
    }

    @include tablet {
      top: auto;
    }
  }

  &__pagination-block {
    .nft {
      &__page-btn, &__page-line, &__page-circle {
        background: #2d42b8;

        box-shadow: 0px 2px 2px 0px rgba(100, 161, 245, 0.42),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);
      }

      &__page-circle {
        &:before {
          background: #263aaa;
          box-shadow: 0px 2px 2px 0px rgba(100, 161, 245, 0.42),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);
        }
      }
    }

    @include s {
      top: -24px;
    }

    @include tablet {
      top: -24px;
    }
  }

  &__screw {
    width: 100%;
    height: 30px;

    position: absolute;

    left: 0;
    bottom: -29px;

    @include s {
      height: 4px;
      bottom: auto;
      top: calc(100% - 11px);
    }

    @include tablet {
      height: 4px;
      bottom: auto;
      top: calc(100% - 11px);
    }
  }
}