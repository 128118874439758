.youtube-popup {
  width: 900px;
  height: 600px;

  @include s {
    width: 90%;
    height: 410px;
  }

  @include tablet {
    width: 90%;
    height: 500px;
  }

  &__frame-wrapper {
    width: 100%;
    height: 100%;
  }

  .popup {
    &__form {
      padding: 40px 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      @include s {
        padding: 30px 10px;
      }

      #youtube-form-data {
        width: 100%;
        height: 100%;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}