.calculator {
  position: relative;
  padding: 157px 0 127px 0;

  background: #3e68dd;

  z-index: 6;

  @include s {
    padding-top: 45px;
    padding-bottom: 44px;
  }

  @include tablet {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  @include smallD {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  @include md {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  &:after {
    content: '';

    width: 100%;
    height: 0;

    position: absolute;
    top: 25%;
    left: 0;

    background-color: transparent;
    box-shadow: 0 0 220px 280px rgba(77, 203, 255, 0.15);

    z-index: -1;
  }

  &__screw {
    position: absolute;

    left: 0;
    bottom: -29px;

    width: 100%;
    height: 30px;

    z-index: 1;

    @include s {
      height: 4px;

      bottom: 7px;
    }
  }

  &__title {
    @include s {
      letter-spacing: 0;
    }
  }

  &__subtitle {
    margin: 36px auto 0 auto;

    max-width: 40%;

    @include s {
      max-width: 270px;

      margin-top: 16px;

      letter-spacing: 0.1px;
    }

    @include tablet {
      margin-top: 20px;

      max-width: 80%;
    }
  }

  &__main-form {
    width: 1240px;
    margin: 0 auto;

    position: relative;
    z-index: 2;

    @include s {
      width: 100%;
    }

    @include tablet {
      width: 680px;
    }

    @include smallD {
      width: 980px;
    }

    @include md {
      width: 1120px;
    }
  }

  &__digits-section {
    width: 100%;

    margin-top: 135px;
    padding: 0 20px;

    position: relative;
    z-index: 2;

    @include s {
      margin-top: 26px;

      padding: 0 17px;
    }

    @include tablet {
      margin-top: 60px;
      padding: 0;
    }

    @include smallD {
      margin-top: 60px;
      padding: 0;
    }

    @include mdl {
      margin-top: 60px;
    }
  }

  &__digit-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 38px;

      @include s {
        margin-bottom: 10px;
      }

      @include tablet {
        margin-bottom: 20px;
      }
    }
  }

  &__input {
    &-block {
      width: 49%;

      display: flex;
      align-items: center;

      @include s {
        width: 47.5%;
        justify-content: space-around;
      }

      &:last-child {
        justify-content: flex-end;
      }
    }

    &-title {
      font-family: Poppins;
      font-weight: 600;
      font-size: 30px;
      color: white;
      line-height: 42px;

      max-width: 210px;
      margin-right: 15px;
      padding-top: 7px;

      @include s {
        font-size: 8.6px;
        line-height: 12px;

        max-width: 60px;

        padding-top: 3px;

        margin-right: 9px;
      }

      @include tablet {
        font-size: 16px;
        line-height: 24px;

        max-width: 140px;

        padding-top: 3px;

        margin-right: 20px;
      }
    }

    &-value-form {
      width: 350px;
      height: 90px;

      border-radius: 24px;
      background-color: #2c41bb;
      box-shadow: 0px 2px 2px 0px rgba(100, 161, 245, 0.42),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);

      display: flex;
      align-items: center;

      padding: 10px 28px;

      @include s {
        min-width: 86px;
        width: 86px;
        height: 26px;

        border-radius: 8px;

        padding: 5px 8px;
      }

      @include tablet {
        min-width: 200px;
        width: 200px;
        height: 55px;

        border-radius: 8px;

        padding: 5px 8px;
      }
    }

    &-text {
      font-family: Poppins;
      font-weight: 600;
      font-size: 30px;
      color: white;

      @include s {
        font-size: 8.6px;
      }

      @include tablet {
        font-size: 22px;
      }

      &_preffix {
        color: #6293fc;
      }

      &_orange {
        color: #FF9A48;
      }
    }
  }

  &__dropdown {
    position: relative;

    background-image: linear-gradient(0deg, rgb(184,220,255) 0%, rgb(250,251,252) 100%);
    box-shadow: 0px 9px 9px 0px rgba(1, 21, 135, 0.5),inset 0px 7px 7px 0px rgba(217, 248, 255, 0.01),inset 0px -4px 4px 0px rgba(151, 237, 255, 0.47),inset 0px -7px 5px 0px rgba(64, 102, 180, 0.26);

    cursor: pointer;

    &-val {
      font-family: Poppins;
      font-weight: 600;
      font-size: 30px;
      color: #3552ca;

      @include s {
        font-size: 8.6px;
      }

      @include tablet {
        font-size: 22px;
      }
    }

    &-arrow-icon-wrapper {
      width: 42px;
      height: 42px;

      border-radius: 50%;

      background-image: linear-gradient( 0deg, rgb(235,95,62) 0%, rgb(253,198,81) 100%);
      box-shadow: 0px 7px 9px 0px rgba(203, 51, 7, 0.91),inset 0px 5px 5px 0px rgba(255, 238, 217, 0.24),inset 0px -3px 3px 0px rgba(255, 237, 151, 0.47),inset 0px -5px 5px 0px rgba(180, 64, 64, 0.26);

      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: calc(50% - 21px);
      right: 24px;

      cursor: pointer;

      transition: background 0.25s ease-in-out;

      @include s {
        width: 12px;
        height: 12px;

        right: 8px;
        top: calc(50% - 6px);
      }

      @include tablet {
        width: 34px;
        height: 34px;

        top: calc(50% - 18px);
      }
    }

    &-arrow-icon {
      width: 14px;
      height: 10px;

      transition: transform 0.25s ease-in-out;

      @include s {
        width: 6px;
        height: 3px;
      }
    }

    &-menu {
      position: absolute;
      top: 100%;
      left: 0;

      width: 100%;
      height: auto;

      background-image: linear-gradient(0deg, rgb(165,210,252) 0%, rgb(250,251,252) 100%);
      box-shadow: 0px 40px 50px 0px rgba(1, 21, 135, 0.67),inset 0px 7px 7px 0px rgba(217, 248, 255, 0.01),inset 0px -4px 4px 0px rgba(151, 237, 255, 0.47),inset 0px -7px 5px 0px rgba(64, 102, 180, 0.2);

      opacity: 0;
      max-height: 0;
      overflow: hidden;

      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;

      transition: opacity 0.25s ease-in-out, max-height 0.25s ease-in-out;

      @include s {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    &-menu-item {
      font-family: Poppins;
      font-weight: 600;
      font-size: 30px;
      color: #3552ca;

      padding: 22px 30px;

      cursor: pointer;

      @include s {
        font-size: 8.6px;

        padding: 7px 8px 6px 8px;
      }

      @include tablet {
        font-size: 22px;

        padding: 10px 20px;
      }

      &:not(:last-child) {
        border-bottom: 2px solid #d9e4f7;

        @include s {
          border-bottom-width: 1px;
        }
      }
    }

    &_active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      background-image: linear-gradient( 90deg, rgb(235,95,62) 0%, rgb(253,198,81) 100%);
      box-shadow: none;

      .calculator__dropdown {
        &-val {
          color: white;
        }

        &-arrow-icon-wrapper {
          background-image: linear-gradient( 90deg, rgb(250,251,252) 0%, rgb(165,210,252) 100%);
          box-shadow: 0px 6px 6px 0px rgba(103, 16, 5, 0.5),inset 0px 7px 7px 0px rgba(255, 255, 255, 0.69),inset 0px -4px 4px 0px rgba(151, 237, 255, 0.47),inset 0px -7px 5px 0px rgba(64, 102, 180, 0.26);
        }

        &-arrow-icon {
          transform: rotate(180deg);

          path {
            fill: #5ea8f3;
          }
        }

        &-menu {
          opacity: 1;
          max-height: 1600px;
          overflow-y: scroll;
        }
      }
    }
  }

  &__table {
    background: white;
    border-radius: 16px;

    margin-top: 15px;

    @include s {
      margin-top: 5px;

      border-radius: 4px;
    }

    &-form {
      margin-top: 151px;
      padding: 20px;

      border-radius: 30px;
      background-color: #2c41bb;
      box-shadow: 0px 2px 2px 0px rgba(100, 161, 245, 0.42),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);

      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @include s {
        margin: 24px auto 0 auto;

        padding: 6px;

        width: calc(100% - 23px);

        border-radius: 8px;
      }

      @include tablet {
        margin-top: 50px;
      }

      @include smallD {
        margin-top: 60px;
      }

      @include md {
        margin-top: 75px;
      }
    }

    &-main-column {
      width: 68.6%;
    }

    &-main-title-wrapper {
      background: linear-gradient(180deg, #FF9A48 0%, #F25625 100%);
      box-shadow: 0px 12px 24px 4px rgba(0, 0, 0, 0.3), inset 0px -3px 3px #F18749, inset 0px -7px 4px rgba(255, 46, 0, 0.6), inset 0px 4px 4px rgba(255, 213, 104, 0.6), inset 0px 0px 6px 3px rgba(184, 55, 0, 0.3);
      border-radius: 16px;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 64px;

      @include s {
        height: 17px;

        border-radius: 4px;
      }
    }

    &-main-title {
      font-family: Gotham Rounded;
      font-size: 23px;
      line-height: 28px;
      text-align: center;
      color: #FFFFFF;

      @include s {
        font-size: 6.2px;
        line-height: 10px;
      }

      @include tablet {
        font-size: 20px;
      }
    }

    &-preview-column {
      width: 30%;
      height: 738px;

      background: url("../../assets/images/calculatorTableIcon.webp") top left no-repeat;
      background-size: cover;

      border-radius: 16px;

      display: flex;
      flex-direction: column;
      justify-content: center;

      padding: 99px 30px 50px 30px;

      position: relative;

      &:before {
        content: '';

        position: absolute;

        top: 6%;
        left: 0;

        background: url('../../assets/images/HodlVerse.png') center no-repeat;
        background-size: contain;

        width: 100%;
        height: 160px;

        @include s {
          height: 35px;
        }
      }

      @include s {
        height: 200px;

        border-radius: 8px;

        padding: 70px 8px 58px 8px;
      }
    }

    &-preview-block {
      padding: 44px 0 29px 0;

      @include s {
        padding: 11px 0 8px 0;
      }

      &:not(:last-child) {
        border-bottom: 2px solid #DBE3EB;

        @include s {
          border-bottom-width: 1px;
        }
      }
    }

    &-preview-title {
      font-family: Gotham Rounded;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 26px;
      text-align: center;

      color: #000000;

      @include s {
        font-size: 6.1px;
        line-height: 8px;
      }
    }

    &-preview-value {
      font-family: Gotham Rounded;
      font-style: normal;
      font-weight: normal;
      font-size: 64px;
      line-height: 77px;
      /* identical to box height */

      text-align: center;

      color: #2D44BD;

      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

      margin-top: 13px;

      @include s {
        font-size: 17.6px;
        line-height: 18px;

        margin-top: 5px;
      }

      @include tablet {
        font-size: 50px;
      }
    }

    &-row {
      width: 100%;
      height: 165px;

      padding: 22px 0;

      display: flex;

      @include s {
        height: 44.5px;
        padding: 5px 0;
      }

      &:not(:last-child) {
        border-bottom: 2px solid #DBE3EB;

        @include s {
          border-bottom-width: 1px;
        }
      }
    }

    &-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      height: 100%;

      &:first-child {
        width: 39.4%;
      }

      &:nth-child(2) {
        width: 31.5%;
      }

      &:last-child {
        width: 29.1%;
      }

      &:not(:last-child) {
        border-right: 2px solid #DBE3EB;

        @include s {
          border-right-width: 1px;
        }
      }
    }

    &-icon {
      display: block;
      width: 69%;
      height: 100%;

      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      &.icon-pancake-dark {
        background-size: 96%;
      }

      &.icon-sushi {
        background-size: 81%;
      }

      &.icon-axie {
        background-size: 56%;

        position: relative;
        top: -8px;
      }
    }

    &-title {
      font-family: Gotham Rounded;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */

      text-align: center;

      color: #000000;

      @include s {
        font-size: 5px;
        line-height: 7px;
      }

      @include tablet {
        font-size: 16px;
      }
    }

    &-value {
      font-family: Gotham Rounded;
      font-style: normal;
      font-weight: normal;
      font-size: 48px;
      line-height: 58px;
      /* identical to box height */

      text-align: center;

      color: #2D44BD;

      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

      @include s {
        font-size: 13px;
        line-height: 16px;
      }

      @include tablet {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}

.icon-sushi {
  background-image: url("../../assets/images/sushi.png");
}

.icon-axie {
  background-image: url("../../assets/images/axie.png");
}