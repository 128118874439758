.ido {
  padding-top: 60px;

  background: #3c53d6;

  min-height: 520px;

  position: relative;

  @include s {
    padding-top: 45px;
  }

  @include tablet {
    min-height: 460px;
  }

  &__top-wrapper {
    padding-bottom: 0;
  }

  &__screw {
    position: absolute;

    left: 0;
    top: calc(100% - 1px);

    width: 100%;
    height: 85px;

    z-index: 3;

    &_under {
      height: 85px;
      top: calc(100% + 8px);

      z-index: 2;
    }
  }

  &__container {
    position: relative;
    z-index: 5;

    @include s {
      width: 93%;
      margin: 0 auto;
    }
  }

  &__title {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #FFFFFF;

    @include s {
      font-size: 18px;
      line-height: 24px;
    }

    @include sm {
      font-size: 18px;
      line-height: 24px;
    }

    @include tablet {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__link {
    color: #FF9100;

    cursor: pointer;

    &_white {
      color: white;
      text-decoration: underline;
    }

    &_copy {
      position: relative;
      text-decoration: underline;

      transition: opacity 0.25s ease-in-out;

      @include s {
        word-break: break-all;
      }

      @include sm {
        word-break: break-all;
      }

      .icon-copy {
        width: 20px;
        height: 24px;

        position: absolute;
        left: calc(100% + 7px);
        top: -1px;

        @include s {
          position: relative;
          left: auto;
          top: auto;

          margin: 10px auto 0 auto;
          text-align: center;

          display: inline;
        }

        @include sm {
          position: relative;
          left: auto;
          top: auto;

          margin: 10px auto 0 auto;
          text-align: center;

          display: inline;
        }

        &:after {
          content: "Copied!";

          display: block;

          position: absolute;
          left: calc(100% + 3px);
          top: -7px;

          white-space: nowrap;

          color: #7dcc36;

          opacity: 0;
        }
      }

      .ido__copied-text {
        position: absolute;
        left: calc(100% + 33px);
        top: -5px;
        color: #7dcc36;

        opacity: 0;

        transition: opacity 0.25s ease-in-out;

        @include s {
          position: relative;
          left: auto;
          top: auto;

          display: none !important;
        }

        @include sm {
          position: relative;
          left: auto;
          top: auto;

          display: none !important;
        }
      }

      &-active {
        color: #7dcc36;

        .icon-copy {
          path {
            stroke: #7dcc36;
          }

          &:after {
            @include s {
              opacity: 1;
            }

            @include sm {
              opacity: 1;
            }
          }
        }

        .ido__copied-text {
          opacity: 1;
        }
      }
    }
  }

  &__section {
    display: block;

    &_hidden {
      display: none;
    }
  }

  &__manage-block {
    margin-top: 110px;

    display: flex;
    align-items: center;
    justify-content: center;

    @include s {
      margin-top: 50px;
    }

    @include sm {
      margin-top: 50px;
    }

    @include tablet {
      margin-top: 60px;
    }
  }

  &__manage-btn {
    height: 70px;

    margin: 0 30px;
    padding: 0 74px;

    border-radius: 36px;

    &_cancel {
      padding: 0 45px;
      font-size: 24px;

      @include s {
        font-size: 9px;
        padding: 0 37px;
      }
    }

    @include s {
      height: 40px;

      margin: 0 15px;
      padding: 0 37px;

      border-radius: 20px;
    }

    @include sm {
      font-size: 12px;
      height: 36px;

      margin: 0 15px;
    }

    @include tablet {
      font-size: 16px;
      height: 66px;

      padding: 0 18px;

      white-space: nowrap;
    }
  }

  &__text {
    font-family: "Gotham Rounded";
    font-weight: 100;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;

    @include s {
      font-size: 15px;
      line-height: 18px;
    }

    @include sm {
      font-size: 15px;
      line-height: 18px;
    }
  }

  &__wrap {
    &_not-robot {
      margin-top: 58px;

      @include s {
        margin-top: 20px;
      }

      @include sm {
        margin-top: 20px;
      }
    }

    &_recaptcha {
      margin-top: 58px;

      display: flex;
      justify-content: center;

      min-height: 100px;

      @include s {
        margin-top: 20px;
      }

      @include sm {
        margin-top: 20px;
      }

      #my-recaptcha-id {
        transform: scale(1.2);

        @include s {
          transform: scale(1);
        }

        @include sm {
          transform: scale(1);
        }
      }
    }

    &_checkbox {
      margin: 62px auto 0 auto;

      display: flex;
      justify-content: center;

      @include s {
        margin-top: 30px;
      }

      @include sm {
        margin-top: 30px;
        max-width: 330px;
      }

      @include tablet {
        max-width: 440px;

        margin-top: 30px;
      }

      &-5 {
        max-width: 63%;

        @include s {
          max-width: none;
        }

        @include sm {
          max-width: none;
        }
      }
    }

    &_title-5 {
      margin: 0 auto;
      max-width: 73%;

      @include s {
        max-width: none;
      }

      @include sm {
        max-width: none;
      }
    }

    &_multiple-title {
      margin: 0 auto;
      max-width: 62%;

      @include s {
        max-width: none;
      }

      @include sm {
        max-width: none;
      }

      &:not(:last-child) {
        margin-bottom: 52px;

        max-width: none;

        @include s {
          margin-bottom: 20px;
        }

        @include sm {
          margin-bottom: 20px;
        }
      }
    }
  }

  &__footer {
    margin-top: 80px;
    padding: 100px 0 200px 0;

    display: flex;
    justify-content: center;

    &-text {
      font-family: "Gotham Rounded";
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      text-decoration-line: underline;
      color: #000000;

      @include s {
        text-align: left;
        font-size: 15px;
        line-height: 18px;

        padding: 0 20px;

        width: 100%;
      }

      @include sm {
        text-align: left;
        font-size: 15px;
        line-height: 18px;

        padding: 0 20px;

        width: 100%;
      }
    }
  }

  &__mayor {
    position: fixed;
    bottom: 0;
    right: 5.5%;

    width: 370px;
    height: 440px;

    background: url("../../assets/images/MayorWelcome.png") center no-repeat;
    background-size: contain;

    z-index: 10;

    @include s {
      width: 170px;
      height: 205px;
    }

    @include sm {
      width: 170px;
      height: 205px;
    }

    @include tablet {
      width: 270px;
      height: 325px;
    }
  }

  &__kyc {
    &-title {
      max-width: 720px;
      margin: -42px auto 0 auto;
    }

    &-subtitle {
      font-family: "Gotham Rounded";
      font-size: 18px;
      line-height: 22px;
      font-weight: 100;
      text-align: center;
      color: #FFFFFF;

      margin-top: 13px;

      &_link {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        text-decoration: underline;
      }
    }

    &-phone-block-wrapper {
      max-width: 400px;
      margin: 43px auto 0 auto;

      @include s {
        margin-top: 15px;
      }

      @include sm {
        margin-top: 15px;
      }
    }

    &-phone-title {
      font-family: "Gotham Rounded";
      font-size: 18px;
      line-height: 22px;
      font-weight: 100;
      text-align: center;
      color: #FFFFFF;

      @include s {
        font-size: 15px;
        line-height: 18px;
      }

      @include sm {
        font-size: 15px;
        line-height: 18px;
      }
    }

    &-phone-wrapper {
      margin-top: 21px;

      display: flex;
      justify-content: center;
      align-items: center;

      @include s {
        margin-top: 10px;
      }

      @include sm {
        margin-top: 10px;
      }
    }

    &-phone-code {
      font-family: "Gotham Rounded";
      font-weight: 500;
      font-size: 36px;
      line-height: 34px;
      text-align: center;
      color: #FFFFFF;

      @include s {
        font-size: 20px;
        line-height: 24px;
      }

      @include sm {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &-phone-input {
      margin-left: 13px;
      background: transparent;
      border: none;
      border-bottom: 3px solid white;

      font-family: "Gotham Rounded";
      font-weight: 500;
      font-size: 36px;
      line-height: 34px;
      text-align: left;
      color: #FFFFFF;
      letter-spacing: -0.3px;

      width: 300px;
      padding: 0 12px;

      @include s {
        font-size: 20px;
        line-height: 24px;

        width: 170px;

        padding: 0 6px;
      }

      @include sm {
        font-size: 20px;
        line-height: 24px;

        width: 170px;

        padding: 0 6px;
      }

      &:focus {
        border-bottom: 3px solid #FF9100;
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}