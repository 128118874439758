.offering-head {
  &__slide-list {
    padding-bottom: 169px;

    width: 1580px;
    margin: 34px auto 0 auto;

    position: relative;

    @include s {
      width: 100%;

      margin-top: 7px;

      padding-bottom: 52px;
    }

    @include tablet {
      width: 100%;

      margin-top: 7px;

      padding-bottom: 52px;
    }

    @include md {
      width: 100%;

      padding-bottom: 120px;
    }

    @include smallD {
      width: 100%;

      padding-bottom: 120px;
    }

    @include l {
      width: 100%;
    }

    .slick-list {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';

      width: 40%;
      height: 0;

      position: absolute;
      top: 25%;
      right: 10%;

      background-color: transparent;
      box-shadow: 0 120px 220px 180px rgba(77, 203, 255, 0.55);
    }
  }

  &__slide {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 0 0 80px;

    @include s {
      padding: 0 16px;

      flex-direction: column;
      justify-content: flex-start;
    }

    @include tablet {
      padding: 0 16px;

      flex-direction: column;
      justify-content: flex-start;
    }

    @include smallD {
      padding: 0 60px;
    }

    @include md {
      padding: 0 60px;
    }
  }

  &__info-box {
    display: block;

    padding-top: 13px;

    max-width: 600px;

    @include smallD {
      max-width: 500px;
    }

    @include md {
      max-width: 50%;
    }
  }

  &__preff-title {
    font-family: "Gotham Rounded";
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #d8feff;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    @include s {
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      letter-spacing: -0.1px;
    }

    @include tablet {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.1px;
    }

    @include smallD {
      font-size: 17px;
    }

    @include md {
      font-size: 17px;
    }
  }

  &__title {
    font-family: Poppins;
    font-weight: 500;
    font-size: 84px;
    line-height: 90px;
    color: white;
    letter-spacing: -2.5px;

    margin-top: 40px;

    @include s {
      font-weight: 600;
      font-size: 27px;
      line-height: 33px;
      letter-spacing: -0.5px;
      text-align: center;

      margin-top: 14px;
    }

    @include tablet {
      font-weight: 600;
      font-size: 27px;
      line-height: 33px;
      letter-spacing: -0.5px;
      text-align: center;

      margin-top: 14px;
    }

    @include smallD {
      font-size: 62px;
      line-height: 70px;

      margin-top: 25px;
    }

    @include md {
      font-size: 66px;
      line-height: 76px;

      margin-top: 25px;
    }
  }

  &__description {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #d8feff;

    margin-top: 36px;

    @include s {
      font-size: 13px;
      line-height: 20px;
      text-align: center;

      margin-top: 35px;
    }

    @include tablet {
      font-size: 16px;
      line-height: 20px;
      text-align: center;

      margin-top: 30px;
    }

    @include smallD {
      font-size: 18px;
      line-height: 26px;

      margin-top: 30px;
    }

    @include md {
      font-size: 18px;
      line-height: 28px;

      margin-top: 30px;
    }
  }

  &__icon-wrapper {
    width: 855px;
    height: 565px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    right: 43px;
    bottom: 4px;

    &_mobile {
      @include sm {
        display: block !important;
      }
    }

    @include s {
      width: 100%;
      height: 300px;

      bottom: -15px;
      right: auto;
      left: -2px;
    }

    @include sm {
      width: 400px;
      height: 340px;
    }

    @include tablet {
      width: 430px;
      height: 330px;

      bottom: -15px;
      right: auto;
      left: -2px;
    }

    @include smallD {
      width: 50%;
      height: 460px;

      right: 0;
    }

    @include md {
      width: 50%;
      height: 460px;

      right: 0;
    }

    &:before {
      content: '';

      width: 100%;
      height: 100%;

      position: absolute;
      left: 0;
      bottom: -77px;

      background: url("../../assets/images/farmShape.png") center no-repeat;
      background-size: contain;

      @include s {
        bottom: -32px;
        left: 1px;

        background-size: 99%;
      }

      @include tablet {
        bottom: -32px;
        left: 1px;

        background-size: 99%;
      }

      @include smallD {
        bottom: -50px;
      }

      @include md {
        bottom: -50px;
      }
    }
  }

  &__icon {
    display: block;

    width: 100%;
    height: 100%;

    background-size: 110%;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
    z-index: 2;

    @include s {
      background-size: 111%;
    }

    @include tablet {
      background-size: 111%;
    }
  }

  &__pagination-block {
    @include s {
      top: -30px;
    }

    @include tablet {
      top: -30px;
    }

    .nft {
      &__page-btn, &__page-line, &__page-circle {
        background: #2d42b8;

        box-shadow: 0px 2px 2px 0px rgba(100, 161, 245, 0.42),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);
      }

      &__page-circle {
        &:before {
          background: #263aaa;
          box-shadow: 0px 2px 2px 0px rgba(100, 161, 245, 0.42),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);
        }
      }
    }
  }

  &__screw {
    width: 100%;
    height: 30px;

    position: absolute;

    left: 0;
    bottom: -29px;

    @include s {
      height: 4px;
      bottom: 6px;
    }
  }
}