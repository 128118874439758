.demand {
  padding: 141px 0 170px 0;

  @include s {
    padding-top: 55px;
    padding-bottom: 52px;
  }

  @include tablet {
    padding-top: 55px;
    padding-bottom: 90px;
  }

  &__title {
    @include s {
      max-width: 300px;
      margin: 0 auto;

      line-height: 36px;
    }
  }

  &__subtitle {
    margin-top: 36px;

    @include s {
      margin: 19px auto 0 auto;
      text-align: center;

      max-width: 300px;
    }

    @include tablet {
      margin-top: 24px;
    }

    @include smallD {
      margin-top: 24px;
    }
  }

  &__icon-wrapper {
    margin-top: 133px;

    width: 107%;
    height: 485px;

    position: relative;
    left: -5.5%;

    @include s {
      left: 2px;

      width: 100%;
      height: 117px;

      margin-top: 47px;
    }

    @include sm {
      height: 150px;
    }

    @include tablet {
      left: 2px;

      width: 100%;
      height: 225px;

      margin-top: 47px;
    }

    @include smallD {
      width: 100%;
      height: 390px;

      left: 0;

      margin-top: 90px;
    }

    @include md {
      width: 100%;
      height: 390px;

      left: 0;

      margin-top: 90px;
    }

    @include l {
      margin-top: 90px;

      left: -4%;

      height: 400px;
    }
  }

  &__icon {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    display: block;
    width: 100%;
    height: 100%;
  }

  &__description-box {
    margin: 42px auto 0 auto;

    max-width: 1220px;
    height: 149px;

    position: relative;

    @include s {
      margin-top: 38px;
      left: 5px;

      height: 115px;
    }

    @include md {
      max-width: 1100px;
    }
  }

  &__item-svg-wrapper {
    g {
      filter: grayscale(1);
    }

    &_mobile {
      @include sm {
        display: block;
      }
    }
  }

  &__description-inner-box {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 20px 50px;

    @include s {
      padding: 15px 20px;
      left: -5px;
    }
  }

  &__description-text {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #4557ba;
    text-align: center;
    letter-spacing: -0.1px;

    @include s {
      font-size: 11px;
      line-height: 15px;
    }

    @include sm {
      font-size: 12px;
      line-height: 16px;
    }

    @include tablet {
      font-size: 18px;
      line-height: 24px;
    }

    @include smallD {
      font-size: 18px;
      line-height: 26px;
    }

    @include md {
      font-size: 18px;
      line-height: 26px;
    }

    @include l {
      font-size: 18px;
      line-height: 26px;
    }
  }
}

.icon-demand {
  background-image: url("../../assets/images/demand.png");

  @include s {
    background-image: url("../../assets/images/demand-mobile.png");
  }
}