/**
 * Variables
*/

/* Typography */
/* Breakpoints */
/* Paths */
/* Grid setup */
/* Colour palette */
$black: #000;
$white: #fff;
$light-gray: #f1f1f1;

$body-background-color: #333;
$foreground-color: #fff;
$caption-color: #464646;
$accent-color: #999;
$link-color: #ec1f4e;

/* Components */
$light-blue: #3c53d6;