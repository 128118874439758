.hodl {
  position: relative;

  width: 100%;
  height: 100%;

  min-width: 100vw;
  min-height: 100vh;

  overflow: hidden;

  &__loader-wrapper {
    position: fixed;
    overflow: hidden;

    width: 100%;
    height: 100%;

    min-width: 100vw;
    min-height: 100vh;
    z-index: 100;

    background: url("../../assets/images/LoadingBG.webp") center no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    opacity: 1;

    pointer-events: none;

    transition: opacity 0.5s ease-in-out;

    &_hide {
      opacity: 0;
      pointer-events: auto;
      visibility: hidden;
    }
  }

  &__unity-game-wrapper {
    opacity: 0;
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    &_show {
      opacity: 1;
      pointer-events: auto;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &__ribbon {
    width: 650px;
    height: 536px;

    display: block;

    background: url("../../assets/images/HODLValleyRibbonLogo.webp") center no-repeat;
    background-size: cover;

    @include s {
      width: 305px;
      height: 355px;

      background-size: contain;
    }

    @include tablet {
      width: 455px;
      height: 350px;

      background-size: contain;
    }
  }

  &__loader {
    width: 720px;
    height: 64px;

    margin-top: 162px;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), #E4F1FF;

    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.3), inset 0 0 8px #FFFFFF;

    border-radius: 32px;

    padding: 8px;

    @include s {
      width: 90%;
      height: 60px;
    }

    @include tablet {
      width: 550px;
      height: 62px;
    }

    &-form {
      width: 100%;
      height: 100%;

      position: relative;

      display: flex;
      align-items: center;

      overflow: hidden;

      padding-left: 40px;

      background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%), #2D41BC;

      box-shadow: 0 0 8px #FFFFFF, inset 0 0 4px rgba(0, 0, 0, 0.25), inset 0 2px 4px rgba(0, 0, 0, 0.25);

      border-radius: 24px;
    }

    &-progress {
      position: absolute;

      background: linear-gradient(180deg, #FF9A48 0%, #F25625 100%);

      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.35), inset 0px -3px 3px #F18749, inset 0px -7px 20px rgba(255, 46, 0, 0.6), inset 0px 4px 4px rgba(255, 213, 104, 0.6), inset 0px 0px 6px 3px rgba(184, 55, 0, 0.3);

      border-radius: 22px;

      top: 2px;
      left: 2px;

      height: calc(100% - 4px);

      min-width: 44px;

      transition: width 0.2s linear;
    }

    &-text {
      font-family: 'Poppins';

      font-weight: 500;
      font-size: 24px;
      text-transform: uppercase;
      text-align: center;
      color: white;
      letter-spacing: normal;

      position: absolute;
      left: 0;
      z-index: 10;

      width: 100%;

      @include s {
        font-size: 20px;
      }
    }
  }
}