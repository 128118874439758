.cookie-form {
  position: fixed;

  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: auto;

  justify-content: center;
  align-items: center;

  padding: 56px 20px;

  background: #3d61da;

  z-index: 10000;

  box-shadow: 0 0 15px 0 rgba(10, 28, 131, 35);

  @include s {
    padding: 25px 20px;
  }

  &_show {
    display: flex;
  }

  &_hide {
    display: none;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;

    @include s {
      flex-direction: column;
    }
  }

  &__text {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 23px;
    color: #d2f1ff;
    letter-spacing: -0.1px;

    text-align: center;

    &_link {
      color: white;
      text-decoration: underline;
    }

    @include s {
      font-size: 14px;
    }
  }

  &__manage-list {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 13px;

    @include s {
      margin-left: 0;
      margin-top: 15px;
    }
  }

  &__btn {
    width: 180px;

    margin: 0 15px;

    display: flex;
    justify-content: center;

    @include s {
      width: 100px;
    }
  }
}