.competition {
  width: 100%;

  background: #3d5ed9;

  position: relative;

  padding-top: 146px;
  padding-bottom: 180px;

  @include s {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include tablet {
    padding-top: 90px;
    padding-bottom: 100px;
  }

  @include smallD {
    padding-top: 90px;
    padding-bottom: 100px;
  }

  @include md {
    padding-top: 120px;
    padding-bottom: 130px;
  }

  &__shadow {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    overflow: hidden;

    &:before {
      content: '';

      position: absolute;

      top: 10%;
      left: 0;

      width: 100%;
      height: 0;

      background-color: transparent;
      box-shadow: 0 0 220px 140px rgba(77, 203, 255, 0.25);
    }
  }

  &__screw {
    position: absolute;
    left: 0;
    width: 100%;
    height: 30px;

    z-index: 3;

    bottom: -29px;

    @include s {
      height: 3px;
      bottom: 9px;
    }
  }

  &__title {
    letter-spacing: -1.5px;

    @include s {
      letter-spacing: -1px;
    }
  }

  &__subtitle {
    text-align: center;
    letter-spacing: -0.5px;

    margin-top: 20px;

    @include s {
      margin: 20px auto 0 auto;
      max-width: 75%;
    }
  }

  &__table-wrapper {
    margin: 39px auto 0 auto;

    width: 86%;

    @include s {
      width: 100%;
      margin-top: 19px;

      padding: 0 3px;
    }
  }

  &__form-wrapper {
    background: #305ac6;
    border-radius: 34px;

    display: flex;
    align-items: flex-start;

    padding: 17px 19px;

    margin-top: 9px;

    @include s {
      padding: 5px 5px;

      border-radius: 8px;

      margin-top: 3px;
    }
  }

  &__splitter-wrapper {
    padding: 6px 13px;

    margin-right: 21px;

    width: 117px;

    background: linear-gradient(180deg, #FF9A48 0%, #F25625 100%);

    box-shadow: 0px 12px 24px 4px rgba(0, 0, 0, 0.3), inset 0px -3px 3px #F18749, inset 0px -7px 4px rgba(255, 46, 0, 0.6), inset 0px 4px 4px rgba(255, 213, 104, 0.6), inset 0px 0px 6px 3px rgba(184, 55, 0, 0.3);

    border-radius: 16px;

    @include s {
      width: 32px;

      border-radius: 3px;

      margin-right: 6px;

      padding: 3px 4px;
    }
  }

  &__splitter-block {
    font-family: "Gotham Rounded";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 29px;
    text-align: center;

    color: #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 137px;

    @include s {
      font-size: 5px;

      height: 37px;
    }

    &:not(:last-child) {
      border-bottom: 2px solid rgba(255, 255, 255, 0.4);

      @include s {
        border-bottom-width: 1px;
      }
    }

    &_defi {
      height: 270px;

      @include s {
        height: 71px;
      }
    }

    &_gamefi {
      height: 140px;

      @include s {
        height: 41px;
      }
    }

    &_network {
      height: 131px;

      @include s {
        height: 33px;
      }
    }
  }

  &__main-table-wrapper {
    flex-grow: 1;
  }

  &__header-row {
    width: 100%;
    height: 80px;

    display: flex;
    align-items: center;

    padding: 0 20px 0 160px;

    .competition__column {
      border: none !important;

      padding: 0 15px;
    }

    .icon-competition-binance {
      background-size: 112%;
    }

    @include s {
      height: 20px;
      padding: 0 6px 0 40px;

      .competition__column {
        @include s {
          padding: 0 5px;
        }
      }
    }
  }

  &__competitor-icon-wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__competitor-icon {
    display: block;

    width: 100%;
    height: 100%;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__row {
    height: 69px;

    display: flex;

    @include s {
      height: 18.8px;
    }

    &:not(:last-child) {
      border-bottom: 2px solid #DBE3EB;

      @include s {
        border-bottom-width: 1px;
      }
    }
  }

  &__column {
    width: 17.3%;
    height: 100%;

    padding: 10px 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    @include s {
      padding: 2px 5px;
    }

    @include tablet {
      padding: 2px 5px;
    }

    &:not(:last-child) {
      border-right: 2px solid #DBE3EB;

      @include s {
        border-right-width: 1px;
      }

      @include tablet {
        border-right-width: 1px;
      }
    }

    &_1 {
      width: 24.8%;
    }

    &_2 {
      width: 23.5%;

      @include smallD {
        width: 20%;
      }
    }
  }

  &__main-table {
    background: #FFFFFF;

    box-shadow: 0px 12px 24px 4px rgba(0, 0, 0, 0.3);

    border-radius: 16px;

    @include s {
      border-radius: 4px;
    }
  }

  &__main-table-text {
    font-family: "Gotham Rounded";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;

    color: #3651CB;

    width: 100%;

    margin-top: 5px;

    @include s {
      font-size: 4px;
      line-height: 5px;

      margin-top: 4px;
    }

    @include tablet {
      font-size: 12px;
      line-height: 12px;

      margin-top: 2px;
    }

    @include smallD {
      font-size: 12px;
      line-height: 15px;
    }

    @include md {
      font-size: 13px;
      line-height: 15px;
    }
  }
}

.icon-competition-hodl {
  background-image: url("../../assets/images/HodlVerse.png");
}

.icon-competition-uniswap {
  background-image: url("../../assets/images/uniswap.png");
}

.icon-competition-pancake {
  background-image: url("../../assets/images/pancake.png");
}

.icon-competition-binance {
  background-image: url("../../assets/images/binance.png");
}

.icon-success {
  background: url("../../assets/images/success.png") center no-repeat;
  background-size: contain;

  width: 100%;
  height: 100%;
}

.success {
  width: 24px;
  height: 24px;

  border-radius: 50%;

  overflow: hidden;

  background: linear-gradient(180deg, #10ECAC 0%, #01956A 100%);
  box-shadow: 0px 3px 4px rgba(5, 103, 74, 0.25), inset 0px 2px 2px #32FFC3, inset 0px 0px 4px rgba(0, 0, 0, 0.35);

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 5px;

  @include s {
    padding: 2px;

    width: 7px;
    height: 7px;
  }
}