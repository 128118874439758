.properties {
  background: #f5f7fa;

  position: relative;

  padding: 69px 0 88px 0;

  @include s {
    padding-top: 40px;
    padding-bottom: 46px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include s {
      flex-direction: column;
    }
  }

  &__column {
    display: block;

    &_main {
      width: 60%;

      @include s {
        width: 100%;
      }
    }

    &_extra {
      width: 35.5%;

      @include s {
        width: 100%;
      }
    }
  }

  &__main-icon-wrapper {
    width: 810px;
    height: 537px;

    display: block;

    position: relative;

    @include s {
      width: 300px;
      height: 280px;

      margin: 0 auto;
    }

    @include sm {
      width: 400px;

      margin: 0 auto;
    }

    @include tablet {
      width: 420px;
      height: 420px;

      margin: 0 auto;
    }

    @include smallD {
      width: 100%;
      height: 390px;
    }

    @include md {
      width: 680px;
      height: 480px;
    }

    @include l {
      width: 640px;
      height: 450px;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 10px;

    width: 100%;
    height: 100%;

    background-position: center;
    background-size: 108%;
    background-repeat: no-repeat;

    display: block;

    opacity: 0;

    transition: opacity 0.25s ease-in-out;

    &_hodl-valley {
      top: auto;
      left: -3px;
      bottom: -45px;

      background-size: 103%;

      @include s {
        left: -5px;
        bottom: -30px;
      }

      @include sm {
        left: 0;
        bottom: -34px;
      }

      @include l {
        bottom: 0;
      }
    }

    &_hodl-zug {
      top: auto;
      left: -7px;
      bottom: -24px;

      @include sm {
        left: 0;
      }

      @include l {
        bottom: 17px;
      }
    }

    &_hodl-kong {
      top: auto;
      left: -7px;
      bottom: -24px;

      @include sm {
        left: 0;
      }

      @include l {
        bottom: 16px;
      }
    }

    &_hodl-island {
      top: auto;
      left: -9px;
      bottom: -24px;

      @include sm {
        left: 0;
      }

      @include l {
        bottom: 16px;
      }
    }

    &_hodl-si {
      top: auto;
      left: -9px;
      bottom: -24px;

      @include sm {
        left: 0;
      }

      @include l {
        bottom: 16px;
      }
    }

    &_hodl-delhi {
      top: auto;
      left: -9px;
      bottom: -24px;

      @include sm {
        left: 0;
      }

      @include l {
        bottom: 16px;
      }
    }

    &_active {
      opacity: 1;
    }
  }

  &__option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 50%;

    &-row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 64px;

        @include s {
          margin-bottom: 25px;
        }

        @include tablet {
          margin-bottom: 20px;
        }

        @include smallD {
          margin-bottom: 25px;
        }

        @include md {
          margin-bottom: 40px;
        }

        @include l {
          margin-bottom: 40px;
        }
      }

      &:first-child {
        @include s {
          margin-top: 50px;
        }
      }
    }

    &-block {
      width: 135px;
      height: 135px;

      padding: 5px;

      box-shadow: 0 2px 2px 0 rgba(255, 255, 255, 0.91);

      background-color: rgba(83, 106, 231, 0.071);

      border-radius: 35px;

      transition: box-shadow 0.25s ease-in-out;

      cursor: pointer;

      @include s {
        width: 100px;
        height: 100px;

        border-radius: 24px;
      }

      @include tablet {
        width: 100px;
        height: 100px;

        border-radius: 24px;
      }

      @include smallD {
        width: 100px;
        height: 100px;

        border-radius: 24px;
      }

      @include md {
        width: 115px;
        height: 115px;

        border-radius: 20px;

        padding: 10px;
      }

      @include l {
        width: 115px;
        height: 115px;

        border-radius: 20px;

        padding: 10px;
      }
    }

    &-icon {
      width: 100%;
      height: 100%;

      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      position: relative;

      &.icon-hodl-valley {
        top: -4px;
      }

      &.icon-hodl-zug {
        background-size: 145%;
      }

      &.icon-hodl-kong {
        background-size: 125%;
      }

      &.icon-hodl-island {
        background-size: 97%;
        top: -4px;
      }

      &.icon-hodl-si {
        background-size: 148%;
      }

      &.icon-hodl-delhi {
        background-size: 114%;
        top: -4px;
      }
    }

    &-text {
      font-family: "Gotham Rounded";
      font-weight: 500;
      font-size: 16px;
      color: #4557ba;
      text-align: center;

      margin-top: 26px;

      @include smallD {
        margin-top: 20px;
      }

      @include md {
        margin-top: 20px;
      }
    }

    &_active, &:hover {
      .properties__option-block {
        box-shadow: 0 0 0 10px rgb(248, 159, 29);

        @include s {
          box-shadow: 0 0 0 7px rgb(248, 159, 29);
        }
      }
    }
  }

  &__arrow-list {
    width: 44%;

    margin: 0 auto 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;

    top: 35px;
    left: -5.5%;

    @include s {
      top: -10px;
      left: 0;
    }
  }
}

.icon-hodl-valley {
  background-image: url("../../assets/images/HodlValley.png");
}

.icon-hodl-zug {
  background-image: url("../../assets/images/HodlZug.png");
}

.icon-hodl-kong {
  background-image: url("../../assets/images/HodlKong.png");
}

.icon-hodl-island {
  background-image: url("../../assets/images/HodlIsland.png");
}

.icon-hodl-si {
  background-image: url("../../assets/images/HodlSi.png");
}

.icon-hodl-delhi {
  background-image: url("../../assets/images/HodlDelhi.png");
}

.icon-hodl-valley-building {
  background-image: url("../../assets/images/properties/HV_Townhall.webp");
}

.icon-hodl-zug-building {
  background-image: url("../../assets/images/properties/HZ_Townhall.webp");
}

.icon-hodl-kong-building {
  background-image: url("../../assets/images/properties/HK_Townhall.webp");
}

.icon-hodl-island-building {
  background-image: url("../../assets/images/properties/HI_Townhall.webp");
}

.icon-hodl-si-building {
  background-image: url("../../assets/images/properties/HS_Townhall.webp");
}

.icon-hodl-delhi-building {
  background-image: url("../../assets/images/properties/HD_Townhall.webp");
}