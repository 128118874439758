.bridge-form {
  width: 100%;
  height: 1155px;

  position: relative;

  background: url("../../assets/images/bridge-form-bg.webp") center no-repeat;
  background-size: contain;

  @include s {
    background: url("../../assets/images/bridge-form-bg-mobile.webp") center no-repeat;
    background-size: contain;

    margin: 0 auto;

    width: 340px;
    height: 520px;
  }

  @include xs {
    width: 300px;
    height: 460px;
  }

  @include sm {
    width: 400px;
    height: 610px;
  }

  @include tablet {
    height: 560px;
  }

  @include smallD {
    height: 820px;
  }

  @include md {
    height: 920px;
  }

  &__token {
    position: absolute;
    top: 32.4%;
    left: 1.8%;

    width: 350px;
    height: 350px;

    background: url("../../assets/images/MTokenFront.webp") center no-repeat;
    background-size: contain;

    @include s {
      display: none;
    }

    @include tablet {
      top: 31.5%;
      left: 1%;

      width: 180px;
      height: 180px;
    }

    @include smallD {
      top: 32.3%;
      left: 1.35%;

      width: 250px;
      height: 250px;
    }

    @include md {
      top: 32.3%;

      width: 280px;
      height: 280px;
    }

    @include l {
      top: 35.1%;

      width: 295px;
      height: 295px;
    }
  }

  &__btn {
    padding: 0 45px;

    width: auto;
    height: 80px;

    font-family: "Gotham Rounded";
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    color: white;
    text-align: center;

    border: none;
    border-radius: 99px;

    cursor: pointer;

    background: linear-gradient(180deg, #FF8C3C 0%, #FC7837 100%, #F96734 100%);
    box-shadow: 0px 16px 20px -8px rgba(87, 87, 87, 0.35), inset 0px -3px 3px #F08847, inset 0px -6px 4px rgba(180, 64, 64, 0.5), inset 0px 5px 10px #FFB35F;

    @include s {
      height: 28px;

      padding: 0 10px;

      font-size: 10px;
    }

    @include tablet {
      height: 30px;

      padding: 0 10px;

      font-size: 16px;
    }

    @include smallD {
      height: 50px;

      padding: 0 20px;

      font-size: 20px;
    }

    @include md {
      height: 60px;

      padding: 0 25px;

      font-size: 22px;
    }

    @include l {
      height: 70px;

      padding: 0 35px;
    }

    &_success {
      background: linear-gradient(180deg, #91DD4F 0%, #36A53E 100%);
      box-shadow: 0px 16px 20px -8px rgba(87, 87, 87, 0.35), inset 0px -3px 3px rgba(131, 210, 103, 0.5), inset 0px -10px 8px rgba(19, 146, 36, 0.8), inset 0px 5px 5px #AEFF92;
    }

    &_full {
      width: 100%;
    }
  }

  &__input {
    background: #FCFDFF;

    box-shadow: inset 0px 4px 3px rgba(8, 24, 113, 0.1);

    border-radius: 24px;

    width: 362px;
    height: 90px;

    padding: 24px 29px;

    font-family: Poppins;
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: -0.03em;
    color: black;

    border: none;

    &::placeholder {
      color: #A5ACC8;
    }

    @include s {
      width: 100%;
      height: 28px;

      font-size: 15px;
      line-height: 24px;

      padding: 5px 10px;

      border-radius: 8px;
    }

    @include tablet {
      height: 30px;

      font-size: 16px;
      line-height: 24px;

      padding: 10px 10px;

      border-radius: 10px;
    }

    @include smallD {
      height: 50px;

      font-size: 20px;
      line-height: 30px;

      padding: 16px 16px;

      border-radius: 16px;
    }

    @include md {
      height: 60px;

      font-size: 24px;
      line-height: 34px;

      padding: 18px 22px;

      border-radius: 18px;
    }

    @include l {
      height: 70px;

      font-size: 26px;
      line-height: 38px;

      padding: 20px 24px;
    }
  }

  &__token-dropdown {
    position: relative;

    width: 255px;
    height: 90px;

    z-index: 10;

    @include s {
      width: 100px;
      height: 28px;
    }

    @include tablet {
      width: 140px;
      height: 30px;
    }

    @include smallD {
      width: 190px;
      height: 50px;
    }

    @include mdl {
      width: 200px;
      height: 70px;
    }

    &_up {
      z-index: 15;
    }

    &-value-wrapper {
      width: 100%;
      height: 90px;

      background: linear-gradient(180deg, #FFFFFF 0%, #E7EAF9 68.75%);
      box-shadow: 0px 10px 20px rgba(87, 87, 87, 0.15), inset 0px -10px 10px #FFFFFF, inset 0px -30px 40px rgba(255, 255, 255, 0.8), inset 0px 15px 15px rgba(255, 255, 255, 0.6);
      border-radius: 45px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 17px;

      cursor: pointer;

      position: relative;

      z-index: 5;

      @include s {
        height: 28px;

        padding: 5px;
      }

      @include tablet {
        height: 30px;

        padding: 4px;
      }

      @include smallD {
        height: 50px;

        padding: 10px 7px;
      }

      @include mdl {
        height: 70px;

        padding: 10px;
      }
    }

    &-arrow-wrapper {
      width: 56px;
      height: 56px;

      background: linear-gradient(0deg, #E7EAF9, #E7EAF9);
      box-shadow: 0px 1px 12px rgba(69, 87, 186, 0.15), inset 0px -10px 10px #FFFFFF, inset 0px -30px 40px rgba(255, 255, 255, 0.8), inset 0px 15px 15px rgba(255, 255, 255, 0.6);
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      @include s {
        width: 22px;
        height: 22px;
      }

      @include tablet {
        width: 24px;
        height: 24px;
      }

      @include smallD {
        width: 36px;
        height: 36px;
      }

      &:before {
       content: '';

        display: block;
        width: 24px;
        height: 14px;

        background: url("../../assets/icons/tokenDropdownArrow.svg") center no-repeat;
        background-size: contain;

        transition: transform 0.25s linear;

        @include s {
          width: 12px;
        }

        @include tablet {
          width: 12px;
        }

        @include smallD {
          width: 16px;
          height: 7px;
        }
      }
    }

    &-expand-list {
      position: absolute;

      top: 50%;
      left: 0;

      width: 100%;

      background: linear-gradient(180deg, #FFFFFF 0%, #E7EAF9 68.75%);
      box-shadow: 0px 10px 20px rgba(87, 87, 87, 0.15), inset 0px -10px 10px #FFFFFF, inset 0px -30px 40px rgba(255, 255, 255, 0.8), inset 0px 15px 15px rgba(255, 255, 255, 0.6);

      border-bottom-left-radius: 45px;
      border-bottom-right-radius: 45px;

      padding: 0;

      .bridge-form__token-dropdown-item {
        padding: 8px 17px;
        width: 100%;

        @include s {
          padding: 3px 5px;
        }

        @include tablet {
          padding: 5px 10px;
        }
      }

      max-height: 0;
      overflow: hidden;

      transition: max-height 0.25s linear, padding 0.25s linear;

      @include s {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      @include tablet {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }

    &-item {
      display: flex;
      align-items: center;

      width: calc(100% - 60px);
      height: 100%;

      cursor: pointer;

      @include s {
        width: calc(100% - 26px);
      }

      @include tablet {
        width: calc(100% - 30px);
      }

      @include smallD {
        width: calc(100% - 40px);
      }

      &-icon {
        width: 40px;
        height: 38px;

        display: flex;
        align-items: center;
        justify-content: center;

        display: none;
      }

      &-value {
        font-family: "Gotham Rounded";
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #4557BA;

        padding-left: 7px;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        display: block;

        width: 100%;

        @include s {
          font-size: 10px;
        }

        @include tablet {
          font-size: 12px;
        }
      }
    }

    &_opened {
      .bridge-form__token-dropdown {
        &-arrow-wrapper {
          &:before {
            transform: rotate(180deg);
          }
        }

        &-expand-list {
          padding: 50px 0 25px 0;
          max-height: 200px;
          overflow-y: scroll;

          @include s {
            padding: 20px 0 0 0;
          }

          @include tablet {
            padding: 20px 0 0 0;
          }
        }
      }
    }
  }

  &__token-balance-block {
    width: 120px;

    @include s {
      width: 50px;
    }

    @include tablet {
      width: 70px;
    }

    @include smallD {
      width: 90px;
    }
  }

  &__token-balance-value {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.03em;
    color: #3352C7;

    @include s {
      font-size: 14px;
      line-height: 20px;
    }

    @include tablet {
      font-size: 16px;
      line-height: 22px;
    }

    @include smallD {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__token-balance-title {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #808DC4;

    margin-top: -4px;

    @include s {
      font-size: 12px;
      line-height: 20px;
    }

    @include tablet {
      font-size: 14px;
      line-height: 22px;
    }

    @include smallD {
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__block {
    position: absolute;
    left: 50%;

    width: 720px;
    height: auto;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding: 0 55px;

    @include s {
      left: 20px;

      width: calc(100% - 22px);

      padding: 0 20px;
    }

    @include xs {
      left: 15px;

      width: calc(100% - 16px);
    }

    @include tablet {
      width: 343px;

      padding: 0 10px;
    }

    @include smallD {
      width: 487px;

      padding: 0 10px;
    }

    @include md {
      width: 567px;

      padding: 0 30px;
    }

    @include l {
      width: 630px;
    }

    &-circle {
      position: absolute;
      left: -15px;
      top: 30px;

      width: 30px;
      height: 30px;

      border-radius: 50%;

      overflow: hidden;

      background: #DBDEED;
      box-shadow: 0px 2px 2px rgba(255, 255, 255, 0.9), inset 0px 2px 2px rgba(8, 24, 113, 0.2);

      padding: 8px;

      @include s {
        width: 20px;
        height: 20px;

        padding: 5px;

        top: 15px;
        left: -12px;
      }

      @include xs {
        width: 16px;
        height: 16px;

        padding: 4px;

        top: 15px;
        left: -9px;
      }

      @include tablet {
        width: 20px;
        height: 20px;

        padding: 5px;

        left: -10px;
      }

      &:before {
        content: '';
        display: block;

        width: 100%;
        height: 100%;

        border-radius: 50%;

        overflow: hidden;

        background: #FE823D;
        box-shadow: 0px 3px 2px -1.5px rgba(189, 113, 97, 0.75), inset 0px 3px 3px #FCD685, inset 0px -2px 2px #FECE79, inset 0px -3px 4px #F7672A;
      }
    }

    &-number {
      font-family: Poppins;
      font-weight: 600;
      font-size: 124px;
      line-height: 92px;
      text-align: center;
      letter-spacing: -0.01em;

      color: rgba(53, 82, 202, 0.1);

      width: 100px;

      @include s {
        font-size: 60px;
        line-height: 50px;

        width: 40px;
      }

      @include tablet {
        font-size: 60px;
        line-height: 50px;

        width: 40px;
      }

      @include smallD {
        font-size: 80px;
        line-height: 64px;

        width: 60px;
      }

      @include md {
        font-size: 90px;
        line-height: 74px;

        width: 70px;
      }

      @include l {
        font-size: 110px;
        line-height: 84px;

        width: 70px;
      }
    }

    &-info {
      width: calc(100% - 150px);

      padding: 0 10px 0 40px;

      @include s {
        width: calc(100% - 70px);
        padding: 0 10px;
      }

      @include tablet {
        width: calc(100% - 80px);
        padding: 0 10px 0 15px;
      }

      @include smallD {
        padding: 0 10px 0 15px;
      }

      @include md {
        padding: 0 10px 0 20px;
      }

      @include l {
        padding: 0 10px 0 30px;
      }

      &-title {
        font-family: Poppins;
        font-weight: 600;
        font-size: 36px;
        line-height: 46px;
        letter-spacing: -0.01em;

        color: #6077D4;

        @include s {
          font-size: 18px;
          line-height: 24px;
        }

        @include tablet {
          font-size: 18px;
          line-height: 24px;
        }

        @include smallD {
          font-size: 26px;
          line-height: 36px;
        }

        @include md {
          font-size: 30px;
          line-height: 40px;
        }

        @include l {
          font-size: 34px;
          line-height: 44px;
        }
      }

      &-action-wrapper {
        margin-top: 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        @include s {
          margin-top: 5px;
        }

        @include tablet {
          margin-top: 5px;
        }

        @include smallD {
          margin-top: 10px;
        }

        @include md {
          margin-top: 10px;
        }

        @include l {
          margin-top: 15px;
        }
      }
    }

    &-checkbox-wrapper {
      width: 50px;

      padding-top: 20px;

      svg {
        width: 100%;
      }

      @include s {
        width: 30px;

        padding-top: 0;
      }

      @include tablet {
        width: 30px;
        height: 30px;

        padding-top: 10px;
      }
    }

    &_active {
      .bridge-form__block-checkbox-wrapper {
        svg {
          fill: #00A267;
        }
      }
    }

    &_wallet {
      top: 91px;

      @include s {
        top: 37px;
      }

      @include xs {
        top: 32px;
      }

      @include sm {
        top: 47px;
      }

      @include tablet {
        top: 29px;
      }

      @include smallD {
        top: 64px;
      }

      @include md {
        top: 66px;
      }

      @include l {
        top: 162px;
      }
    }

    &_token-1 {
      top: 292px;
      z-index: 20;

      @include s {
        top: 127px;
      }

      @include xs {
        top: 111px;
      }

      @include sm {
        top: 153px;
      }

      @include tablet {
        top: 125px;
      }

      @include smallD {
        top: 201px;
      }

      @include md {
        top: 224px;
      }

      @include l {
        top: 330px;
      }
    }

    &_token-2 {
      top: 504px;

      @include s {
        top: 221px;
      }

      @include xs {
        top: 195px;
      }

      @include sm {
        top: 263px;
      }

      @include tablet {
        top: 226px;
      }

      @include smallD {
        top: 345px;
      }

      @include md {
        top: 391px;
      }

      @include l {
        top: 507px;
      }
    }

    &_amount {
      top: 714px;

      @include s {
        top: 316px;
      }

      @include xs {
        top: 278px;
      }

      @include sm {
        top: 375px;
      }

      @include tablet {
        top: 326px;
      }

      @include smallD {
        top: 487px;
      }

      @include md {
        top: 557px;
      }

      @include l {
        top: 683px;
      }
    }

    &_convert {
      top: 923px;

      @include s {
        top: 409px;
      }

      @include xs {
        top: 360px;
      }

      @include sm {
        top: 485px;
      }

      @include tablet {
        top: 426px;
      }

      @include smallD {
        top: 629px;
      }

      @include md {
        top: 722px;
      }

      @include l {
        top: 858px;
      }
    }
  }
}