.decentralize {
  background: #3e66dc;

  padding: 154px 0 104px 0;

  position: relative;
  z-index: 9;

  @include s {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  @include tablet {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  @include smallD {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  @include md {
    padding-top: 120px;
    padding-bottom: 90px;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  &:after {
    content: '';

    width: 100%;
    height: 0;

    position: absolute;
    top: 25%;
    left: 0;

    background-color: transparent;
    box-shadow: 0 0 220px 280px rgba(77, 203, 255, 0.2);
  }

  &__title {
    @include s {
      max-width: 340px;
      letter-spacing: 0;
      text-align: center;

      margin: 0 auto;
    }
  }

  &__subtitle {
    margin-top: 66px;

    @include s {
      margin: 20px auto 0 auto;
      max-width: 300px;
    }

    @include tablet {
      margin: 25px auto 0 auto;
    }

    @include smallD {
      margin: 30px auto 0 auto;
    }

    @include md {
      margin-top: 36px;
    }
  }

  &__screw {
    position: absolute;

    left: 0;
    bottom: -29px;

    width: 100%;
    height: 30px;

    z-index: 3;

    @include s {
      height: 4px;
      bottom: 6px;
    }
  }

  &__list {
    margin-top: 107px;
    padding: 0 24px;

    @include s {
      max-width: 346px;

      margin: 31px auto 0 auto;
      padding: 0 8px 134px 8px;

      position: relative;
    }

    @include tablet {
      width: 680px;
      margin: 50px auto 0 auto;

      padding: 0;
    }

    @include smallD {
      width: 930px;
      margin: 50px auto 0 auto;

      padding: 0;
    }

    @include md {
      margin-top: 50px;
    }
  }

  &__list-circle-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;

    background: url("../../assets/images/decentralizeCircles.png") center no-repeat;
    background-size: contain;

    width: 100%;
    height: 350px;

    @include s {
      background: url("../../assets/images/decentralizeCircles-mobile.png") center no-repeat;
      background-size: 102%;

      height: 461px;
    }

    @include tablet {
      width: 680px;
      height: 170px;
    }

    @include smallD {
      width: 930px;
      height: 240px;
    }

    @include md {
      width: 1120px;
      height: 270px;

      margin: 0 auto;
    }
  }

  &__list-info-row {
    display: inline-flex;
    align-items: flex-start;
    justify-content: space-between;

    margin-top: 14px;
    padding: 0 5px;

    width: 49%;

    @include s {
      width: 100%;

      position: absolute;
      top: 27.4%;
    }

    &:last-child {
      @include s {
        top: 76.7%;
      }
    }

    &_second {
      @include md {
        margin-left: 2%;
      }
    }
  }

  &__info-item {
    width: 300px;
    position: relative;

    @include s {
      width: 41%;
    }

    @include md {
      width: 250px;
    }

    &:last-child {
      @include s {
        width: 51%;
      }
    }

    &-number {
      position: absolute;
      top: 0;
      left: 25px;

      font-family: Poppins;
      font-weight: 600;
      font-size: 124px;
      line-height: 81px;
      color: #5ce6ff;

      opacity: 0.1;

      @include s {
        font-size: 60px;
        line-height: 60px;

        top: -9px;
        left: 24px;
      }

      @include tablet {
        font-size: 92px;
        line-height: 62px;

        left: 0;
      }

      @include md {
        font-size: 104px;
        line-height: 70px;
      }
    }

    &-title {
      font-family: Poppins;
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
      color: white;
      text-align: center;

      @include s {
        font-size: 18px;
        line-height: 22px;
      }

      @include tablet {
        font-size: 24px;
        line-height: 30px;
      }

      @include md {
        font-size: 32px;
        line-height: 36px;
      }
    }

    &-description {
      font-family: "Gotham Rounded";
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: #d2f1ff;
      text-align: center;

      margin-top: 27px;

      @include s {
        font-size: 7.5px;
        line-height: 10px;

        margin: 13px auto 0 auto;
        max-width: 140px;
      }

      @include tablet {
        font-size: 12px;
        line-height: 16px;

        margin: 13px auto 0 auto;
        max-width: 140px;
      }
    }

    &:first-child {
      .decentralize__info-item-number {
        left: 45px;

        @include s {
          left: 18px;
        }

        @include tablet {
          left: 10px;
        }

        @include md {
          left: 32px;
        }
      }
    }
  }
}