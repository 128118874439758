.page-404 {
  padding-top: 70px;

  background: #3c53d6;

  min-height: 530px;

  position: relative;

  &__top-wrapper {
    padding-bottom: 0;
  }

  &__screw {
    position: absolute;

    left: 0;
    top: calc(100% - 1px);

    width: 100%;
    height: 85px;

    z-index: 3;

    &_under {
      height: 85px;
      top: calc(100% + 8px);

      z-index: 2;
    }
  }

  &__container {
    position: relative;
    z-index: 5;
  }

  &__title {
    font-family: "Gotham Rounded";
    font-weight: bold;
    font-size: 288px;
    line-height: 255px;
    text-align: center;
    color: #FFFFFF;

    @include s {
      font-size: 130px;
      line-height: 140px;
    }

    @include sm {
      font-size: 170px;
      line-height: 200px;
    }

    @include tablet {
      font-size: 240px;
    }
  }

  &__subtitle {
    font-family: "Gotham Rounded";
    font-weight: bold;
    font-size: 48px;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF;

    margin-top: -10px;

    @include s {
      font-size: 28px;
    }

    @include sm {
      font-size: 36px;
    }

    @include tablet {
      font-size: 42px;
    }
  }

  &__text {
    font-family: "Gotham Rounded";
    font-weight: 500;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF;

    margin-top: 29px;

    @include s {
      font-size: 16px;
      line-height: 22px;
    }

    @include sm {
      font-size: 18px;
      line-height: 26px;
    }

    @include tablet {
      font-size: 22px;
    }
  }

  &__manage-block {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: -98px;
    left: 0;

    width: 100%;

    z-index: 20;
  }

  &__manage-btn {
    height: 70px;

    margin: 0 30px;
    padding: 0 60px;

    border-radius: 36px;
  }

  &__mayor {
    position: fixed;
    bottom: 0;
    right: 5.5%;

    width: 370px;
    height: 440px;

    background: url("../../assets/images/MayorWelcome.png") center no-repeat;
    background-size: contain;

    z-index: 10;
  }

  &__footer-empty {
    width: 100%;
    height: 150px;
  }
}