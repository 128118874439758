.metaverse {
  background: white;

  padding: 158px 0 123px 0;

  position: relative;
  z-index: 8;

  @include s {
    padding-top: 56px;
    padding-bottom: 54px;
  }

  @include sm {
    padding-bottom: 0;
  }

  @include tablet {
    padding-top: 56px;
    padding-bottom: 54px;
  }

  @include smallD {
    padding-top: 90px;
    padding-bottom: 30px;
  }

  @include md {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  @include l {
    padding-top: 130px;
  }

  &__title {
    text-align: left;

    @include s {
      text-align: center;
      line-height: 36px;
      letter-spacing: 0;
    }

    @include tablet {
      text-align: center;
      line-height: 36px;
      letter-spacing: 0;
    }
  }

  &__subtitle {
    margin: 38px auto 0 0;

    text-align: left;

    @include s {
      text-align: center;
      margin: 19px auto 0 auto;

      max-width: 265px;
    }

    @include tablet {
      text-align: center;
      margin: 19px auto 0 auto;

      max-width: 600px;
    }
  }

  &__screw {
    position: absolute;

    left: 0;
    bottom: -29px;

    width: 100%;
    height: 30px;

    z-index: 3;
  }

  &__list {
    margin-top: 75px;

    @include s {
      margin-top: 37px;
      padding-left: 2px;
    }

    @include tablet {
      margin-top: 37px;
      padding-left: 2px;
    }

    @include smallD {
      width: 990px;

      margin: 40px auto 0 auto;
    }

    @include md {
      width: 1100px;

      margin: 65px auto 0 auto;
    }

    @include l {
      width: 1100px;

      margin: 65px auto 0 auto;
    }
  }

  &__item {
    width: 1590px;
    height: 483px;

    margin-left: auto;
    margin-right: auto;

    position: relative;

    @include s {
      width: 90%;
      height: 490px;
    }

    @include sm {
      width: 320px;
    }

    @include tablet {
      width: 520px;
      height: 750px;
    }

    @include smallD {
      width: 100%;
      height: 310px;
    }

    @include md {
      width: 100%;
      height: 370px;
    }

    @include l {
      width: 100%;
      height: 340px;
    }

    &-svg-wrapper {
      width: 100%;
      height: 100%;

      g {
        filter: grayscale(1);
      }
    }

    &:not(:last-child) {
      margin-bottom: 53px;

      @include s {
        margin-bottom: 22px;
      }

      @include tablet {
        margin-bottom: 22px;
      }

      @include smallD {
        margin-bottom: 30px;
      }
    }

    &-form {
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      display: flex;
      justify-content: space-between;

      padding: 75px 74px;

      @include s {
        padding: 37px 37px;
        flex-direction: column;
        justify-content: flex-start;
      }

      @include xs {
        padding: 55px 20px;
      }

      @include sm {
        padding: 35px 20px;
      }

      @include tablet {
        padding: 50px;
        flex-direction: column;
        justify-content: flex-start;
      }

      @include smallD {
        padding: 30px 30px 10px 30px;
      }

      @include md {
        padding: 50px 50px 10px 50px;
      }

      @include l {
        padding: 50px 50px 10px 50px;
      }
    }

    &-token-wrapper {
      position: absolute;

      top: 16.5%;
      left: 23.6%;

      width: 300px;
      height: 300px;

      border-radius: 50%;

      background-color: rgba(53, 82, 202, 0.071);
      box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.91),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);

      @include s {
        width: 150px;
        height: 150px;

        top: 7.8%;
        left: auto;
        right: 11.3%;
      }

      @include xs {
        width: 120px;
        height: 120px;

        top: 11%;
        right: 5%;
      }

      @include sm {
        width: 120px;
        height: 120px;
      }

      @include tablet {
        width: 180px;
        height: 180px;

        top: 7.8%;
        left: auto;
        right: 11.3%;
      }

      @include smallD {
        width: 200px;
        height: 200px;

        left: 21%;
      }

      @include md {
        width: 240px;
        height: 240px;
      }

      @include l {
        width: 240px;
        height: 240px;
      }

      &:before {
        content: '';

        position: absolute;

        width: 100%;
        height: 100%;

        top: 0;
        left: 0;
      }

      &_red {
        &:before {
          background: url("../../assets/images/mTokenRed.png") center no-repeat;
          background-size: contain;

          filter: drop-shadow(11px 30px 16px rgba(198, 0, 0, 0.4));

          @include s {
            filter: drop-shadow(6px 20px 10px rgba(198, 0, 0, 0.3));
          }
        }
      }

      &_yellow {
        &:before {
          background: url("../../assets/images/mTokenYellow.png") center no-repeat;
          background-size: contain;

          filter: drop-shadow(11px 30px 16px rgba(166, 100, 33, 0.4));

          @include s {
            filter: drop-shadow(6px 20px 10px rgba(166, 100, 33, 0.3));
          }
        }
      }
    }

    &-column {
      width: 20%;

      @include s {
        width: 50%;
      }

      @include tablet {
        width: 50%;
      }

      &:last-child {
        width: 50.1%;

        @include s {
          width: 100%;

          margin-top: 76px;
        }

        @include xs {
          margin-top: 30px;
        }

        @include sm {
          margin-top: 25px;
        }

        @include tablet {
          width: 100%;

          margin-top: 76px;
        }

        @include smallD {
          width: 56%;
        }

        &.metaverse__item-column_hvlp {
          @include s {
            margin-top: 80px;
          }

          @include xs {
            margin-top: 30px;
          }

          @include sm {
            margin-top: 25px;
          }

          @include tablet {
            margin-top: 80px;
          }
        }
      }

      &_hvlp {
        margin-top: -11px;
      }
    }

    &-number {
      font-family: Poppins;
      font-weight: 600;
      font-size: 30px;
      color: #3552ca;

      margin-top: 13px;

      @include s {
        margin-top: 3px;

        font-size: 15px;
      }

      @include tablet {
        margin-top: 3px;

        font-size: 26px;
      }

      @include smallD {
        font-size: 28px;
      }

      @include md {
        font-size: 28px;
      }

      @include l {
        font-size: 28px;
      }
    }

    &-title {
      font-family: Poppins;
      font-weight: 600;
      font-size: 60px;
      line-height: 61px;
      color: #3552ca;
      text-transform: uppercase;

      margin-top: 39px;

      @include s {
        margin-top: 21px;

        font-size: 30px;
        line-height: 30px;
      }

      @include tablet {
        margin-top: 21px;

        font-size: 36px;
        line-height: 42px;
      }

      @include smallD {
        font-size: 40px;
        line-height: 48px;
      }

      @include md {
        font-size: 48px;
        line-height: 52px;
      }

      @include l {
        font-size: 48px;
        line-height: 52px;
      }
    }

    &-text {
      font-family: "Gotham Rounded";
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #3552ca;
      letter-spacing: -0.1px;

      @include s {
        font-size: 9px;
        line-height: 13px;
      }

      @include sm {
        font-size: 12px;
        line-height: 16px;
      }

      @include tablet {
        font-size: 18px;
        line-height: 26px;
      }

      @include smallD {
        font-size: 14px;
        line-height: 20px;
      }

      @include md {
        font-size: 16px;
        line-height: 22px;
      }

      @include l {
        font-size: 16px;
        line-height: 22px;
      }
    }

    &-benefit-list {
      margin: 39px 0 18px 0;

      @include s {
        margin: 16px 0 12px 0;
      }

      @include tablet {
        margin: 16px 0 12px 0;
      }

      @include smallD {
        margin: 20px 0 10px 0;
      }

      @include md {
        margin: 20px 0 10px 0;
      }

      @include l {
        margin: 20px 0 10px 0;
      }

      &_hvlp {
        margin: 51px 0 26px 4px;

        @include s {
          margin: 20px 0 12px 0;
        }

        @include tablet {
          margin: 20px 0 12px 0;
        }

        @include smallD {
          margin: 20px 0 0 4px;
        }

        @include md {
          margin: 20px 0 0 4px;
        }

        @include l {
          margin: 20px 0 0 4px;
        }
      }
    }

    &-benefit-item {
      display: inline-flex;
      align-items: center;

      font-family: "Gotham Rounded";
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #808dc4;

      margin-bottom: 21px;

      @include s {
        font-size: 9px;
        line-height: 13px;

        margin-bottom: 10px;
      }

      @include tablet {
        font-size: 16px;
        line-height: 22px;

        margin-bottom: 10px;
      }

      @include smallD {
        font-size: 14px;
        line-height: 18px;
      }

      @include md {
        font-size: 14px;
        line-height: 18px;
      }

      @include l {
        font-size: 14px;
        line-height: 18px;

        margin-bottom: 14px;
      }

      &_first {
        width: 42%;

        @include s {
          width: 49%;
        }

        @include tablet {
          width: 49%;
        }
      }

      &_second {
        width: 32%;

        @include s {
          width: 49%;
        }

        @include tablet {
          width: 49%;
        }
      }

      &_third {
        width: 24%;

        @include s {
          width: 49%;
        }

        @include tablet {
          width: 49%;
        }
      }

      &_full {
        width: 100%;
      }

      &_hvlp {
        margin-bottom: 48px;

        @include s {
          margin-bottom: 15px;
        }

        @include tablet {
          margin-bottom: 15px;
        }

        @include smallD {
          margin-bottom: 25px;
        }

        @include md {
          margin-bottom: 25px;
        }

        @include l {
          margin-bottom: 25px;
        }
      }
    }

    &-benefit-item-circle {
      width: 24px;
      height: 24px;
      min-width: 24px;

      border-radius: 50%;

      padding: 5px;

      background-color: rgba(53, 82, 202, 0.071);
      box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.91),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);

      margin-right: 11px;

      @include s {
        width: 12px;
        min-width: 12px;
        height: 12px;

        padding: 3px;

        margin-right: 4px;
      }

      @include tablet {
        width: 12px;
        min-width: 12px;
        height: 12px;

        padding: 3px;

        margin-right: 4px;
      }

      @include smallD {
        width: 20px;
        height: 20px;

        min-width: 20px;

        margin-right: 8px;
      }

      @include md {
        width: 20px;
        height: 20px;

        min-width: 20px;

        margin-right: 8px;
      }

      @include l {
        width: 20px;
        height: 20px;

        min-width: 20px;

        margin-right: 8px;
      }

      &:before {
        content: '';

        display: block;

        width: 100%;
        height: 100%;

        border-radius: 50%;

        background-image: linear-gradient( 0deg, rgb(235,95,62) 0%, rgb(253,198,81) 100%);
        box-shadow: 0px 3px 4px 0px rgba(164, 39, 3, 0.81),inset 0px 5px 5px 0px rgba(255, 238, 217, 0.3),inset 0px -3px 3px 0px rgba(255, 237, 151, 0.47),inset 0px -5px 5px 0px rgba(180, 64, 64, 0.26);
      }
    }
  }
}