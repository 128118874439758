.bridge {
  width: 100%;

  position: relative;

  &__container {
    padding-bottom: 64px;

    @include tablet {
      width: 690px;
    }

    @include smallD {
      width: 980px;
    }
  }

  &__title {
    margin-top: 118px;
  }

  &__subtitle {
    margin: 35px auto 0 auto;

    max-width: 900px;
  }

  &__screw {
    position: absolute;
    left: 0;
    width: 100%;
    height: 30px;

    z-index: 3;

    bottom: -29px;

    @include s {
      height: 4px;
      bottom: 7px;
    }
  }

  &__chart-container {
    margin: 131px auto 0 auto;

    width: 100%;
    height: 625px;

    background: url("../../assets/images/bridgeHead.webp") center no-repeat;
    background-size: contain;

    position: relative;

    @include s {
      background-image: url("../../assets/images/bridgeHeadMobile.webp");

      width: 340px;
      height: 700px;

      margin-top: 40px;
    }

    @include xs {
      width: 300px;
    }

    @include sm {
      width: 400px;
      height: 800px;

      margin-top: 60px;
    }

    @include tablet {
      height: 315px;

      margin-top: 80px;
    }

    @include smallD {
      height: 490px;

      margin-top: 80px;
    }

    @include md {
      height: 540px;

      margin-top: 100px;
    }
  }

  &__chart-money-token {
    background: url("../../assets/images/mTokenRed.png") center no-repeat;
    background-size: contain;

    position: absolute;
    top: 0;
    left: 39.5%;

    width: 300px;
    height: 300px;

    @include s {
      top: 283px;
      left: 22px;
      width: 136px;
      height: 136px;
    }

    @include xs {
      left: 4px;
    }

    @include sm {
      top: 320px;
      left: 29px;

      width: 160px;
      height: 160px;
    }

    @include tablet {
      top: 11px;
      left: 39.8%;

      width: 140px;
      height: 140px
    }

    @include smallD {
      top: 23px;
      left: 39.6%;

      width: 204px;
      height: 221px;
    }

    @include md {
      top: 23px;
      left: 39.9%;

      width: 234px;
      height: 234px;
    }

    @include l {
      top: 50px;
      left: 39.7%;

      width: 250px;
      height: 250px;
    }
  }

  &__chart-token {
    position: absolute;
    top: 460px;

    @include tablet {
      top: 228px;
    }

    @include smallD {
      top: 345px;
    }

    @include md {
      top: 387px;
    }

    @include l {
      top: 436px;
    }

    &-box {
      width: 150px;
      height: 150px;

      border-radius: 40px;

      overflow: hidden;

      background-color: rgba(87, 210, 255, 0.3);

      padding: 10px;

      display: flex;
      justify-content: center;
      align-items: center;

      box-shadow: 0px 30px 30px -20px #1B2FA5, inset 0px 5px 5px #6596F3, inset 0px -5px 5px #3F57DD;

      @include s {
        width: 73px;
        height: 73px;

        border-radius: 22px;
      }

      @include sm {
        width: 86px;
        height: 86px;

        border-radius: 22px;
      }

      @include tablet {
        width: 73px;
        height: 73px;
        border-radius: 18px;

        padding: 10px;
      }

      @include smallD {
        width: 102px;
        height: 102px;
        border-radius: 26px;

        padding: 15px;
      }

      @include md {
        width: 118px;
        height: 118px;

        border-radius: 32px;

        padding: 15px;
      }

      @include l {
        width: 125px;
        height: 125px;

        border-radius: 30px;

        padding: 15px;
      }
    }

    &-icon {
      svg {
        width: 100%;
        height: 100%;
      }

      @include l {
        padding: 10px;
      }
    }

    &-title {
      font-family: Poppins;
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #FFFFFF;

      margin-top: 53px;

      @include s {
        font-size: 20px;
        line-height: 30px;

        margin-top: 18px;
      }

      @include tablet {
        font-size: 20px;
        line-height: 30px;

        margin-top: 18px;
      }

      @include smallD {
        font-size: 24px;
        line-height: 34px;

        margin-top: 24px;
      }

      @include md {
        font-size: 26px;
        line-height: 36px;

        margin-top: 30px;
      }

      @include l {
        margin-top: 34px;
      }
    }

    $mobileXSLeftOffset: 220px;
    $mobileSLeftOffset: 242px;
    $mobileSmLeftOffset: 281px;

    &_avax {
      left: 1%;

      @include s {
        left: $mobileSLeftOffset;
        top: 7px;
      }

      @include xs {
        left: $mobileXSLeftOffset;
        top: 11px;
      }

      @include sm {
        left: $mobileSmLeftOffset;
        top: 7px;
      }
    }

    &_matic {
      left: 23%;

      @include s {
        left: $mobileSLeftOffset;
        top: 161px;
      }

      @include xs {
        left: $mobileXSLeftOffset;
        top: 163px;
      }

      @include sm {
        left: $mobileSmLeftOffset;
        top: 181px;
      }
    }

    &_binance {
      left: 44.8%;

      @include s {
        left: $mobileSLeftOffset;
        top: 314px;
      }

      @include xs {
        left: $mobileXSLeftOffset;
        top: 314px;
      }

      @include sm {
        left: $mobileSmLeftOffset;
        top: 357px;
      }
    }

    &_fantom {
      left: 66.7%;

      @include s {
        left: $mobileSLeftOffset;
        top: 467px;
      }

      @include xs {
        left: $mobileXSLeftOffset;
        top: 465px;
      }

      @include sm {
        left: $mobileSmLeftOffset;
        top: 531px;
      }
    }

    &_new {
      left: 88.6%;

      @include s {
        left: $mobileSLeftOffset;
        top: 620px;
      }

      @include xs {
        left: $mobileXSLeftOffset;
        top: 616px;
      }

      @include sm {
        left: $mobileSmLeftOffset;
        top: 707px;
      }
    }

    &:hover {
      .bridge__chart-token-box {
        background: linear-gradient(180deg, #F4F9FD 0%, #C8E3FF 100%);
        box-shadow: 0px 15px 15px #1E3DB1, inset 0px 10px 10px rgba(255, 255, 255, 0.9), inset 0px -3px 3px rgba(143, 207, 245, 0.8), inset 0px -6px 6px #8BBAED;

        transition: background 0.25s linear, box-shadow 0.25s linear;
      }

      &.bridge__chart-token {
        &_avax, &_fantom, &_new {
          .bridge__chart-token-icon {
            svg {
              fill: blue;
            }
          }
        }

        &_matic {
          .bridge__chart-token-icon {
            svg {
              fill: #8247E5;
            }
          }
        }

        &_binance {
          .bridge__chart-token-icon {
            svg {
              fill: #f0b90b;
            }
          }
        }
      }
    }
  }

  &__dapp {
    &-section {
      padding: 143px 0 50px 0;

      @include s {
        padding: 70px 0 20px;
      }
    }

    &-container {
      @include tablet {
        width: 690px;
        margin: 0 auto;
      }

      @include smallD {
        width: 980px;
        margin: 0 auto;
      }
    }

    &-top-block {
      display: flex;
      align-items: flex-start;

      @include s {
        width: 100%;
        flex-direction: column;
      }
    }

    &-top-column {
      width: 50%;

      @include s {
        width: 100%;
      }
    }

    &-title {
      text-align: left;
      max-width: 70%;

      @include s {
        max-width: none;
        text-align: center;
      }
    }

    &-subtitle {
      text-align: left;

      @include s {
        text-align: center;

        margin-top: 20px;
      }
    }

    &-desc {
      text-align: left;

      font-family: "Gotham Rounded";
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.01em;

      color: #808DC4;

      margin-top: 20px;

      @include s {
        text-align: center;

        font-size: 14px;
        line-height: 22px;
      }
    }

    &-form-wrapper {
      margin-top: 4px;

      @include s {
        margin-top: 20px;
      }

      @include tablet {
        margin-top: 20px;
      }
    }
  }
}