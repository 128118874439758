.small-screen-popup {
  width: 90%;
  height: 630px;
  max-width: 500px;

  .popup {
    &__form {
      padding: 81px 40px;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__title {
      font-size: 40px;
      line-height: 45px;
    }

    &__description {
      font-size: 18px;

      margin: 0 auto;

      max-width: 85%;
    }

    &__input-form-wrapper {
      margin-top: 36px;
    }

    &__input-wrapper {
      height: 46px;
    }

    &__input {
      font-size: 14px;

      padding: 5px 100px 5px 21px;
    }

    &__button {
      width: 92px;
      font-size: 14px;
    }
  }

  &__description-wrapper {
    margin: 29px auto 0 auto;

    &_second {
      margin: 22px auto 0 auto;
    }
  }
}