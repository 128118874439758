.partnerships {
  background: white;

  padding: 214px 0 195px 0;

  position: relative;

  @include s {
    padding: 100px 0;
  }

  @include tablet {
    padding: 100px 0;
  }

  @include smallD {
    padding: 100px 0;
  }

  @include mdl {
    padding: 140px 0;
  }

  &__title {
    letter-spacing: 0;
  }

  &__subtitle {
    margin: 50px auto 0 auto;
    max-width: 950px;

    letter-spacing: 0.2px;

    @include s {
      margin-top: 25px;
    }

    @include tablet {
      margin-top: 25px;
    }

    @include smallD {
      margin-top: 28px;
    }

    @include mdl {
      margin-top: 30px;
    }
  }

  &__screw {
    width: 100%;
    height: 30px;

    position: absolute;
    left: 0;
    bottom: -30px;

    z-index: 2;

    @include s {
      height: 3px;
      bottom: 30px;
    }

    filter: drop-shadow(0px 14px 6px rgba(0, 0, 0, 0.1));
  }

  &__tab-list-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__switcher-wrapper {
    background-color: rgb(44, 65, 187);
    box-shadow: 0px 2px 2px 0px rgba(100, 161, 245, 0.42),inset 0px 3px 3px 0px rgba(8, 24, 113, 0.2);

    display: flex;
    align-items: center;

    width: fit-content;
    height: 90px;

    border-radius: 45px;

    padding: 5px 0;

    margin-top: 86px;

    position: relative;
    left: -1px;

    @include s {
      margin-top: 16px;
      height: 45px;

      padding: 3px;
    }

    @include tablet {
      height: 70px;

      margin-top: 55px;
    }

    @include smallD {
      height: 70px;

      margin-top: 55px;
    }

    @include mdl {
      margin-top: 60px;

      height: 80px;
    }
  }

  &__switcher-item {
    font-family: "Gotham Rounded";
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: #cfe4ff;
    text-transform: uppercase;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    height: 100%;

    padding: 0 61px;

    margin: 0 6px;

    @include s {
      padding: 0 22px;
      margin: 0 8px;

      font-size: 12px;
    }

    @include xs {
      font-size: 10px;
      padding: 0 8px;
    }

    @include tablet {
      font-size: 18px;

      padding: 0 40px;
    }

    @include smallD {
      font-size: 18px;

      padding: 0 40px;
    }

    @include mdl {
      font-size: 20px;

      padding: 0 50px;
    }

    &:first-child {
      margin-left: 8px;

      @include s {
        margin-left: 2px;
      }

      @include xs {

      }
    }

    &:last-child {
      margin-right: 6px;
      margin-left: -12px;

      @include s {
        margin-right: 1px;
      }

      @include xs {
        margin-left: 6px;
      }
    }

    &_active {
      background-image: linear-gradient(0, rgb(235,95,62) 0%, rgb(253,198,81) 100%);
      box-shadow: 0px 14px 21px 0px rgba(3, 20, 125, 0.79),inset 0px 7px 7px 0px rgba(255, 238, 217, 0.16),inset 0px -4px 4px 0px rgba(255, 237, 151, 0.47),inset 0px -7px 5px 0px rgba(180, 64, 64, 0.26);

      border-radius: 45px;

      color: white;
    }
  }

  &__cards-list {
    margin-top: 135px;

    @include s {
      margin-top: 40px;
    }

    @include tablet {
      margin-top: 60px;
    }

    @include smallD {
      margin-top: 60px;
    }

    @include mdl {
      margin-top: 80px;
    }
  }

  &__cards-row {
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 44px;

      @include s {
        margin-bottom: 0;
      }

      @include tablet {
        margin-bottom: 20px;
      }

      @include smallD {
        margin-bottom: 20px;
      }

      @include mdl {
        margin-bottom: 30px;
      }
    }

    @include s {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__card {
    display: flex;
    justify-content: center;
    align-items: center;

    background: url("../../assets/icons/partnerships/partnership__card_base.svg") center no-repeat;
    background-size: contain;

    width: 323px;
    height: 153px;

    padding: 40px;
    margin: 0 20px;

    @include s {
      margin: 0 0 10px 0;

      width: 280px;
      height: 130px;
    }

    @include sm {
      margin-bottom: 20px;
    }

    @include tablet {
      margin: 0 10px;

      width: 160px;
      height: 80px;

      padding: 15px;
    }

    @include smallD {
      margin: 0 10px;

      width: 228px;
      height: 110px;

      padding: 15px;
    }

    @include mdl {
      margin: 0 15px;
    }

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      max-width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &_active {
      background-image: url("../../assets/icons/partnerships/partnership__card_active.svg");

      svg {
        fill: white;

        path {
          fill: white !important;
        }
      }

      &.partnerships__card_bitcoin {
        svg {
          #bitcoin_symbol_partnership {
            fill: #3c53d6 !important;
          }
        }
      }
    }

    &_bramah {
      padding: 20px 40px;

      @include tablet {
        padding: 10px 20px;
      }
    }

    &_aws, &_tally, &_fantom, &_getblock {
      padding: 55px;

      @include s {
        padding: 40px;
      }

      @include tablet {
        padding: 25px;
      }

      @include smallD {
        padding: 35px;
      }
    }

    &_vercel, &_bitcoin {
      padding: 60px;

      @include s {
        padding: 47px;
      }

      @include tablet {
        padding: 30px;
      }

      @include smallD {
        padding: 40px;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}