.operator {
  background: #f5f7fa;
  padding: 122px 0 403px 0;

  position: relative;
  z-index: 10;

  @include s {
    padding-top: 46px;
    padding-bottom: 54px;
  }

  @include tablet {
    padding-top: 46px;
    padding-bottom: 54px;
  }

  @include smallD {
    padding-top: 100px;
    padding-bottom: 140px;
  }

  @include md {
    padding-top: 90px;
    padding-bottom: 340px;
  }

  @include l {
    padding-bottom: 200px;
  }

  &__screw {
    position: absolute;

    left: 0;

    width: 100%;
    height: 30px;

    z-index: 5;

    &_top {
      top: -29px;

      @include s {
        top: -14px;
      }
    }

    &_bottom {
      bottom: -29px;

      @include s {
        bottom: 6px;
      }
    }

    @include s {
      height: 4px;
    }
  }

  &__main-container {
    position: relative;

    width: 100%;
    height: 720px;

    background: url("../../assets/images/networkOperators.png") no-repeat;
    background-position: 0 top;
    background-size: contain;

    @include s {
      background: url("../../assets/images/networkOperators-mobile.png") no-repeat;
      background-size: 350px 644px;
      background-position: center 213px;

      height: 997px;
    }

    @include xs {
      background-size: 300px 600px;
      background-position: center 213px;

      height: 950px;
    }

    @include sm {
      background: url("../../assets/images/networkOperators-mobile.png") no-repeat;
      background-size: 420px 820px;
      background-position: center 213px;

      height: 1200px;
    }

    @include tablet {
      background: url("../../assets/images/networkOperators-mobile.png") no-repeat;
      background-size: 600px 1170px;
      background-position: center 213px;

      height: 1650px;
    }

    @include md {
      width: 1120px;
      height: 620px;

      margin: 0 auto;
    }
  }

  &__info-block {
    position: absolute;
    top: 0;
    left: 50%;

    width: 50%;

    padding-top: 4px;

    @include s {
      position: relative;
      top: auto;
      left: auto;

      width: 100%;
      padding-top: 0;
    }

    @include tablet {
      position: relative;
      top: auto;
      left: auto;

      width: 100%;
      padding-top: 0;
    }
  }

  &__title {
    text-align: left;

    @include s {
      text-align: center;
      line-height: 36px;
      letter-spacing: 0;
    }

    @include tablet {
      text-align: center;
      line-height: 36px;
      letter-spacing: 0;
    }

    @include smallD {
      font-size: 48px;
      line-height: 56px;
    }

    @include md {
      font-size: 50px;
      line-height: 64px;
    }

    @include l {
      font-size: 52px;
      line-height: 66px;
    }
  }

  &__subtitle {
    text-align: left;
    margin-top: 66px;

    @include s {
      text-align: center;

      margin: 26px auto 0 auto;

      max-width: 300px;
    }

    @include tablet {
      text-align: center;

      margin: 26px auto 0 auto;

      max-width: 600px;
    }

    @include smallD {
      margin-top: 30px;
    }

    @include l {
      font-size: 20px;

      margin-top: 26px;
    }
  }

  &__list-info-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    margin-top: 14px;
    padding: 0 5px;

    position: absolute;
    top: 38.7%;
    left: -1.9%;

    width: 45.4%;

    @include s {
      width: 100%;
      top: 34.6%;
      left: -0.9%;
    }

    @include sm {
      top: 31%;
    }

    @include tablet {
      width: 84%;
      top: 28%;
      left: 6%;
    }

    @include smallD {
      top: 28%;
      width: 50%;
    }

    @include md {
      top: 36%;
      width: 50%;
    }

    @include l {
      top: 28%;

      left: -0.5%;

      width: 49%;
    }

    &_second {
      top: 105.7%;
      width: 45.3%;

      @include s {
        top: 58.75%;
        left: -0.9%;

        width: 100%;
      }

      @include sm {
        top: 57%;
      }

      @include tablet {
        top: 54.5%;
        left: 6%;

        width: 84%;
      }

      @include smallD {
        top: 76%;
        width: 50%;
      }

      @include md {
        top: 103%;
        width: 50%;
      }

      @include l {
        top: 84%;
        width: 49%;
      }
    }

    &_third {
      top: 105.7%;
      left: 48%;
      width: 45.3%;

      @include s {
        top: 85.7%;
        left: -0.9%;

        width: 100%;
      }

      @include sm {
        top: 86%;
      }

      @include tablet {
        top: 85%;
        left: 6%;

        width: 84%;
      }

      @include smallD {
        top: 76%;
        left: 50%;

        width: 53%;
      }

      @include md {
        top: 103%;
        left: 50%;

        width: 53%;
      }

      @include l {
        top: 84%;
        width: 51%;

        left: 53%;
      }

      .operator__info-item {
        &:first-child {
          @include s {
            order: 2;
          }

          @include tablet {
            order: 2;
          }
        }

        &:last-child {
          @include s {
            order: 1;
          }

          @include tablet {
            order: 1;
          }
        }
      }
    }
  }

  &__info-item {
    width: 280px;
    position: relative;

    @include s {
      width: 46%;
    }

    @include tablet {
      width: 46%;
    }

    @include smallD {
      width: 230px;
    }

    @include md {
      width: 240px;
    }

    @include l {
      width: 250px;
    }

    &-number {
      position: absolute;
      top: -22px;
      left: 25px;

      font-family: Poppins;
      font-weight: 600;
      font-size: 124px;
      line-height: 81px;
      color: #3552ca;

      opacity: 0.1;

      @include s {
        font-size: 60px;
        line-height: 63px;
      }

      @include tablet {
        font-size: 96px;
        line-height: 81px;

        left: 66px;
      }

      @include smallD {
        font-size: 86px;
        line-height: 80px;

        left: 15px;
      }

      @include md {
        font-size: 100px;
        line-height: 80px;

        left: 20px;
      }

      @include l {
        font-size: 90px;
      }
    }

    &-title {
      font-family: Poppins;
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
      color: #6077d4;
      text-align: center;
      letter-spacing: -0.6px;

      @include s {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.3px;

        padding-left: 4px;
      }

      @include sm {

      }

      @include tablet {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.3px;

        padding-left: 4px;
      }

      @include smallD {
        font-size: 32px;
      }

      @include md {
        font-size: 34px;
      }

      @include l {
        font-size: 30px;
        line-height: 32px;
      }
    }

    &-description {
      font-family: "Gotham Rounded";
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: #808dc4;
      text-align: center;
      letter-spacing: -0.2px;

      margin-top: 27px;

      @include s {
        font-size: 7.5px;
        line-height: 10px;

        margin: 13px auto 0 auto;

        max-width: 85%;
      }

      @include sm {
        font-size: 12px;
        line-height: 16px;
      }

      @include tablet {
        font-size: 16px;
        line-height: 22px;

        margin: 13px auto 0 auto;

        max-width: 85%;
      }

      @include smallD {
        font-size: 13px;

        margin-top: 15px;
      }

      @include md {
        font-size: 14px;

        margin-top: 20px;
      }

      @include l {
        font-size: 14px;
        margin-top: 20px;
      }
    }

    &-benefits {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: 31px;

      @include s {
        margin-top: 17px;
      }

      @include sm {
        font-size: 12px;
        line-height: 16px;
      }

      @include tablet {
        margin-top: 17px;
      }

      @include smallD {
        margin-top: 20px;
      }

      @include md {
        margin-top: 25px;
      }
    }

    &-benefit {
      font-family: "Gotham Rounded";
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #808dc4;

      position: relative;

      &:before {
        content: '';

        width: 4px;
        height: 4px;

        border-radius: 50%;

        background: #808dc4;

        position: absolute;
        left: -8px;
        top: 7px;

        @include s {
          width: 2px;
          height: 2px;

          left: -4px;
          top: 3px;
        }

        @include sm {
          width: 4px;
          height: 4px;
          left: -9px;
          top: 5px;
        }

        @include tablet {
          width: 5px;
          height: 5px;

          left: -11px;
          top: 8px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 0.5px;
      }

      @include s {
        font-size: 7.5px;
        line-height: 10px;
      }

      @include sm {
        font-size: 12px;
        line-height: 16px;
      }

      @include tablet {
        font-size: 16px;
        line-height: 22px;
      }

      @include smallD {
        font-size: 13px;
      }

      @include md {
        font-size: 14px;
      }
    }

    &_double-name {
      margin-top: -20px;

      @include s {
        margin-top: -11px;
      }

      @include tablet {
        margin-top: -11px;
      }

      .operator__info-item-number {
        margin-top: 20px;

        @include s {
          margin-top: 11px;
        }

        @include tablet {
          margin-top: 11px;
        }
      }
    }
  }
}